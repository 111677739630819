import React from 'react';
import "./PricesModal.scss";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { moneyFormatter } from '../../../../../services/currencyUtils';

const PricesModal = ({distribuitorPrices, pricesOff}) => {
    return (
        <div className="PricesModal">
            <div className="card inner-modal card-custom gutter-b example example-compact border border-danger">
                <div className="card-header d-flex align-items-center ">
                    <div className="info">
                        <h3 className='mb-0 magenta-color font-weight-bolder'>Lista de precios</h3>
                    </div>
                    <div className="card-toolbar">
			        	<div className="example-tools justify-content-center">
                            <FontAwesomeIcon icon={faTimes} className="example-copy text-danger cursor-pointer" onClick={() => pricesOff()}/>
			        	</div>
			        </div>
                </div>
                {distribuitorPrices ? distribuitorPrices?.map((price, i) => (
                    <div key={i} className='d-flex align-items-center mt-3 border-bottom border-muted justify-content-between px-5'>
                        <div className="font-weight-bolder text-dark-75 align-middle pb-5">
			    			<div className="d-flex flex-column text-center">
			    				<p className='mb-0 mt-1'>Cantidad mínima</p>
			    				<p className='mb-0 mt-1 text-muted'>{price.minQuantity}</p>
			    			</div>
                        </div>
                        <div className="font-weight-bolder text-dark-75 align-middle pb-5">
			    			<div className="d-flex flex-column mx-3 text-center">
			    				<p className='mb-0 mt-1'>Cantidad máxima</p>
                                <p className='mb-0 mt-1 text-muted'>{price.maxQuantity}</p>
			    			</div>
			    		</div>
                        <div className="font-weight-bolder text-dark-75 align-middle pb-5">
			    			<div className="d-flex flex-column text-center">
			    				<p className='mb-0 mt-1'>Precio</p>
                                <p className='mb-0 mt-1 text-muted'>${moneyFormatter(price.price, 'MXN')}</p>
			    			</div>
			    		</div>
                    </div>
                )): (
                    <div className='d-flex align-items-center'>
                        El producto todavía no tiene precio para aliadas
                    </div>
                )
                }
            </div>
        </div>
    );
}
 
export default PricesModal;