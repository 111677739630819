import moment from 'moment';
import React, {useCallback, useMemo} from 'react';
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import useTideTable from '../../../../../hooks/useTideTable';
import {moneyFormatter} from "../../../../../services/currencyUtils";
import Modal from "../../../../../components/Modal/Modal";

const LogBalanceModal = ({onClose, user}) => {
    const referredColumns = useCallback(() => [
        {Header: "Descripción", accessor: 'description'},
        {Header: "Monto", accessor: log => "$ " + moneyFormatter(log?.amount)},
        {Header: "Fecha", accessor: log =>  moment(log.createdDate).format('DD/MM/YYYY HH:mm')}
    ], []);

    const columns = useMemo(() => referredColumns(), [referredColumns]);

    const requestFilters = useMemo(()=>({
        profile: user.profile.id,
        sGroups: groups,
    }),[user]);

    const table = useTideTable({
        columns,
        entity: 'log_balances',
        requestFilters
    });

    return (
        <Modal
            className={'LogBalanceModal'}
            title={'Detalle de movimientos'}
            onClose={onClose}
        >
            <h3>Saldo actual: {"$ " + moneyFormatter(Number(user.profile.balance||0))}</h3>
            {/*begin::Table*/}
            <TideReactTable {...table.tableProps} />
            {/*end::Table*/}

        </Modal>
    )
}

const groups = ['read_created_date', 'log_balance_write'];
export default LogBalanceModal;
