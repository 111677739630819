import {useState, useRef, useMemo, useContext, useCallback, useEffect} from 'react';
import _ from "lodash";
import {useSelector} from "react-redux";
import {useTable, usePagination} from "react-table";
import {ApiContext} from "../services/api/api-config";

const emptyArray = [];

/**
 *
 * @param active boolean Default to true. If false it will not load anything from the server
 * @param entity string The name of the entity to fetch, in plural as defined in the Api
 * @param columns [] Array to send to ReactTable as columns property
 * @param debounced boolean Whether the load function should be debounced, handy when loading on each keystroke of a filter
 * @param requestFilters object Extra filters to send to the api in the request
 * @param requestOptions object Merged to the api call param
 * @param createApiOptions function Receives the params (tableState, addOptions, config, filters) and should return the object
 *                           to send to the api call see tableStateToApiOptions for the default implementation
 * @param getMethod string Method to call on the api endpoint
 * @param filterData function A function to filter the data received from the server before processing it
 * @param filterMappings object The default tableStateToApiOptions will take this in the config object and convert each
 *                           filter named as each key of the filterMappings object and convert it to its value
 * @param selectable boolean If true, a column will be prepended to the table with a checkbox, the selected row will be
 *                           returned in the "selected" key of the returned object
 * @returns {{reload: (function(): Q.Promise<any> | Promise<void> | * | void | PromiseLike<any>), itemsFoundString: string, tableProps: {reactTable: *, manualPagination: boolean, onFetchData: (*), defaultFilterMethod: (function(*, *): boolean), loading: boolean}, lastUsedApiOptions: unknown, reduxProp: string}}
 */
const useTideTable = ({
                    active=true,
                    entity,
                    columns,
                    debounced=true,
                    itemsPerPage=10,
                    requestFilters,
                    requestOptions,
                    createApiOptions=tableStateToApiOptions,
                    getMethod='get',
                    filterData,
                    filterMappings,
                    pageSizeOptions,
                })=> {

    if (!entity)
        throw new Error('The "entity" parameter is mandatory for the "useTideTable" hook.');
    if (!columns)
        throw new Error('The "columns" parameter is mandatory for the "useTideTable" hook.');
    
    //  -------- Get table state ----------

    //Get the requested data from Redux
    const reduxProp = requestOptions?.customProp? requestOptions.customProp:entity;
    let data = useSelector(({api})=>api[reduxProp])||emptyArray;

    //Get the request meta data from redux
    let {totalItems} = useSelector(({api})=>api[reduxProp+'Meta'])||{totalItems:0, itemsPerPage:0};
    const pageCount=Math.ceil(totalItems/itemsPerPage)||1;

    const reactTable = useTable({
            columns,
            data,
            manualPagination:true,
            pageCount,
            initialState: {
                pageSize: itemsPerPage
            }
        },
        usePagination
    );

    const {pageIndex, pageSize} = reactTable.state;

    const tableStateRef = useRef(null);
    //Generate a unique loading id for this component
    let loadingId = useMemo(()=>`useTideTable.${entity}.${Math.random()}`,[entity]);

    if(requestOptions && requestOptions.loadingId)
        loadingId=requestOptions.loadingId;
    //Get the api object
    const api = useContext(ApiContext);

    const [lastUsedApiOptions, setLastUsedApiOptions]=useState(null);
    //Function to fetch data from server, debounced for searching while writing an input
    const loadData = useCallback((tableState, requestFilters) => {
        if(!active)
            return;
        tableStateRef.current = tableState;
        //Convert the table state to an api options object to make a request
        const apiOptions=createApiOptions({pageIndex, pageSize, apiOptions:{loadingId, ...requestOptions}, requestFilters});
        //Make the request
        return api[entity][getMethod](apiOptions)
            .then(()=> setLastUsedApiOptions(apiOptions));

    }, [active, api, createApiOptions, requestOptions, pageIndex, pageSize, loadingId, entity, getMethod]);

    // eslint-disable-next-line
    const loadDataDebounced = useCallback(_.debounce(loadData, 650), [loadData]);

    const reload = useCallback(()=>{
        return loadData(tableStateRef.current, requestFilters);
    }, [loadData, requestFilters]);

    //If the parameters for the request change, we reload the data
    useEffect(()=>{
        if (debounced) {
            loadDataDebounced(tableStateRef.current, requestFilters);
        } else {
            loadData(tableStateRef.current, requestFilters);
        }
    },[loadDataDebounced, requestFilters, loadData, debounced]);


    //Get the loading state of the request from redux
    const loading = !!useSelector(({loadingIds})=>loadingIds[loadingId]);

    const filterCaseInsensitive=(filter, row)=>{
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    };

    //Apply external filter to data array
    data=useMemo(()=>{
        if(filterData)
            return filterData(data);
        return data;
    }, [filterData, data]);

    //Expose everything in an object
    //tableProps are the props meant to be sent to a ReactTable

    return useMemo(()=>({
        tableProps: {
            reactTable,
            data,
            onFetchData: debounced?loadDataDebounced:loadData,
            loading,
            manualPagination: true,
            totalItems,
            defaultFilterMethod:filterCaseInsensitive,
        },
        reduxProp,
        reload,
        pageIndex,
        itemsFoundString:`${totalItems} registro${totalItems!==1?'s':''} encontrado${totalItems!==1?'s':''}`,
        lastUsedApiOptions,
    }),[reactTable, debounced, loadDataDebounced, totalItems, loadData, loading, reduxProp, reload, lastUsedApiOptions, data, pageIndex]);

};
/*
export const filtersToObject = ( filters = [], sorts = [] )=>{

    const sort = sorts.reduce((acc, val) => {
        acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
        return acc;
    }, {});

    const filter = filters.reduce((acc, val) => {
        acc[val.id] = val.value;
        return acc;
    }, {});

    return { ...sort , ...filter };

};
*/
export const tableStateToApiOptions=({pageIndex, pageSize, apiOptions, requestFilters={}})=>{

    const params = {...requestFilters};
    if( typeof pageIndex === 'number')
        params.page = pageIndex+1;
    if( typeof pageSize === 'number')
        params.itemsPerPage = pageSize;

    return {
        ...apiOptions,
        pagination: true,
        params,
    };
};

export default useTideTable;
