import React, {useCallback, useContext, useState, useEffect} from 'react';
import './Register.scss';
import useFormState from "../../../hooks/useFormState";
import {ApiContext} from "../../../services/api/api-config";
import {ReactComponent as Icon} from "../../../assets/img/logo.svg";
import {paths} from "../../../services/routes/mainRoutes";
import {Link, useLocation} from "react-router-dom";
import { getNotifier } from '../../../services/notifier';
import {useSelector} from "react-redux";
import {neighbourhoodSelectorSGroups} from "../../../services/modelUtils/neighbourhoodUtils";
import _ from 'lodash';
import {normalizePhoneInput, prepareRegisterFormForServer} from "../../../services/modelUtils/userUtils";

const Register=()=> {

    const api=useContext(ApiContext);

    const search = new URLSearchParams(useLocation().search);
    const {form, setForm, handleInputChange} = useFormState(()=>({referredByCode: search.get('code')||''}));
    const [redirect, setRedirect] = useState(false);
    const [showError, setShowError] = useState(false);

    // Zip code entity selection
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [neighbourhood, setNeighbourhood] = useState(null);
    const zipCode = form?.zipCode;
    useEffect(()=>{
        if(zipCode?.length === 5 )
            api.neighbourhoods.get({ useCommonPath: false, params:{ postalCode:zipCode, sGroups: neighbourhoodSelectorSGroups } })
                .then((neighbourhoods)=>{
                    setNeighbourhoods(neighbourhoods);
                    setNeighbourhood(neighbourhoods[0]);
                })
        else{
            setNeighbourhoods([]);
            setNeighbourhood(null);
        }
    },[api, zipCode]);

    const handleNeighbourhoodSelect = useCallback((e)=>{
        const neighbourhood = _.find( neighbourhoods, nh=>(nh.id+"")===e.target.value );
        if(neighbourhood){
            setNeighbourhood(neighbourhood);
        }
    },[neighbourhoods]);

    const loadingId = 'Register.userCreate';
    const register = useCallback((e)=>{
        e.preventDefault();
        let params;
        try {
            params = prepareRegisterFormForServer({...form, neighbourhood})
        }
        catch (e){
            return getNotifier().error(e.message);
        }

        api.users.createPublic({params, loadingId}).then(() => {
            setRedirect(true);
        }).catch((err) => {
            setShowError(true);
            console.log(err);
            if (err.error?.email)
                getNotifier().error(err.error.email);
        });
    },[api, form, neighbourhood]);
    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    const handlePhoneChange = ({ target: { value } }) => {
        setForm({...form, phone: normalizePhoneInput(value, form)})
    };

    return (
        <div className='Register'>
            <div className='login-form text-center p-7 position-relative overflow-hidden'>
                <div className="mt-5 mb-15">
                    <Link to={paths.landing} className='links'>
                        <Icon className="max-h-75px icon"/>
                    </Link>
                </div>
                {redirect ?
                    <div className="registerResponse mb-4 text-success">
                        <h4>Te llegará un correo al email que compartiste con un enlace para validar tu cuenta. ¡Muchas gracias!</h4>
                        <Link to={paths.login} className='links'>
                            <h5 className='mt-5 mb-5'>Inicia Sesión</h5>
                        </Link>
                    </div>
                    :
                    <div className="login-signin">
                        {showError &&
                        <div className="text-danger">
                            <h4>La información de registro que has ingresado no es válida, por favor vuelve a intentar</h4>
                        </div>
                        }
                        <div className="mb-3">
                            <h3>¡Regístrate!</h3>
                            <div className="mentita-color font-weight-bold mb-4 ingresa">Ingresa tus datos para registrarte:</div>
                        </div>
                        <form className="form " id="kt_login_signin_form" onSubmit={register}>

                            <div className='form-container'>
                                <div className='form-column'>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('name')} value={form.name||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Nombre(s)" name="name" autoComplete="off" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('lastNames')} value={form.lastNames||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Apellidos" name="lastName" autoComplete="off" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={handlePhoneChange} value={form.phone||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Teléfono" name="phone" autoComplete="off" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input className="form-control h-auto form-control-solid py-1 px-8" type='text' onChange={handleInputChange('email')} value={form.email||''} placeholder="Correo Electrónico" name="email"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input className="form-control h-auto form-control-solid py-1 px-8" onChange={handleInputChange('password')} value={form.password||''} type="password" placeholder="Contraseña" name="password"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input className="form-control h-auto form-control-solid py-1 px-8" onChange={handleInputChange('passConfirmation')} value={form.passConfirmation||''} type="password" placeholder="Confirma tu contraseña" name="password"/>
                                    </div>
                                </div>

                                <div className='form-column'>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('street')} value={form.street||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Calle y número" name="street"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('zipCode')} value={form.zipCode||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Código postal" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <select onChange={handleNeighbourhoodSelect} value={neighbourhood?.id||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Colonia">
                                            {!neighbourhoods?.length && <option value={""} disabled>Colonia</option>}
                                            {neighbourhoods.map( nh=><option key={nh.id} value={nh.id}>{nh.name}</option> )}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input readOnly value={neighbourhood? `${neighbourhood.municipality?.name}, ${neighbourhood.municipality?.state?.name}`:''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Municipio, Estado"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('addressReference')} value={form.addressReference||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Referencia" autoComplete="off" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={handleInputChange('referredByCode')} value={form.referredByCode||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Código de aliada (opcional)" name="referredByCode" autoComplete="off" />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group d-flex flex-wrap flex-center justify-content-center mt-10">
                                <Link to={paths.login} id="kt_login_forgot_cancel" className='btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2'>
                                    Cancelar
                                </Link>
                                <button id="kt_login_forgot_submit" disabled={loading} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">{loading ?'Enviando...' : 'Registrarme'}</button>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
};

export default Register;
