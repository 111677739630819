import React from 'react';

const Splash=()=>{
    return (
        <div className='Splash'>
            <img src='/logo.svg' alt='Logo' className='splash-logo'/>
        </div>
    );
};

export default Splash;
