import React from 'react'

import Payment from '../../../../../../Logged/Orders/components/Payment/Payment';

const CardPayment = ({ form, gotoThanks }) => {
    return (
        <div className='CardPayment'>
            <Payment 
                orderObject={form.order}
                usePublic={true}
                guest={form.guest}
                onClose={gotoThanks}
            />
        </div>
    );
}

export default CardPayment;