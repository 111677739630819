import React, { useState, useMemo, useCallback, useContext } from 'react';
import useTideTable from '../../../hooks/useTideTable';
import "./UsersAdmin.scss";
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UsersAdminEdit from "./components/UsersAdmin/UsersAdminEdit";
import useBoolean from '../../../hooks/useBoolean';
import { ApiContext } from '../../../services/api/api-config';
import {userListSGroups} from "../../../services/modelUtils/userUtils";
import useFormState from '../../../hooks/useFormState';
import ReferredsModal from './components/ReferredsModal/ReferredsModal';
import LogBalanceModal from './components/LogBalanceModal/LogBalanceModal';
import {moneyFormatter} from "../../../services/currencyUtils";
import {SecurityContext} from "../../../services/SecurityManager";
import { USER_ROLE } from '../../../constants/sesion';

const UsersAdmin = () => {
    const security = useContext(SecurityContext);
    const userFullName = (u) => `${u?.name}`.trim();
    const [editingUser, setEditingUser] = useState();
    const [addingUser, addingUserOn, addingUserOff] = useBoolean();
    const api = useContext(ApiContext);
    const {form, handleInputChange} = useFormState({});
    //const [showLogBalance, setShowLogBalance] = useState({show:false, currentUser:null});
    const [showReferredsModal, setReferredsModal] = useState({show:false, currentUser:null});
    const [showLogBalanceModal, setLogBalanceModal] = useState({show:false, currentUser:null});
    const handleUserStatus = useCallback((user) => {
        api.users.update({id: user.id, params: {isActive: !user.isActive, sGroups: userListSGroups}});
    }, [api]);

    const usersColumns = useCallback((onEdit) => {
        let columns = [
        {Header: "Nombre", accessor: userFullName},
        {Header: "Apellidos", accessor: u=>u?.lastNames},
        {Header: "Dirección", accessor: u=> `${u?.profile?.city ? u?.profile?.city+',':'-'} ${u?.profile?.zipCode ? 'C.P. '+ u?.profile?.zipCode : '-'}`},
        {Header: "Email", accessor: 'email'},
        {
            id: 'referreds',
            Header: "Referidas",
            Cell: ({row: {original} = {}}) =>
                <div>
                    <span className={'cursor-pointer text-success'}
                        onClick={() => setReferredsModal({currentUser: original, show: true})}>
                        {original?.profile?.referredsCount}
                    </span>
                </div>
        },
        {
            id: 'balance',
            Header: "Saldo",
            Cell: ({row: {original} = {}}) =>
                <div>
                    <span className={'cursor-pointer balance-green'}
                        onClick={() => setLogBalanceModal({currentUser: original, show: true})}>
                        {"$\xa0" + moneyFormatter(Number(original?.profile?.balance||0))}
                    </span>
                </div>
        },
        {Header: 'Rol', accessor: u=>u?.role?.title||'Aliada'},
        {Header: 'Órdenes', accessor: 'ordersCount'}
    ];
        if (security?.role === USER_ROLE.ROLE_GENERAL_SELL){
            columns.push({Header: 'Teléfono', accessor: u=>u?.profile?.phone ||'Sin capturar'},);
        }
    if (security?.role !== USER_ROLE.ROLE_GENERAL_SELL){
        columns.push({ 
            id: 'detail',
            Header: "Editar",
            enableHiding: false,
            Cell: ({row: {original} = {}}) =>
                <div>
                    <FontAwesomeIcon icon={faEdit} className="h-75 cursor-pointer" onClick={() => onEdit(original)}/>
                </div>
        });
        columns.push({
            id: 'activar',
            Header: "Status",
            Cell: ({row: {original} = {}}) =>
                <div>
                    <span className={'cursor-pointer ' + (original.isActive?'text-danger':'text-success')} onClick={() => handleUserStatus(original)}>{original.isActive === true ? 'Desactivar':'Activar'}</span>
                </div>
        });
    }
    columns.push({
        Header: "Última orden",
        Cell: ({row: {original} = {}}) =>
            <div>
                <span>{(original.lastOrderDate ? original.lastOrderDate.split('T')[0] : '---')}</span>
            </div>
    });
    return columns;
}, [handleUserStatus, security]);

    const requestFilters = useMemo(()=>({
        sGroups: userListSGroups,
        search: form.name || undefined,
        'profile.city': form.city || undefined,
        'profile.zipCode': form.zipCode || undefined,
    }),[form.name, form.zipCode, form.city]);

    const columns = useMemo(() => usersColumns(setEditingUser), [usersColumns]);

    const table = useTideTable({
        entity: 'users',
        columns,
        requestFilters,
        security,
    });

    return (
        <div className={"UsersAdmin " + ((editingUser || addingUser) && 'scroll-down-block')}>
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-weight-bolder magenta-color font-bold">Aliadas</span>
			    		<span className="text-muted mt-3 font-weight-bold font-size-sm">{security.role !== USER_ROLE.ROLE_GENERAL_SELL ? 'Administradora' : 'Ventas'}</span>
			    	</h3>
                    {security.role !== USER_ROLE.ROLE_GENERAL_SELL && 
                        <div className="card-toolbar">
                            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                                <li className="nav-item cursor-pointer">
						    	    <span className={"nav-link text-light py-2 px-4 btn-info cursor-pointer"} data-toggle="tab" onClick={addingUserOn}>Añadir</span>
						        </li>
						    </ul>
					    </div>
                    }
			    </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
                        {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
                            <div className="d-flex responsive-filters">
                                <div className="d-flex flex-column">
                                    <p className='mb-0 font-bold magenta-color'>Buscar aliada</p>
                                    <input type="text" className='form-control name-input' placeholder='Buscar' value={form.name} onChange={handleInputChange('name')} name="name" id="name-input" />
                                </div>
                                <div className="d-flex flex-column mx-4">
                                    <p className='mb-0 font-bold magenta-color'>Buscar por ciudad</p>
                                    <input type="text" className='form-control name-input' placeholder='Buscar' value={form.city} onChange={handleInputChange('city')} name="name" id="name-input" />
                                </div>
                                <div className="d-flex flex-column">
                                    <p className='mb-0 font-bold magenta-color'>Buscar por C.P</p>
                                    <input type="text" className='form-control name-input' placeholder='Buscar' value={form.zipCode} onChange={handleInputChange('zipCode')} name="name" id="name-input" />
                                </div>
                            </div>
			            	{/*begin::Table*/}
			            	<TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>

                        {showReferredsModal.show &&
                            <div className='mt-5'>
                            <ReferredsModal onClose={() => setReferredsModal({currentUser: null, show: false})} user={showReferredsModal.currentUser}/>
                            </div>}

                        {showLogBalanceModal.show &&
                            <div className='mt-5'>
                            <LogBalanceModal onClose={() => setLogBalanceModal({currentUser: null, show: false})} user={showLogBalanceModal.currentUser}/>
                            </div>}

                        {editingUser &&
                            <div className='mt-5'>
                            <UsersAdminEdit user={editingUser} onClose={()=>setEditingUser(null)} isEdit={true}/>
                            </div>}

                        {addingUser &&
                            <div className='mt-5'>
                            <UsersAdminEdit user={editingUser} onClose={addingUserOff} isEdit={false}/>
                            </div>}
			            {/*end::Tap pane*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

UsersAdmin.displayName="UsersAdmin";

export default UsersAdmin;
