import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import TideEntitySelect from '../../../../../../components/TideEntitySelect/TideEntitySelect';
import useBoolean from '../../../../../../hooks/useBoolean';
import useDatePickerState from '../../../../../../hooks/useDatePickerState';
import useFormState from '../../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../../services/api/api-config';
import { categories } from '../../../../../../services/modelUtils/paymentCategoryUtils';
import { getNotifier } from '../../../../../../services/notifier';
import "./LoansPay.scss";

const LoansPay = ({loanToPay, setLoanToPay}) => {
    const api = useContext(ApiContext);
    const {form, handleInputChange/* , setForm */} = useFormState({});
    const [loading, isLoading, notLoading] = useBoolean();
    const [paymentMethod, setPaymentMethod] = useState();
    const [paymentCategorie, setPaymentCategorie] = useState();
    const {props, date} = useDatePickerState(moment());

    const handleSavePayment = useCallback(() => {
        isLoading();
        api.payments.create({params: {...form, amount: Number(form.amount), paymentCategories: [paymentCategorie],loan: loanToPay.id, type: 'expense',
            paymentMethod:paymentMethod?.id, paymentDate: date}}).then(() => {
            api.loans.get({params:{sGroups: ['loan_read', 'created_date']}, customProp: 'loansTable'});
            getNotifier().success('El pago se ha creado correctamente');
            setLoanToPay(null);
            notLoading();
        }).catch(() => {
            getNotifier().error('No se pudo crear el pago');
            notLoading();
        });
    }, [api, form, isLoading, notLoading, loanToPay, paymentCategorie, setLoanToPay, paymentMethod, date]);

    const handleCategoryChange = useCallback((e) => {
        setPaymentCategorie(e.target.value);
    }, [setPaymentCategorie])

    return (
        <div className="LoansPay">
            <div className="card card-custom gutter-b">
			    <div className="card-body pt-4">
			    	<form>
			    		{/*end::Product images*/}
			    		{/*begin::Product info*/}
			    		<div className="mt-6">
			    			<div className="text-muted mb-4 font-weight-bolder font-size-lg">Información del pago correspondiente a préstamo</div>
			    			{/*begin::Input*/}
			    			<div className="form-group mb-8 row">
                                <div className="col-12 d-flex flex-column">
                                    <label className="font-weight-bolder">Referencia</label>
                                    <input type="text" className="form-control" min={0} value={form.reference} onChange={handleInputChange('reference')}></input>
                                </div>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 mt-8 mt-md-0 d-flex flex-column">
                                    <label className={'font-weight-bolder'}>Fecha en que se realizó el pago</label>
                                    <SingleDatePicker
                                        {...props}
                                        placeholder='Fecha de pago'
                                        id='loanspay_date'
                                        isOutsideRange={() => false}
                                    />
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Categoría de pago</label>
                                    <div className="form-group mb-0">
                                        <select className="form-control" value={paymentCategorie||'Selecciona una opción'} onChange={handleCategoryChange} id="exampleFormControlSelect1">
                                            <option value="Selecciona una opción">Selecciona una opción</option>
                                            <option value={categories.LOAN_MAIN_PAYMENT}>Pago a capital</option>
                                            <option value={categories.LOAN_INTEREST_PAYMENT}>Pago por interés</option>
                                        </select>
                                    </div>
                                </div>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Método de pago</label>
                                    <TideEntitySelect
                                        entity='paymentMethods'
                                        value={paymentMethod}
                                        onChange={setPaymentMethod}
                                        additionalFilters={{paymentType: 'expense'}}
                                        filterLocal
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-2 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Monto</label>
                                    <input min={0} type="number" className="form-control" value={form.amount} onChange={handleInputChange('amount')}></input>
                                </div>
			    			</div>
                            <div className="separator my-1"></div>
			    		</div>
			    		{/*end::Product info*/}
						{/*end::Color*/}
						<button type="submit" className="btn btn-primary font-weight-bolder mr-2 px-8" disabled={loading} onClick={handleSavePayment}>Guardar pago</button>
			    		<button type="reset" className="btn btn-clear font-weight-bolder text-muted px-8" onClick={() => setLoanToPay(null)}>Cancelar</button>
			    		{/*end::Input*/}
			    	</form>
			    	{/*end::Form*/}
			    </div>
			    {/*end::Body*/}
			</div>
        </div>
    );
}
 
export default LoansPay;