import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApiContext } from '../../../../../services/api/api-config';
import "./TotalSells.scss";

const TotalSells = () => {

    const api = useContext(ApiContext);

    const customProp = 'TotalSellsStats';
    useEffect(() => {
        api.orders.get({ params: { 'createdDate[after]': moment().format('YYYY-01-01 00:00'), itemsPerPage:0 }, customProp: customProp+'year' });
        api.orders.get({ params: { 'createdDate[after]': moment().format('YYYY-MM-01 00:00'), itemsPerPage:0 }, customProp: customProp+'month' });
    }, [api]);

    const totalOrdersInMonth = useSelector(s => s.api[customProp+'monthMeta']||0);
    const totalOrdersInYear = useSelector(s => s.api[customProp+'yearMeta']||0);

    return (
        <div className="TotalSells d-flex flex-column justify-content-around">

            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title text-center">
                        <span className="font-bold magenta-color">Órdenes de compra</span>
                    </h3>
                </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center flex-wrap mt-5 justify-content-around">
                            <div className="container-card">
                                <p className='font-weight-bolder mb-0 text-info text-center text-uppercase'>Órdenes del mes</p>
                                <h2 className='font-weight-bolder text-center'>{totalOrdersInMonth?.totalItems}</h2>
                            </div>
                            <div className="container-card">
                                <p className='font-weight-bolder mb-0 text-info text-center text-uppercase'>Órdenes del año</p>
                                <h2 className='font-weight-bolder text-center'>{totalOrdersInYear?.totalItems}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TotalSells;
