import React, { useState, useEffect, useContext, useCallback } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import useFormState from '../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../services/api/api-config';
import { neighbourhoodSelectorSGroups } from '../../../../../services/modelUtils/neighbourhoodUtils';
import _ from 'lodash';

import ContinueAsGuest from './components/ContinueAsGuest/ContinueAsGuest';
import DeliveryAddress from './components/DeliveryAddress/DeliveryAddress';
import SelectPaymentType from './components/SelectPaymentType/SelectPaymentType';
import CardPayment from './components/CardPayment/CardPayment';

import './RegisterAndCreateOrderModal.scss';
import { normalizePhoneInput } from '../../../../../services/modelUtils/userUtils';
import { paths } from '../../../../../services/routes/mainRoutes';
import { useHistory } from 'react-router-dom';
import KueskiPaymentModal from './components/KueskiPaymentModal/KueskiPaymentModal';

const initialState = {
    email: null,
    deliveryAddress: {
        address: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
        phone: null,
    },
    order: null,
    guest: null,
    paymentType: null
}

const RegisterAndCreateOrderModal = ({cart, resetCart, onClose}) => {
    const api=useContext(ApiContext);
    const [step, setStep] = useState(0);
    const {form, handleInputChange, setForm} = useFormState(initialState);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [neighbourhood, setNeighbourhood] = useState(null);
    const zipCode = form?.zipCode;
    const history = useHistory();

    useEffect(()=>{
        if(zipCode?.length === 5 )
            api.neighbourhoods.get({ useCommonPath: false, params:{ postalCode:zipCode, sGroups: neighbourhoodSelectorSGroups } })
                .then((neighbourhoods)=>{
                    setNeighbourhoods(neighbourhoods);
                    setNeighbourhood(neighbourhoods[0]);
                })
        else{
            setNeighbourhoods([]);
            setNeighbourhood(null);
        }
    },[api, zipCode]);

    const gotoDeliveryAddress = () => {
        setStep(1);
    }
    
    const gotoNextStep = () => {
        setStep(step + 1);
    }

    const handleNeighbourhoodSelect = useCallback((e)=>{
        const neighbourhood = _.find( neighbourhoods, nh=>(nh.id+"")===e.target.value );
        if(neighbourhood){
            setNeighbourhood(neighbourhood);
        }
    },[neighbourhoods]);

    const register = () => {};

    const handlePhoneChange = ({ target: { value } }) => {
        setForm({...form, phone: normalizePhoneInput(value, form)})
    };

    const gotoCardPayment = () => {
        setStep(3);
    }

    const gotoKueskiPayment = () => {
        setStep(4);
    }

    const gotoThanks = useCallback(() => {
        resetCart();
        history.push( paths.landing );
        history.push( paths.products );
    }, [history, resetCart]);

    return (
        <Modal className='RegisterAndCreateOrderModal'
            title='Pagar orden'
            show={true}
            onClose={onClose}
        >
            <div className='RegisterAndCreateOrderModal__content'>
                { step === 0 && 
                    <ContinueAsGuest
                        form={form}
                        handleInputChange={handleInputChange}
                        gotoDeliveryAddress={gotoDeliveryAddress}
                    />}
                
                { step === 1 && 
                    <DeliveryAddress
                        form={form}
                        setForm={setForm}
                        handleInputChange={handleInputChange}
                        gotoNextStep={gotoNextStep}
                        neighbourhoods={neighbourhoods}
                        register={register}
                        handleNeighbourhoodSelect={handleNeighbourhoodSelect}
                        neighbourhood={neighbourhood}
                        handlePhoneChange={handlePhoneChange}
                        cart={cart}
                    />}
                
                { step === 2 && 
                    <SelectPaymentType
                        form={form}
                        setForm={setForm}
                        cart={cart}
                        gotoCardPayment={gotoCardPayment}
                        gotoKueskiPayment={gotoKueskiPayment}
                    />} 

                { step === 3 && 
                    <CardPayment
                        form={form}
                        setForm={setForm}
                        gotoThanks={gotoThanks}
                    />}              
                
                { step === 4 &&
                    <KueskiPaymentModal
                        form={form}
                        setForm={setForm}
                        gotoThanks={gotoThanks}
                    />}

            </div>
        </Modal>
    );
}

export default RegisterAndCreateOrderModal;