import { faEraser, faImages, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useState } from 'react';
import { ApiContext } from '../../../services/api/api-config';
import "./BannerModal.scss";
import {settings} from "../../../services/modelUtils/settingUtils";
import {getNotifier} from "../../../services/notifier";
import join from 'url-join';

const BannerModal = () => {

    const api = useContext(ApiContext);
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState(null);
    const [imgSrc, setImgSrc] = useState( join(api.host + '/settings/banner_image' ) );

    const handleFileSelection = useCallback(e => {
        if (!e.target?.files?.length)
            return;
        const file = e.target.files[0];
		setImgSrc(URL.createObjectURL(file))

        setFile(file);
    }, [setFile]);

    const handleImageSave = useCallback(() => {
        if (!file)
            return getNotifier().info("Selecciona una imagen");

        api.settings.update({id:settings.BANNER_ID, files:[file], params:{} })
            .then(()=>{
                getNotifier().success("Imagen guardada correctamente")
            });
    }, [file, api]);

    const handleMockClickToFile = useCallback(e => {
		hiddenFileInput.current.click();
	}, []);

    const removeImage = useCallback(() => {
        setImgSrc();
        setFile();

        api.settings.update({id:settings.BANNER_ID, params:{ file:null } })
            .then(()=>{getNotifier().success("Imagen guardada correctamente")});

    }, [api]);

    return (
        <div className="BannerModal">
            <div className="BannerModal-header">
                <div className="header-title">
                    <h1>Banner Principal</h1>
                </div>
            </div>
            <div className="BannerModal-buttons px-20 mt-20 w-100 d-flex justify-content-between align-items-center flex-column flex-md-row">
                <div className="addImage-button btn btn-info d-flex align-items-center" onClick={handleMockClickToFile}>
                    <input
                		type='file'
						ref={hiddenFileInput}
						onChange={handleFileSelection}
						style={{display: 'none'}}
                	/>
                    <FontAwesomeIcon icon={faImages} className='add-icon mr-3'/>
                    <p className='mb-0'>Cambiar Fotografía</p>
                </div>
                <div className="deleteImage-button btn btn-danger d-flex align-items-center mt-5 mt-md-0" onClick={removeImage}>
                    <FontAwesomeIcon icon={faEraser} className='add-icon mr-3'/>
                    <p className='mb-0'>Eliminar Fotografía</p>
                </div>
            </div>
            <div className="BannerModal-image mt-5" style={{backgroundImage: `url(${imgSrc})` }}>
            </div>
            <div className="BannerModal-save mt-5 d-flex justify-content-center">
                <div className="btn btn-info" onClick={handleImageSave}>
                    <FontAwesomeIcon icon={faSave} className='add-icon'/>
                    <p className='mb-0'>Guardar imágen</p>
                </div>
            </div>
        </div>
    );
}

export default BannerModal;
