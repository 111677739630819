import React from "react";
import {getProductPhotoUrl} from "../../../services/modelUtils/productUtils";

export const getProductsTableColumns = ({ api, deleteProduct, onShowPrices, toggleProductActive, onProductEdit }) => [
    {Header: "Logo", accessor: product => <div style={{backgroundImage: `url(${getProductPhotoUrl(product, api)})`,
            width: '100px',
            height: '100px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>

        </div>},
    
    {Header: "Categoría", accessor: product => 
        <div>
            <p className='mb-0 text-info text-small'>CATEGORÍA</p>
            <p className='mb-0 font-weight-bolder'>{product.category?.name||'Sin categoría'}</p>
        </div>},

    {Header: "Nombre", accessor: product =>
            <div>
                <p className='mb-0 font-weight-bolder'>{product.name||'Sin información'}</p>
                <p className='mb-0 text-info'>ID: {product.id}</p>
            </div>
    },
    {Header: "Detalles", accessor: product =>
            <div>
                <p className='mb-0 text-uppercase text-medium font-weight-bolder text-justify'>{product.details||'Sin información'}</p>
            </div>
    },
    {Header: "Peso", accessor: product =>
            <div>
                <p className='mb-0 text-info text-small'>PESO</p>
                <p className='mb-0 font-weight-bolder'>{product.weight||'---'}</p>
            </div>
    },
    {Header: "Stock", accessor: product =>
            <div>
                <p className='mb-0 text-info text-small'>STOCK</p>
                <p className='mb-0 font-weight-bolder'>{product.stock}</p>
            </div>
    },
    {Header: "Grupo de mayoreo", accessor: product =>
            <div>
                <p className='mb-0 text-info text-small'>GRUPO DE MAYOREO</p>
                <p className='mb-0 font-weight-bolder'>{product.wholesaleGroup||'---'}</p>
            </div>
    },
    {Header: "Activo", accessor: product =>
            <div>
                <p className='mb-0 font-weight-bolder'>{product.isActive?'ACTIVO':'INACTIVO'}</p>
            </div>
    },
    {
        Header: 'Acciones',
        accessor: product =>
            (
                <div className="actionButtonsContainer">
                    <button className="btn button-top w-100 font-weight-bolder btn-danger py-1" onClick={() => deleteProduct(product)}>Eliminar</button>
                    <button className={"btn button-center font-weight-bolder w-100 py-1 " + (product.isActive ? 'btn-light-danger': 'btn-light-info')} onClick={() => toggleProductActive(product)}>{product.isActive === true ? 'Desactivar' : 'Activar'}</button>
                    <button
                        className="btn button-center font-weight-bolder w-100 py-1 btn-success"
                        onClick={() =>onProductEdit(product)}>
                        Editar
                    </button>
                    <button
                        className="btn button-bottom font-weight-bolder w-100 py-1 btn-info"
                        onClick={()=>onShowPrices(product.productPrices)}>
                        Ver precios
                    </button>
                </div>
            )
    }
]
