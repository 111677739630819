import React from 'react';
import ReactEcharts from "echarts-for-react";


const GraphWidget = ({color, xAxisLabels=[], title, data=[]}) => {


    const options = {
        grid: {
            left: '0',
            right: '0',
            bottom: '0',
            top: '0',
            containLabel: false
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        xAxis : [
            {
                type : 'category',
                boundaryGap : false,
                data : xAxisLabels,
            }
        ],
        yAxis : [
            {
                type : 'value',
                show:false,
            },
        ],
        series : [
            {
                name: title,
                type:'bar',
                color: color || '#ff7f7f',
                areaStyle: {
                    color: {
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: color || '#ff7f7f'
                        }, {
                            offset: 1, color: 'white'
                        }]
                    }
                },
                data,
                smooth: true,
                showSymbol: false,
            },
        ]
    };

    return (
        <div className={"GraphWidget dash-widget"}>
            <ReactEcharts
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{height: 100}}
            />
        </div>
    );
};

export default GraphWidget;
