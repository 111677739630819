import React, {useCallback, useContext} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import {ReactComponent as Icon} from "../../../assets/img/logo.svg";
import {paths} from "../../../services/routes/mainRoutes";
import {Link} from "react-router-dom";
import { getNotifier } from '../../../services/notifier';


const Login=()=>{
    const api=useContext(ApiContext);


    const {form, handleInputChange} = useFormState({});

    const login=useCallback((e)=>{
        e.preventDefault();
        if(!form.email || !form.pass)
            return;
        api.login(form.email,form.pass).catch(() => {
            getNotifier().error('No pudimos validar su cuenta, inténtalo nuevamente.')
        });
    },[api, form]);


    // --- login state message ------
    const loginState=useSelector(({api})=>api.loggedIn);
    const loading=useSelector(({loadingIds})=>loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    let loginMsg;
    switch (loginState) {
        case LOGIN_STATE.LOGIN_ERROR:
            loginMsg= 'Lo sentimos, hubo un error al iniciar sesión.';
            break;
        case LOGIN_STATE.BAD_CREDENTIALS:
            loginMsg= 'El correo y contraseña no coinciden';
            break;
        default:
            loginMsg= null;
            break;
    }



    return (
        <div className='Login'>

            <div className='login-form text-center p-7 position-relative overflow-hidden'>



			    <div className="mt-5 mb-15">
                    <Link to={paths.landing} className='links'>
                        <Icon className="max-h-75px icon"/>
                    </Link>
			    </div>
			    <div className="login-signin">
			    	<div className="mb-20">
			    		<h3>Inicia Sesión</h3>
			    		<div className="mentita-color font-weight-bold mb-5">Ingresa tus datos para iniciar sesión:</div>
			    	</div>
			    	<form className="form" id="kt_login_signin_form" onSubmit={login}>
			    		<div className="form-group mb-5">
			    			<input id='login-user-input' onChange={handleInputChange('email')} value={form.email||''} className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Ingresa tu correo electrónico" name="username" autoComplete="off" />
			    		</div>
			    		<div className="form-group mb-5">
			    			<input className="form-control h-auto form-control-solid py-4 px-8" id='login-pass-input' type='password' onChange={handleInputChange('pass')} value={form.pass||''} placeholder="Ingresa tu contraseña" name="password"/>
			    		</div>
			    		<div className="form-group d-flex px-5 flex-wrap justify-content-between align-items-center">
                            <Link to={paths.forgotPassword} id='kt_login_forgot' className='mentita-color text-hover-primary'>¿Olvidaste tu contraseña?</Link>
			    		</div>
			    		<button id="kt_login_signin_submit"  type='submit' disabled={loading} className="submit-button btn font-weight-bold px-9 py-4 my-3 mx-4">Iniciar sesión</button>
                        {loginMsg &&
                            <p className='error'>{loginMsg}</p>}
			    	</form>
			    	<div className="mt-10">
			    		<span className="opacity-70 mr-4">¿Aún no tienes cuenta?</span>
			    		<a href="/register" id="kt_login_signup" className="mentita-color text-hover-primary font-weight-bold">¡Regístrate!</a>
			    	</div>
			    </div>
            </div>
        </div>
    );
};

export default Login;
