import React, {useState} from 'react';
import './FieldEditor.scss';
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FieldEditor = ({ initialValue, onCancel, onConfirm, placeholder, disabled }) => {

    const [value, setValue] = useState(initialValue);

    const handleConfirm = ()=>{
        onConfirm(value);
    };

    return (
        <div className={"FieldEditor"}>
            <input className='field-input form-control'
                   placeholder={placeholder}
                   value={typeof value==='undefined'?'':value}
                   onChange={e=>setValue(e.target.value)}
            />
            <button onClick={handleConfirm} className='action-btn confirm' disabled={disabled}>
                <FontAwesomeIcon icon={faCheck}/>
            </button>
            <button onClick={onCancel} className='action-btn cancel' disabled={disabled}>
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </div>
    );
};

export default FieldEditor;
