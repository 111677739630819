import Login from "../../scenes/Public/Login/Login";
import Register from "../../scenes/Public/Register/Register";
import Landing from "../../scenes/Public/Landing/Landing";
import ForgotPassword from "../../scenes/Public/ForgotPassword/ForgotPassword";
import ValidateAccount from "../../scenes/Public/ValidateAccount/ValidateAccount";
import DashLayout from "../../scenes/DashLayout/DashLayout";
import ResetPassword from "../../scenes/Public/ResetPassword/ResetPassword";
import Products from "../../scenes/Public/Products/Products";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import Maintenance from "../../scenes/Public/Maintenance/Maintenance";

export const paths={
    login:'/login',
    loginHidden:'/login_hidden',
    register:'/register',
    forgotPassword: '/recover_password',
    resetPassword: '/reset_password',
    landing: '/inicio',
    valAccountBadSpelled: '/validate_acount',
    valAccount: '/validate_account',
    catalogue: 'https://www.flipsnack.com/mentitababy/sp0221/full-view.html',
    guests: '/invitados',
    thankyou: '/gracias',
};

const getMainRoutes =(loggedIn)=>{

    // Logged in routes. Another router inside DashLayout
    if(loggedIn)
        return [{path:'/', component: DashLayout}];
    // Public routes
    else
        return [
            {path:paths.landing, component: Landing},
            {path:paths.login, component: Login},
            {path:paths.loginHidden, component: Login},
            {path:paths.register, component: Register},
            {path:paths.forgotPassword, component: ForgotPassword},
            {path:paths.valAccountBadSpelled, component: ValidateAccount},
            {path:paths.valAccount, component: ValidateAccount},
            {path:paths.resetPassword, component: ResetPassword},
            {path:paths.guests, component: Products},
        ];
};

export const getGuestRoutes =(loggedIn)=>{
    const routes = [
        {path:paths.guests, name:'Productos', component: Products, icon: <FontAwesomeIcon icon={faShoppingBag} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='active-icon icon-dash-logo text-active-icon'/>}
    ];

    return routes;
}

export default getMainRoutes;
