import Catalogues from "../../scenes/Logged/Catalogues/Catalogues";
import SuperDashboard from "../../scenes/Logged/SuperDashboard/SuperDashboard";
import UsersAdmin from "../../scenes/Logged/UsersAmin/UsersAdmin";
import Orders from "../../scenes/Logged/Orders/Orders";
import ProductsIndex from "../../scenes/Logged/Products/ProductsIndex";
import BannerModal from "../../scenes/Logged/BannerModal/BannerModal"
import OrderDetails from "../../scenes/Logged/Orders/components/OrderDetails/OrderDetails";
import OrderEdit from "../../scenes/Logged/Orders/components/OrderEdit/OrderEdit";
import Reports from "../../scenes/Logged/Reports/Reports";
import Accounting from "../../scenes/Logged/Accounting/Accounting";
import LoansEdit from "../../scenes/Logged/Accounting/components/Loans/LoansEdit/LoansEdit";
import IncomesEdit from "../../scenes/Logged/Accounting/components/Incomes/IncomesEdit/IncomesEdit";
import ExpensesEdit from "../../scenes/Logged/Accounting/components/Expenses/ExpensesEdit/ExpensesEdit";
import { USER_ROLE } from "../../constants/sesion";

import {faBookmark, faBookOpen, faChartArea, faFileInvoiceDollar, faImage, faPaperclip, faShoppingBag, faUsers} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const paths={
    adminDashboard: '/admin',
    catalogues: '/catalogos',
    adminUsers: '/usuarios',
    orders: '/ordenes',
    products: '/productos',
    banner: '/banner',
    orderDetail: "/ordenes/:id",
    orderEdit: "/ordenes/:id/edit",
    reports: "/reportes",
    accounting: "/contabilidad",
    loansEdit: "/contabilidad/prestamos/:id/edit",
    incomesEdit: "/contabilidad/ingresos/:id/edit",
    expensesEdit: "/contabilidad/egresos/:id/edit"
};

const getAppRoutes =(securityManager)=>{
    let routes = [];
    if(securityManager?.role === USER_ROLE.SUPER_ADMIN || securityManager?.role === USER_ROLE.ROLE_GENERAL_ADMIN) {
        routes=[
            {path:paths.adminDashboard, name:'Dashboard', component: SuperDashboard , icon: <FontAwesomeIcon icon={faChartArea} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faChartArea} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.catalogues, name:'Catálogos', component: Catalogues, icon: <FontAwesomeIcon icon={faBookmark} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faBookmark} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.adminUsers, name:'Usuarios', component: UsersAdmin, icon: <FontAwesomeIcon icon={faUsers} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faUsers} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.products, name:'Productos', component: ProductsIndex, icon: <FontAwesomeIcon icon={faShoppingBag} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.orders, name:'Órdenes de compra', component: Orders, icon: <FontAwesomeIcon icon={faBookOpen} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faBookOpen} className='active-icon icon-dash-logo text-active-icon'/>, exact:true},
            {path:paths.banner, name:'Administrar banner', component: BannerModal, icon: <FontAwesomeIcon icon={faImage} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faImage} className='active-icon icon-dash-logo text-active-icon'/> },
            {path:paths.orderDetail, name:'Detalle de pedido', component: OrderDetails, hidden: true, exact: true},
            {path:paths.orderEdit, name:'Editar pedido', component: OrderEdit, hidden: true},
            {path:paths.reports, name:'Reportes', component: Reports, icon: <FontAwesomeIcon icon={faPaperclip} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faPaperclip} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.accounting, name:'Finanzas', component: Accounting, icon: <FontAwesomeIcon icon={faFileInvoiceDollar} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faFileInvoiceDollar} className='active-icon icon-dash-logo text-active-icon'/>, exact: true},
            {path:paths.loansEdit, name:'Editar prestamo', component: LoansEdit, hidden: true},
            {path:paths.incomesEdit, name:'Editar ingreso', component: IncomesEdit, hidden: true},
            {path:paths.expensesEdit, name:'Editar egreso', component: ExpensesEdit, hidden: true},
        ]
    } else if(securityManager?.role === USER_ROLE.ROLE_GENERAL_SELL) {
        routes=[
            {path:paths.adminUsers, name:'Usuarios', component: UsersAdmin, icon: <FontAwesomeIcon icon={faUsers} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faUsers} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.products, name:'Productos', component: ProductsIndex, icon: <FontAwesomeIcon icon={faShoppingBag} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.orders, name:'Órdenes de compra', component: Orders, icon: <FontAwesomeIcon icon={faBookOpen} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faBookOpen} className='active-icon icon-dash-logo text-active-icon'/>, exact:true},
            {path:paths.orderDetail, name:'Detalle de pedido', component: OrderDetails, hidden: true, exact: true},
            {path:paths.orderEdit, name:'Editar pedido', component: OrderEdit, hidden: true},
        ]
    }
    else {
        routes=[
            {path:paths.products, name:'Productos', component: ProductsIndex, icon: <FontAwesomeIcon icon={faShoppingBag} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='active-icon icon-dash-logo text-active-icon'/>},
            {path:paths.orders, name:'Órdenes de compra', component: Orders, icon: <FontAwesomeIcon icon={faBookOpen} className='dashboard-logo icon-dash-logo text-danger'/>, activeIcon: <FontAwesomeIcon icon={faBookOpen} className='active-icon icon-dash-logo text-active-icon'/>, exact:true},
            {path:paths.orderDetail, name:'Detalle de pedido', component: OrderDetails, hidden: true, exact: true},
            {path:paths.orderEdit, name:'Editar pedido', component: OrderEdit, hidden: true}
        ]
    }

    return routes;
};


export default getAppRoutes;
