
let PUBLICTOKEN = 'TEST-69197ff1-a4df-446c-a064-a4f0b2556743';

if(process.env.REACT_APP_BUILD === 'prod')
    PUBLICTOKEN = 'APP_USR-692b33a1-5b84-49f0-8c63-f561aabd1983';

export const MERCADOPAGO_PUBLICTOKEN = PUBLICTOKEN;

export const getEmptyPayment = ()=>({
    cardNumber: null,
    securityCode: null,
    cardExpirationMonth: null,
    cardExpirationYear: null,
    cardholderName: null
});
