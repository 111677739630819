import {useState, useMemo} from 'react';
import moment from "moment";


const defaultIsOutsideRange=(d)=>moment().isBefore(d);

const useDateRangePickerState = (initialValue={startDate:moment(), endDate:moment()})=>{

    const [dateRange, setDateRange]=useState(initialValue);
    const [focused, setFocused]=useState(null);

    return useMemo(()=>({
        props:{
            startDate: dateRange.startDate,
            endDate:dateRange.endDate,
            startDateId:"your_unique_start_date_id",
            endDateId:"your_unique_end_date_id",
            onDatesChange:setDateRange,
            focusedInput:focused,
            onFocusChange:setFocused,
            isOutsideRange: defaultIsOutsideRange,
            minimumNights:0,
            hideKeyboardShortcutsPanel:true
        },
        dateRange
    }),[dateRange, focused]);
};

export default useDateRangePickerState;
