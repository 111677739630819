import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import useBoolean from '../../../../../../hooks/useBoolean';
import useFormState from '../../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../../services/api/api-config';
import { getNotifier } from '../../../../../../services/notifier';
import "./LoansForm.scss";

const LoansForms = ({newLoanOff, loanObject}) => {
    const api = useContext(ApiContext);
    const [loading, isLoading, notLoading] = useBoolean();
    const history = useHistory();
    const goBack = useCallback((id) => history.push('/contabilidad?entity=Préstamos'), [history]);

    const {form, handleInputChange, setForm } = useFormState();

    useEffect(() => {
        if (loanObject) {
            setForm({
                name: loanObject.name,
                description: loanObject.description,
                interest: loanObject.interest,
                initialAmount: loanObject.initialAmount,
                actualAmount: loanObject.actualAmount,
            });
        }
    }, [loanObject, setForm]);

    const handleSaveLoan = useCallback(() => {
        isLoading();
        const request = api.loans[loanObject?"update":"create"]({
            id: loanObject?.id,
            params:{
                ...form,
                initialAmount: Number(form.initialAmount),
                actualAmount: Number(form.actualAmount),
            },
            customProp: 'loansTable',
        });
        request.then(() => {
            getNotifier().success('El préstamo se ha guardado correctamente');
            loanObject?goBack() : newLoanOff();
            notLoading();
        }).catch(() => {
            getNotifier().error('No se pudo crear el préstamo');
            notLoading();
        });
    }, [api, form, newLoanOff, isLoading, notLoading, loanObject, goBack]);

    const renderBack = useCallback(() => {
        if (loanObject) {
            goBack();
        } else {
            newLoanOff();
        }
    }, [goBack, newLoanOff, loanObject]);

    return (
        <div className="LoansForm">
            <div className="card card-custom gutter-b">
			    <div className="card-body pt-4">
			    	<form>
			    		{/*end::Product images*/}
			    		{/*begin::Product info*/}
			    		<div className="mt-6">
			    			<div className="font-size-h3 font-weight-bolder text-dark">Información del nuevo préstamo</div>
			    			{/*begin::Input*/}
                            <br></br>
			    			<div className="form-group mb-8">
			    				<label className="font-weight-bolder">Prestamista</label>
                                <input type="text" className="form-control" min={0} value={form.name} onChange={handleInputChange('name')}/>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 d-flex flex-column">
                                    <label className="font-weight-bolder">Descripción</label>
                                    <input type="text" className="form-control" min={0} value={form.description} onChange={handleInputChange('description')} />
                                </div>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 col-md-4 d-flex flex-column">
                                    <label className="font-weight-bolder">Interés <small>Solo informativo</small> </label>
                                    <input type="number" className="form-control" min={0} value={form.interest} onChange={handleInputChange('interest')} />
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Deuda inicial</label>
                                    <input min={0} type="number" className="form-control" value={form.initialAmount} onChange={handleInputChange('initialAmount')} />
                                </div>
                                <div className="col-12 col-md-4 mt-2 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Deuda actual</label>
                                    <input min={0} type="number" className="form-control" value={form.actualAmount} onChange={handleInputChange('actualAmount')} />
                                </div>
			    			</div>
                            <div className="separator my-1" />
			    		</div>
			    		{/*end::Product info*/}
						{/*end::Color*/}
						<button type="submit" className="btn btn-primary font-weight-bolder mr-2 px-8" disabled={loading} onClick={handleSaveLoan}>{loanObject?'Editar':'Crear'} préstamo</button>
			    		<button type="reset" className="btn btn-clear font-weight-bolder text-muted px-8" onClick={renderBack}>Cancelar</button>
			    		{/*end::Input*/}
			    	</form>
			    	{/*end::Form*/}
			    </div>
			    {/*end::Body*/}
			</div>
        </div>
    );
}

export default LoansForms;
