import React, {useCallback, useContext, useEffect} from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Toggle from 'react-bootstrap-toggle';
import TideEntitySelect from '../TideEntitySelect/TideEntitySelect';
import useFormState from '../../hooks/useFormState';
import { moneyFormatter } from '../../services/currencyUtils';
import {
    convertOrderToForm, getEmptyOrder, getOrderTotal, orderShippingCalculator,
    orderShippingCalculatorForGuest,
    orderShippingStatus,
    orderShippingStatusTrans, orderShippingStatusTransBackgroundColor,
    orderShippingStatusTransColor,
    orderStatus,
    orderStatusTrans,
    orderStatusTransColor, prepareOrderFormForServer
} from '../../services/modelUtils/orderUtils';
import "./OrderForm.scss";
import {getUserFullName, userForOrderFilters
} from "../../services/modelUtils/userUtils";
import useShippingZones from "../../hooks/useShippingZones";
import OrderProductsListForm from "./components/OrderProductsListForm/OrderProductsListForm";
import {getNotifier} from "../../services/notifier";
import {ApiContext} from "../../services/api/api-config";
import {useSelector} from "react-redux";

const OrderForm = ({ onClose, orderToEdit = null }) => {

    // App variables
    const api = useContext(ApiContext);
    const shippingZones = useShippingZones();

    //state
    const { form, setForm, handleSimpleChange, handleInputChange } = useFormState( ()=>getEmptyOrder() );

    //Derived data
    let automaticShipping = (!form?.guest?.id ? 
                    orderShippingCalculator(form, shippingZones) :
                    orderShippingCalculatorForGuest(form, form.guest.neighbourhood, shippingZones) );


    const shipping = form.manualShipping? form.shipping : automaticShipping;
    const total = getOrderTotal(form, shipping);

    //Pre-fill the form if editing
    useEffect(()=>{
        setForm(convertOrderToForm(orderToEdit));
    },[orderToEdit, setForm]);
    //Clean users cache to prevent stale data
    const userCustomProp = "OrderForm.userSelector";
    useEffect(()=>{
        api.clearProperty(userCustomProp);
    },[api]);

    // Send order to server
    const loadingId = "OrderForm.orders";
    const handleSaveOrder = useCallback((e)=>{
        e.preventDefault();
        let order;
        try{
            order = prepareOrderFormForServer({...form, total, shipping });
        }
        catch (e){
            return getNotifier().error(e.message);
        }

        api.orders[ orderToEdit?"update":"create" ]({ id:order.id, params:order, loadingId }).then(()=>{
            getNotifier().success("Orden guardada correctamente");
            onClose && onClose();
        })

    },[api, form, shipping, total, orderToEdit, onClose]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);
    
    const canEditOrder = (order) => {
        return (order?.status==='pending' || !order);
    };
    
    return (
        <div className="OrderForm">
            <div className="card card-custom gutter-b">
                <div className="card-body pt-4">
                    <form>
                        {/*end::Product images*/}
                        {/*begin::Product info*/}
                        <div className="mt-6">
                            <div className="close-container d-flex justify-content-between p-1 cursor-pointer" >
                                <div className="font-size-h3 font-weight-bolder text-dark mb-4 font-weight-bolder font-size-lg">Información de la orden</div>
                                {!!onClose && <FontAwesomeIcon icon={faTimes} className='close-icon pink-color' onClick={onClose} />}
                            </div>

                            {/*begin::Input*/}
                            <div className="form-group mb-8">
                                {canEditOrder(orderToEdit) ?
                                    <>
                                        <label className="font-weight-bolder">Selecciona una aliada</label>
                                        <TideEntitySelect
                                            entity='users'
                                            filterBy='search'
                                            labelCreator={getUserFullName}
                                            value={form.user}
                                            onChange={handleSimpleChange('user')}
                                            additionalFilters={userForOrderFilters}
                                            apiCustomProp={userCustomProp}
                                            disabled={!canEditOrder(orderToEdit)}
                                        /> 
                                    </>: 
                                    <div>
                                        <label className="font-weight-bolder">Aliada</label>
                                        <div>{getUserFullName(form.user)}</div>
                                    </div>}
                            </div>
                            <div className="form-group mb-8 row">
                                <div className="col-12 col-md-6">
                                    <label className="font-weight-bolder">Selecciona un status de pago</label>

                                    <div className="form-group mb-0">
                                        <select className="form-control" 
                                            id="paymentStatusSelect" 
                                            style={{color: orderStatusTransColor[form.status] }} 
                                            value={form.status || 'Selecciona una opción'} 
                                            onChange={handleInputChange('status')}
                                        >
                                            {_.map(orderStatus, (status) =>
                                                <option className='font-bold' style={{color: orderStatusTransColor[status] }} value={status} key={status}>{orderStatusTrans[status]}</option>
                                            )}
                                        </select>
                                    </div>                              
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="font-weight-bolder">Selecciona un status de entrega</label>
                                    <div className="form-group mb-0">
                                        <select className="form-control" 
                                            id="shippingStatusSelect" 
                                            style={{color: orderShippingStatusTransColor[form.shippingStatus] }} 
                                            value={form.shippingStatus || 'Selecciona una opción'} 
                                            onChange={handleInputChange('shippingStatus')}
                                        >
                                            {_.map( orderShippingStatus , (status) =>
                                                <option className='font-bold pt-5' style={{color: orderShippingStatusTransColor[status], backgroundColor: orderShippingStatusTransBackgroundColor[status] }} value={status} key={status}>{ orderShippingStatusTrans[status]}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            {form.payment?.paymentMethod?.name && 
                                <div className="form-group mb-8 row">
                                    <div className="col-12 col-md-6">
                                        <label className="font-weight-bolder">Forma de pago</label>

                                        <div className="form-group mb-0">
                                            { form.payment?.paymentMethod?.name }
                                        </div>                              
                                    </div>
                                </div>}
                            <div className="form-group mb-8 row">
                                <div className="col-12">
                                    <label className="font-weight-bolder">Notas</label>
                                    
                                    <textarea 
                                        className="form-control" 
                                        value={form.comment || ''} 
                                        onChange={handleInputChange('comment')} 
                                        placeholder='...' 
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Número de rastreo</label>
                                    
                                    <input type="text" 
                                        className="form-control" 
                                        value={form.trackingNumber || ''} 
                                        onChange={handleInputChange('trackingNumber')} 
                                        placeholder='#'
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Costo de envío</label>
                                    <div className="d-flex">
                                        {form.manualShipping ?
                                            <input type="number" 
                                                className="form-control mb-5" 
                                                value={form.shipping || ""} 
                                                onChange={handleInputChange('shipping')} 
                                                disabled={!canEditOrder(orderToEdit)}
                                            />
                                        :
                                            <p className="form-control form-control-solid">$ { moneyFormatter( automaticShipping )}</p>
                                        }
                                        <div className='d-flex align-items-center manual-shipping-container'>
                                            <span className='auto-switch-label'>Auto</span>
                                            <Toggle
                                                onClick={handleSimpleChange('manualShipping')}
                                                on={null}
                                                off={null}
                                                className='additional-button mt-2'
                                                size="s"
                                                width='50px'
                                                height='30px'
                                                recalculateOnResize={true}
                                                offstyle="danger"
                                                active={form.manualShipping}
                                                disabled={!canEditOrder(orderToEdit)}
                                            />
                                            <span className='auto-switch-label'>Manual</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Saldo de usuario</label>
                                    <p className="form-control form-control-solid">$&nbsp;{moneyFormatter( Number(form?.user?.profile?.balance)||0 )}</p>
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Saldo usado</label>
                                    <input min={0} type="number" 
                                        className="form-control" 
                                        value={form?.balanceUsed || ""} 
                                        onChange={handleInputChange('balanceUsed')} 
                                        disabled={!canEditOrder(orderToEdit)}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Total</label>
                                    <p className="form-control form-control-solid">$&nbsp;{moneyFormatter( total )}</p>
                                </div>
                                <div className="col-12 col-md-6 mt-2 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Descuento</label>
                                    <input min={0} type="number" 
                                        className="form-control" 
                                        value={form.discount || ""} 
                                        onChange={handleInputChange('discount')} 
                                        disabled={!canEditOrder(orderToEdit)}
                                    />
                                </div>
                            </div>
                            <div className="separator my-1" />

                            <OrderProductsListForm 
                                orderProducts={form.orderProducts} 
                                order={orderToEdit} 
                                onChange={handleSimpleChange('orderProducts')} 
                                canEditOrder={canEditOrder}
                            />

                        </div>
                        {/*end::Product info*/}
                        {/*end::Color*/}
                        
                        <button onClick={handleSaveOrder} disabled={loading} className="btn btn-primary font-weight-bolder mr-2 px-8 mt-4">Guardar orden</button>
                        
                        {!!onClose &&
                        <button onClick={onClose} disabled={loading} className="btn btn-clear font-weight-bolder text-muted px-8">Cancelar</button>}
                        {/*end::Input*/}
                        <div className='clearfix' />
                    </form>
                    {/*end::Form*/}
                </div>
                {/*end::Body*/}
            </div>
        </div>
    );
}

OrderForm.displayName="OrderForm";

export default OrderForm;
