import React, {useCallback, useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import {displayProductsOptions} from "../../../../../services/modelUtils/productUtils";
import TideTableFilters from "../../../../../components/TideTableFilters/TideTableFilters";
import {ApiContext} from "../../../../../services/api/api-config";
import OrderForm from "../../../../../components/OrderForm/OrderForm";
import useBoolean from "../../../../../hooks/useBoolean";
import useCallbackCreator from "use-callback-creator";
import { categoriesSGroups } from '../../../../../services/modelUtils/categoriesUtils';
import { getNotifier } from '../../../../../services/notifier';

const ProductsAdminHeader = ({ filters, onFiltersChange, viewStyle, setViewStyle, onNewProductClick }) => {

    const api = useContext(ApiContext);
    const [categories, setCategories] = useState([]);
    const [createOrder, orderOn, orderOff] = useBoolean();

    useEffect(() => {
		api.categories.get({params: {sGroups: categoriesSGroups}})
			.then(setCategories)
			.catch((err) => getNotifier().error(err.detail));
	}, [api]);	

    const handleInputChange = useCallbackCreator( (field, event)=>{
        const value = event.target.value;
        onFiltersChange( filters=>{
            const newFilters = {...filters, [field]:value };
            if(value)
                return newFilters;
            delete newFilters[field];
            return newFilters;
        } );
    },[onFiltersChange]);

    const handleOrderChange = useCallbackCreator( (field)=>{
        onFiltersChange( filters=>{
            const newFilters = {...filters};
            if(!filters[field])
                newFilters[field] = 'ASC' ;
            else if(filters[field]==='ASC')
                newFilters[field] = 'DESC' ;
            else
                delete newFilters[field];
            return newFilters;
        } );
    },[onFiltersChange]);

    const handleActiveToggle = useCallback(()=>{
        onFiltersChange(filters=>{
            const newFilters = {...filters};
            if(newFilters.isActive === true )
                delete newFilters.isActive;
            else
                newFilters.isActive = true;
            return newFilters;
        })
    },[onFiltersChange]);

    const excelUrl = api.products.exportExcelReport(filters);

    const filtersConfig = [
        {
            name: 'nombre',
            onChange: handleInputChange('name'),
            value: filters.name,
            type: 'text',
            placeholder: 'Filtrar por nombre',
            orderOnChange: handleOrderChange('order[name]'),
            orderStatus: filters['order[name]'],
        },
        {
            name: 'id',
            onChange: handleInputChange('id'),
            value: filters.id,
            type: 'text',
            placeholder: 'Filtrar por id',
            orderOnChange: handleOrderChange('order[id]'),
            orderStatus: filters['order[id]'],
        },
        {
            labelColor: 'color-purple',
            placeholder: 'Filtrar por detalles',
            name: 'detalles',
            onChange: handleInputChange('details'),
            value: filters.details,
            type: 'text',
        },
        {
            placeholder: 'Filtrar por peso',
            name: 'peso',
            onChange: handleInputChange('weight'),
            value: filters.weight,
            type: 'number',
            orderOnChange: handleOrderChange('order[weight]'),
            orderStatus: filters['order[weight]'],
        },
        {
            placeholder: 'Filtrar por stock',
            name: 'stock',
            onChange: handleInputChange('stock'),
            value: filters.stock,
            type: 'number',
            orderOnChange: handleOrderChange('order[stock]'),
            orderStatus: filters['order[stock]'],
        },
        {
            placeholder: 'Filtrar por grupo',
            name: 'grupo',
            onChange: handleInputChange('wholesaleGroup'),
            value: filters.wholesaleGroup,
            type: 'text',
            orderOnChange: handleOrderChange('order[wholesaleGroup]'),
            orderStatus: filters['order[wholesaleGroup]'],
        },
        {
            placeholder: 'Filtrar por categoría',
            label: 'Filtrar por categoría',
            name: 'category',
            onChange: handleInputChange('category'),
            value: filters.category,
            type: 'select',
            options: categories,
            orderOnChange: handleOrderChange('order[category]'),
            orderStatus: filters['order[category]'],
        },
        {
            placeholder: filters.isActive?'Mostrar todos':'Mostrar solo activos',
            onChange: handleActiveToggle,
            value: filters.isActive,
            type: 'boolean',
        },
    ];

    return (
        <div className="ProductsAdminHeader d-flex flex-column w-100">
            <div className="card-toolbar mt-4">
                <ul className="nav nav-pills nav-pills-sm nav-dark-75 justify-content-center justify-content-md-end">
                    <li className="nav-item">
                        <a href={excelUrl} className="excelButton btn btn-white d-flex justify-content-center">
                            <FontAwesomeIcon icon={faFileExcel} className="text-danger" />
                        </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <select className="custom-select pb-2" value={viewStyle} onChange={(e) => setViewStyle(e.target.value)}>
                            {_.map(displayProductsOptions, (option, index) => (
                                <option className='font-bold' key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <span className="nav-link text-light py-2 px-4 btn-info cursor-pointer" data-toggle="tab" onClick={onNewProductClick}>Añadir Producto</span>
                    </li>
                    <li className="nav-item cursor-pointer">
                        <span className="nav-link text-light py-2 px-4 btn-green cursor-pointer" data-toggle="tab" onClick={orderOn}>Crear Orden</span>
                    </li>
                </ul>
            </div>
            <div className="d-flex justify-content-center justify-content-md-start w-100">
                <TideTableFilters inputsObject={filtersConfig} />
            </div>

            {createOrder &&
            <OrderForm onClose={orderOff}/>}

        </div>
    );
};

export default ProductsAdminHeader;
