
export const categories = {
    LOAN_INTEREST_PAYMENT: "loan_interest_payment_category",
    LOAN_MAIN_PAYMENT: "loan_main_payment_category",
    ORDER_PAYMENT: "order_payment_category",
}

export const listOfCategoriesIdsThatCannotRemove = [
    'loan_interest_payment_category',
    'loan_main_payment_category',
    'order_payment_category',
    're_stock_payment_category'
];
