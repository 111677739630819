import React, { useContext, useEffect, useState } from 'react';
import useBoolean from '../../../hooks/useBoolean';
import { ReactComponent as Icon } from "../../../assets/img/logo.svg";
import ProductsMosaicView from "./components/ProductsMosaicView/ProductsMosaicView";
import "./Products.scss";
import SideBar from './components/SideBar/SideBar';
import RegisterAndCreateOrderModal from './components/RegisterAndCreateOrderModal/RegisterAndCreateOrderModal';
import { categoriesSGroups } from '../../../services/modelUtils/categoriesUtils';
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';

const emptyCart = {
    cartProducts: []
}

const customProp = 'PublicProducts.productTable';

const defaultFilters = {'order[name]': 'ASC'};

const Products = () => {
    const api = useContext(ApiContext);

    const [filters, setFilters] = useState({ ...defaultFilters });
    const [hidden,,, toggleHidden] = useBoolean(false);
    const [hideAside,,, toggleHideAside] = useBoolean(false);    
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart'))||emptyCart);
    const [category, setCategory] = useState();
    const [categories, setCategories] = useState([]);

    const [showRegisterModal, setShowRegisterModal] = useState(false)

    useEffect(() => {
        // save cart in the local storage
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    useEffect(() => {
        if(category)
            setFilters(prev => ({...prev, 'category': category}));
        else    
            setFilters({...defaultFilters});
    }, [category]);    
    
    useEffect(() => {
		api.categories.get({params: {sGroups: categoriesSGroups}, useCommonPath: false})
			.then(setCategories)
			.catch((err) => getNotifier().error(err.detail));
	}, [api]);

    return (
        <div className={"Products"}>
            <div id="kt_header_mobile" className="header-mobile">
                <a href="/">
                    <Icon className={'logo-icon mb-4 logo-default max-h-30px'} />
                </a>
                <div className="d-flex align-items-center">
                    <button className="btn p-0 burger-icon burger-icon-left responsive-burguer" id="kt_aside_mobile_toggle" onClick={toggleHideAside}>
                        <span />
                    </button>
                </div>
            </div>

            <SideBar 
                hideAside={hideAside} 
                hidden={hidden} 
                toggleHidden={toggleHidden} 
                toggleHideAside={toggleHideAside} 
                cart={cart}
                setCart={setCart}
                handleShowRegister={() => setShowRegisterModal(true)}
            />

            <div className={(hidden ? 'hidden' : 'main-content')}>
                <div className={"ProductsContainer"}>
                    <div className="d-flex flex-row">
                        <div className="flex-row-fluid ml-lg-8">
                            <div className="card products-index card-custom card-stretch gutter-b">
                                <div className="card-body">
                                    <div className="card card-custom mb-12" style={{ backgroundColor: "transparent" }}>
                                        <div className="card-body content-header text-center rounded p-0 d-flex flex-column" style={{ backgroundColor: "transparent" }}>
                                            <h1 className="section-title mx-auto color-purple mb-0">
                                                Mentita Baby, línea de productos de grado alimenticio ideales para las diferentes etapas de desarrollo y alimentación de tu bebé.
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="mb-11">
                                        <div className="d-flex justify-content-end align-items-center mb-7 flex-column flex-md-row">
                                            <div>
                                                <h2 className="font-weight-bolder color-purple font-size-h3 mb-0">Lista de productos</h2>
                                                <div className="d-flex mt-2">
                                                    <select className="form-control mr-3"
                                                        
                                                        onChange={e => setCategory(e.target.value)}
                                                    >
                                                        <option value="Selecciona una categoría">Sin categoría</option>
                                                        {categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                                    </select>

                                                    <input className="form-control mr-sm-2" type="search" placeholder="Buscar por nombre" aria-label="Search" value={filters.name} onChange={e => setFilters({ ...filters, name: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center font-weight-bolder font-size-h1 color-purple'>
                                            Inicia tu orden de compra seleccionando los productos al carrito
                                        </div>

                                        <ProductsMosaicView
                                            filters={filters}
                                            customProp={customProp}
                                            cart={cart}
                                            setCart={setCart}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showRegisterModal && <RegisterAndCreateOrderModal cart={cart} onClose={() => setShowRegisterModal(false)} resetCart={() => {
                setCart(emptyCart);
                localStorage.setItem('cart', JSON.stringify(emptyCart));
            }} />}
        </div>
    );
}

Products.displayName = "Products";

export default Products;
