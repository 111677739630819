import React, {useState} from 'react';
import "../../../assets/styles/theme.scss";
import "./Catalogues.scss"
import AsideBar from "./components/AsideBar/AsideBar";
import CatalogueList from './components/CatalogueList/CatalogueList';
import ProductCategoriesAdmin from './components/ProductCategoriesAdmin/ProductCategoriesAdmin';
import ShippingZoneAdmin from "./components/ShippingZonesAdmin/ShippingZoneAdmin";

const Catalogues = () => {
	const [catalogue, setCatalogue] = useState();

	const cataloguesList = [
	    {name: 'Métodos de pago cliente', entityName:'paymentMethods', filters:  ['Ingresos', 'Egresos']},
        {name: 'Categorías de pago / compras', entityName:'paymentCategories', filters:  ['Ingresos', 'Egresos']},
        {name: 'Proveedores', entityName:'providers', },
        {name: 'Zonas de envío', Component: ShippingZoneAdmin },
        {name: 'Categorías de productos', Component: ProductCategoriesAdmin },
    ];

	return (
        <div className="Catalogues d-flex">
			<AsideBar catalogue={catalogue} setCatalogue={setCatalogue} catalogues={cataloguesList} />
			<CatalogueList catalogue={catalogue} />
        </div>
    );
}

Catalogues.displayName="Catalogues";

export default Catalogues;
