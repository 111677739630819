import React, { useState } from 'react';
import "./Reports.scss";
import ReportWidget from "./components/ReportWidget";

const Reports = () => {
    const [distributor, setDistributor] = useState();

    return (
        <div className="Reports">
            <ReportWidget  title="Órdenes de compra"
                entity='orders'
                query={true}
                valueEntity={distributor}
                setValueEntity={setDistributor}
                useSelect={true}
                useDateRange={true}

            />
            <ReportWidget title="Aliadas" entity='users' />
            <ReportWidget title="Inventario Actual" entity='products' />
            <ReportWidget title="Programa de aliadas" entity='referrals' />
        </div>
    );
}

Reports.displayName="Reports";

export default Reports;
