import React, { useContext } from 'react';
import "./DashLayout.scss";
import SideBar from './components/SideBar/SideBar';
import {ReactComponent as Icon} from "../../assets/img/logo.svg";
import ProfileBar from './components/ProfileMenu/ProfileMenu';
import {Redirect, Route, Switch} from "react-router-dom";
import {SecurityContext} from "../../services/SecurityManager";
import getAppRoutes from "../../services/routes/appRoutes";
import useBoolean from "../../hooks/useBoolean";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import useShippingZones from "../../hooks/useShippingZones";

const DashLayout = () => {
    const security = useContext(SecurityContext);
    const routes = getAppRoutes(security);
    const [hidden,,, toggleHidden] = useBoolean(false);
    const [hideAside,,, toggleHideAside] = useBoolean(false);
    const [showProfile,,, toggleProfile] = useBoolean(false);

    //Preload shipping zone to have them always available
    useShippingZones();

    return (
        <div className={"DashLayout"}>
            <div id="kt_header_mobile" className="header-mobile">
			    <a href="index.html">
                    <Icon className={'logo-icon mb-4 logo-default max-h-30px'}/>
			    </a>
			    <div className="d-flex align-items-center">
			    	<button className="btn p-0 burger-icon burger-icon-left responsive-burguer" id="kt_aside_mobile_toggle" onClick={toggleHideAside}>
			    		<span />
			    	</button>
			    </div>
		    </div>

            <SideBar hideAside={hideAside} hidden={hidden} toggleHidden={toggleHidden} toggleProfile={toggleProfile}  toggleHideAside={toggleHideAside}/>
            { showProfile && <ProfileBar showProfile={showProfile} toggleProfile={toggleProfile}/>}
            <div className={(hidden ? 'hidden': 'main-content')}>
                <Switch>
                    {routes.map((route, i) =>
                        <Route
                            path={route.path}
                            component={route.component}
                            key={i}
                            exact={route.exact === true}/>
                    )}
                    {!!routes.length &&
                    <Redirect to={routes[0].path}/>}
                </Switch>
            </div>
        </div>
    );
}

DashLayout.displayName="DashLayout";

export default DashLayout;
