export const incomesTableSGroups = [
    'payment_read',
    'payment_read_order',
    'order_read',
    'payment_read_payment_method',
    'payment_method_read',
    'payment_read_payment_categories',
    'payment_category_read',
    'created_date'
];

export const expensesTableSGroups = [
    'payment_read',
    'payment_read_provider',
        'provider_read',
    'payment_read_payment_method',
        'payment_method_read',
    'payment_read_payment_categories',
        'payment_category_read',
    'created_date'
];

export const paymentsSGroups = [
    'payment_read',
    'payment_read_provider',
    'provider_read',
    'payment_read_order',
    'order_read',
    'payment_read_payment_method',
    'payment_method_read',
    'payment_read_payment_categories',
    'payment_category_read',
    'created_date'
];