import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import "./MonthlyGraphWidget.scss";
import GraphWidget from '../GraphWidget/GraphWidget';
import moment from "moment";

const MonthlyGraphWidget = () => {

    const api = useContext(ApiContext);
    const [graphData, setGraphData] = useState({ labels: [], data: [], yearSum: 0, monthSum: 0 });

    useEffect(() => {
        api.orders.monthlyStats().then( stats=>{
            const labels = [];
            const data = [];
            let yearSum = 0;
            let monthSum = 0;
            const now = new Date();
            stats.forEach( stat => {
                labels.push(stat.year+'-'+moment.monthsShort(stat.month-1).toUpperCase());
                data.push(Number(stat.totalAmount).toFixed(2));
                if( Number(stat.year) === now.getFullYear() ){
                    yearSum += Number(stat.totalAmount)||0;
                }
                if(Number(stat.month) === now.getMonth()+1){
                    monthSum = Number(stat.totalAmount)||0;
                }
            });
            setGraphData({ labels, data, yearSum, monthSum });
        });
    }, [api]);

    return (
        <div className="MonthlyGraphWidget d-flex flex-column justify-content-around">
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-bold magenta-color">Ventas</span>
                    </h3>
                </div>
                <div className="card-body pt-0 pb-0 my-3">
                    <div className="row">
                        <div className="col-12">
                            <GraphWidget data={ graphData.data } xAxisLabels={ graphData.labels } />
                        </div>
                        <div className="col-12 d-flex flex-wrap mt-5 justify-content-around">
                            <div className="container-card">
                                <h5 className='text-info text-small text-uppercase'>Ventas del mes:</h5>
                                <p className='font-weight-bolder'>$&nbsp;{moneyFormatter( graphData.monthSum )}</p>
                            </div>
                            <div className="container-card">
                                <h5 className='text-info text-small text-uppercase'>Ventas del año:</h5>
                                <p className='font-weight-bolder'>$&nbsp;{moneyFormatter( graphData.yearSum )}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MonthlyGraphWidget;
