import classNames from 'classnames'
import React from 'react'

import './CategoryLabel.scss'

const CategoryLabel = ({ className='', children }) => {
  return (
    <div className={ classNames('CategoryLabel', className) }>
        { children || 'Sin categoría' }
    </div>
  )
}

export default CategoryLabel;