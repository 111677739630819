import React, {useContext, useCallback, useMemo, useRef} from 'react';
import "./Orders.scss";
import TideReactTable from "../../../components/TideReactTable/TideReactTable";
import {faEdit, faEye, faFileDownload, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ApiContext } from '../../../services/api/api-config';
import useTideTable from '../../../hooks/useTideTable';
import useBoolean from '../../../hooks/useBoolean';
import {
    orderShippingStatusTrans,
    orderShippingStatus,
    orderStatus,
    orderStatusTrans,
    orderTableSGroups,
    orderStatusTransColor,
    orderShippingStatusTransColor, orderStatusTransBackgroundColor, orderShippingStatusTransBackgroundColor,
    getTotalWithPaymentMethod
} from '../../../services/modelUtils/orderUtils';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { moneyFormatter } from '../../../services/currencyUtils';
import { paths } from '../../../services/routes/appRoutes';
import useFormState from '../../../hooks/useFormState';
import TideTableFilters from '../../../components/TideTableFilters/TideTableFilters';
import { USER_ROLE } from '../../../constants/sesion';


const requestOptions={customProp: 'ordersTable'};
const Orders = () => {
    const api = useContext(ApiContext);
    const [disable, disabledOn, disabledOff] = useBoolean();
    const history = useHistory();
    const me=useSelector(({api})=>api.me);
    const { form, handleInputChange } = useFormState({});

    const goToDetail = useCallback((id) => history.push(paths.orderDetail.replace(':id', id)), [history]);
    const goToEdit = useCallback((id) => history.push(paths.orderEdit.replace(':id', id)), [history]);
    

    const tableRef = useRef();
    
    const handleOrderStatusChange = useCallback((e, order) => {
        disabledOn();
        api.orders.update({id: order.id+'', params: {status: e.target.value, sGroups: orderTableSGroups(me)}})
            .then(()=>{disabledOff();tableRef.current.reload();}).catch(disabledOff);
    }, [api, disabledOn, disabledOff, me, tableRef]);

    const handleOrderShippingStatusChange = useCallback((e, order) => {
        disabledOn();
        api.orders.update({id: order.id+'', params: {shippingStatus: e.target.value, sGroups: orderTableSGroups(me)}})
            .then(()=>{disabledOff();tableRef.current.reload();}).catch(disabledOff);
    }, [api, disabledOn, disabledOff, me, tableRef]);

    const AdminColumns = useMemo(() => [
        {Header: "ID ODC", accessor: order => order.id},
        {Header: "Aliada", accessor: order => {
            return (order.guest ? 'INVITADO: ' + order.guest?.name + ' ' + order.guest?.lastname : order.user?.name + ' ' + order.user?.lastNames)||'Sin información'
        }},
        {
            id: 'shippingStatus',
            Header: "Estado de entrega",
            Cell: ({row: {original} = {}}) =>
            <div className="form-group mb-0">
                <select className="form-control"
                        value={original.shippingStatus||'Sin status'}
                        disabled={disable}
                        style={{color: orderShippingStatusTransColor[original.shippingStatus] }}
                        onChange={(e) => handleOrderShippingStatusChange(e, original)}>
                    {_.map( orderShippingStatus, (val)=>
                        <option className='font-bold' value={val} style={{color: orderShippingStatusTransColor[val] }} key={val}>{orderShippingStatusTrans[val]}</option>
                    )}
                </select>
            </div>
        },
        {
            id: 'status',
            Header: "Estado de pago",
            Cell: ({row: {original} = {}}) =>
                <div className="form-group mb-0">
                    <select className="form-control"
                            value={original.status||'Sin status'}
                            disabled={disable}
                            style={{color: orderStatusTransColor[original.status] }}
                            onChange={(e) => handleOrderStatusChange(e, original)}>
                        {_.map( orderStatus, (val)=>
                            <option className='font-bold' value={val} key={val} style={{color: orderStatusTransColor[val] }}>{orderStatusTrans[val]}</option>
                        )}
                    </select>
                </div>
        },
        {Header: "Saldo usado", accessor:  order=> <div>$&nbsp;{ moneyFormatter(order?.balanceUsed) }</div>},
        {Header: "Total", accessor:  order=> getTotalWithPaymentMethod(order)},
        {Header: "Número de rastreo", accessor:  order=> order.trackingNumber||'Sin información'},
        {
            Header: "Acciones de ODC",
            Cell: ({row: {original} = {}}) =>
            <div className='d-flex'>
                <button className='btn btn-danger' onClick={() => goToEdit(original.id)}>
                    <FontAwesomeIcon icon={faEdit}/>
                </button>
                <button className='btn btn-info mx-2' onClick={() => goToDetail(original.id)}>
                    <FontAwesomeIcon icon={faEye}/>
                </button>
                <a className='btn btn-danger' target="_blank" rel="noreferrer noopener" href={api.orders.exportPdfUrl(original)}>
                    <FontAwesomeIcon icon={faFileDownload}/>
                </a>
            </div>
        },
    ], [handleOrderStatusChange, handleOrderShippingStatusChange, disable, goToDetail, goToEdit, api]);

    const DistribuitorColumns = useMemo(() => [
        {Header: "ID ODC", accessor: order => order.id},
        {Header: "Aliada", accessor: order => order.user?.name||'Sin información'},
        {Header: "Saldo usado", accessor:  order=> <p>$&nbsp;{ moneyFormatter(order?.balanceUsed) }</p>},
        {Header: "Total", accessor:  order=> <p>$&nbsp;{moneyFormatter(order.total)}</p>},
        {Header: "Estatus de entrega", accessor:  order=>
            <div className='status-container' style={{backgroundColor: orderShippingStatusTransBackgroundColor[order.shippingStatus]}}>
                <p className='mb-0 font-bold' style={{color: orderShippingStatusTransColor[order.shippingStatus] }}>{orderShippingStatusTrans[order.shippingStatus]||'Sin información'}</p>
            </div>
        },
        {Header: "Estatus de pago", accessor:  order=>
            <div className='status-container' style={{backgroundColor: orderStatusTransBackgroundColor}}>
                <p className='mb-0 font-bold' style={{color: orderStatusTransColor[order.status] }}>{orderStatusTrans[order.status]||'Sin información'}</p>
            </div>
        },
        {
            Header: "Detalles de orden de compra",
            Cell: ({row: {original} = {}}) =>
            <button className='btn btn-info' onClick={() => goToDetail(original.id)}>
                Ver
            </button>
        },
        {
            Header: "Descargar PDF",
            Cell: ({row: {original} = {}}) =>
                <a target="_blank" rel="noreferrer noopener" href={api.orders.exportPdfUrl(original)}>
            <button className='btn btn-info d-flex align-items-center'>
                <FontAwesomeIcon icon={faFilePdf} className="h-75 cursor-pointer mr-2"/>
                Descargar
            </button></a>
        },
    ], [goToDetail, api]);

    const SellColumns = useMemo(() => [
        {Header: "ID ODC", accessor: order => order.id},
        {Header: "Aliada", accessor: order => {
                return (order.guest ? 'INVITADO: ' + order.guest?.name + ' ' + order.guest?.lastname : order.user?.name + ' ' + order.user?.lastNames)||'Sin información'
            }},
        {Header: "Estatus de entrega", accessor:  order=>
                <div className='status-container' style={{backgroundColor: orderShippingStatusTransBackgroundColor[order.shippingStatus]}}>
                    <p className='mb-0 font-bold' style={{color: orderShippingStatusTransColor[order.shippingStatus] }}>{orderShippingStatusTrans[order.shippingStatus]||'Sin información'}</p>
                </div>
        },
        {Header: "Estatus de pago", accessor:  order=>
                <div className='status-container' style={{backgroundColor: orderStatusTransBackgroundColor}}>
                    <p className='mb-0 font-bold' style={{color: orderStatusTransColor[order.status] }}>{orderStatusTrans[order.status]||'Sin información'}</p>
                </div>
        },
        {Header: "Saldo usado", accessor:  order=> <div>$&nbsp;{ moneyFormatter(order?.balanceUsed) }</div>},
        {Header: "Total", accessor:  order=> getTotalWithPaymentMethod(order)},
        {Header: "Número de rastreo", accessor:  order=> order.trackingNumber||'Sin información'},
        {
            Header: "Acciones de ODC",
            Cell: ({row: {original} = {}}) =>
                <div className='d-flex'>
                    <button className='btn btn-danger' onClick={() => goToEdit(original.id)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </button>
                    <button className='btn btn-info mx-2' onClick={() => goToDetail(original.id)}>
                        <FontAwesomeIcon icon={faEye}/>
                    </button>
                    <a className='btn btn-danger' target="_blank" rel="noreferrer noopener" href={api.orders.exportPdfUrl(original)}>
                        <FontAwesomeIcon icon={faFileDownload}/>
                    </a>
                </div>
        },
    ], [goToDetail, goToEdit, api]);

    const requestAdminFilters = useMemo(()=>({
        sGroups: ['user_read', 'order_read_user', 'order_read', 'created_date', 'user_read', 'user_read_profile', 'profile_read', 'order_read_payment', 'payment_read_payment_method', 'payment_method_read', 'order_read_guest', 'guest_read'],
        "order[createdDate]": "DESC",
        status: form.paymentStatus,
        shippingStatus: form.shippingStatus,
        "user.name": form.user,
        "user.lastNames": form.userLastNames,
        "createdBy.name": form.createdByName,
        id: form.odc,
    }),[form]);
    const requestDistribuitorFilters = useMemo(()=>({
        sGroups: ['user_read', 'order_read_user', 'order_read', 'created_date', 'user_read', 'user_read_profile', 'profile_read', 'order_read_balance_used'],
        "order[createdDate]": "DESC",
        status: form.paymentStatus,
        shippingStatus: form.shippingStatus,
        id: form.odc,
        user: me.id
    }),[me, form]);
    const requestSellFilters = useMemo(()=>({
        sGroups: ['order_read_user', 'order_read', 'created_date', 'user_read', 'user_read_profile', 'profile_read', 'order_read_payment', 'payment_read_payment_method', 'payment_method_read', 'order_read_guest', 'guest_read','created_by'],
        "order[createdDate]": "DESC",
        status: form.paymentStatus,
        shippingStatus: form.shippingStatus,
        "user.name": form.user,
        "user.lastNames": form.userLastNames,
        "createdBy.name": form.createdByName,
        id: form.odc
    }),[form]);


    const table = useTideTable({
        entity: 'orders',
        columns: me.role && (me.role?.name === USER_ROLE.ROLE_GENERAL_ADMIN || me.role?.name === USER_ROLE.SUPER_ADMIN ) ? AdminColumns : (me.role && me.role?.name === USER_ROLE.ROLE_GENERAL_SELL ? SellColumns: DistribuitorColumns),
        requestFilters: me.role && me.role?.name !== USER_ROLE.ROLE_GENERAL_SELL ? requestAdminFilters : ( me.role && me.role?.name === USER_ROLE.ROLE_GENERAL_SELL ? requestSellFilters : requestDistribuitorFilters),
        requestOptions
    });

    tableRef.current = table;

    let mockInputSearch = [
        {
            label: 'Estado de entrega',
            name: 'shipping-status',
            onChange: handleInputChange('shippingStatus'),
            value: form.shippingStatus||'',
            type: 'select',
            placeholder: 'Estado del envío',
            options: orderShippingStatus,
            optionsText: orderShippingStatusTrans,
            selectPlaceholder: 'Selecciona una opción'
        },
        {
            label: 'Estado de pago',
            name: 'payment-status',
            onChange: handleInputChange('paymentStatus'),
            value: form.paymentStatus||'',
            type: 'select',
            placeholder: 'Estado del pago',
            options: orderStatus,
            optionsText: orderStatusTrans,
            selectPlaceholder: 'Selecciona una opción'
        },
        {
            label: 'ID ODC',
            placeholder: 'Número de orden',
            name: 'odc-id',
            onChange: handleInputChange('odc'),
            value: form.odc||'',
            type: 'text',
        }
    ];


    const aliadaSearchFields = [
        {
            label: 'Orden generada por:',
            name: 'created-by-name',
            placeholder: 'Usuario generador de orden',
            onChange: handleInputChange('createdByName'),
            value: form.createdByName||'',
            type: 'text',
        },
        {
            label: 'Nombre',
            name: 'user',
            placeholder: 'Nombre',
            onChange: handleInputChange('user'),
            value: form.user||'',
            type: 'text',
        },
        {
            label: 'Apellido',
            name: 'user-last-name',
            placeholder: 'Apellido',
            onChange: handleInputChange('userLastNames'),
            value: form.userLastNames||'',
            type: 'text',
        }
    ];

    if(me.role && me.role?.name !== USER_ROLE.ROLE_GENERAL_SELL) { mockInputSearch.push(...aliadaSearchFields) }

    return (
        <div className="Orders">
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-weight-bolder text-dark">Órdenes de compra</span>
			    	</h3>
			    </div>
                <div className="d-flex align-items-center justify-content-end pr-6">
                    <TideTableFilters inputsObject={mockInputSearch} me={me} />
                </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
                            <TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Orders.displayName="Orders";

export default Orders;
