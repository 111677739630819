import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import "./ShoppingDetails.scss";
import { faShoppingCart, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ApiContext } from '../../../../services/api/api-config';
import useBoolean from '../../../../hooks/useBoolean';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getProductPhotoUrl, productStockStatus } from '../../../../services/modelUtils/productUtils';
import { cartCustomProp, cartSGroups } from '../../../../services/modelUtils/cartUtils';
import { getNotifier } from '../../../../services/notifier';
import {moneyFormatter} from "../../../../services/currencyUtils";
import {
    createOrderFromCart,
    fillOrderProductPrices,
    getCartProductsTotal,
    shippingCalculator
} from "../../../../services/modelUtils/orderUtils";
import useFormState from '../../../../hooks/useFormState';
import FieldEditor from "../../../../components/FieldEditor/FieldEditor";
import useShippingZones from "../../../../hooks/useShippingZones";
import { paths } from '../../../../services/routes/appRoutes';
import { useHistory } from 'react-router';
import Modal from '../../../../components/Modal/Modal';
const noop = ()=>{};
const emptyArr = [];

const ShoppingDetails = ({ smallButton }) => {
    const history = useHistory();
    const api = useContext(ApiContext);
    const zones = useShippingZones();
    const [showShoppingCart, shoppingCartOn, shoppingCartOff] = useBoolean();
    const {form, handleInputChange} = useFormState({});

    const me = useSelector(({api})=>api.me);

    // --------- Get cart from server --------
    const loadCart = useCallback(() => {
        api.carts.get({params: {user: me.id, sGroups: cartSGroups}, customProp: cartCustomProp});
    }, [me, api]);

    useEffect(loadCart, [loadCart]);

    const myCart = useSelector(({api}) => api[cartCustomProp]?api[cartCustomProp][0]:null);
    const cartProducts = myCart?.cartProducts||emptyArr;

    // ------ Compute cart prices -------
    const productsTotal = getCartProductsTotal(cartProducts);
    const pointsDiscount = Number(myCart?.useBalance)||0;

    const shipping = shippingCalculator( productsTotal, me.profile?.neighbourhood?.shippingZone, zones, myCart?.useBalance);
    const total = productsTotal + shipping - pointsDiscount;

    // -------  Remove item from cart ---------
    const removeItem= useCallback((e) => {
        const { id } = e.currentTarget.dataset;
        api.cartProducts.delete({ id })
            .then(()=>{
                getNotifier().success("Producto eliminado del carrito.");
                loadCart();
            })
            .catch(()=>{
                getNotifier().error("Error al eliminar producto.");
                loadCart();
            })
    }, [api, loadCart]);

    // -------  Edit product quantity ---------
    const [focusedCartProduct, setFocusedCartProduct] = useState(null);
    const [newProductQuantity, setNewProductQuantity] = useState(0);

    const handleCartProductFocus = useCallback(( e )=>{
        const { id } = e.currentTarget.dataset;
        const cartProduct = _.find( cartProducts, cartProduct=>cartProduct.id+""===id+"");
        if(!cartProduct) return;

        setFocusedCartProduct( cartProduct.id );
        setNewProductQuantity( cartProduct.quantity );

    },[cartProducts]);

    const handleQuantityChange = useCallback((e)=>{
        setNewProductQuantity( e.target.value );
    },[]);

    // ------- Handle mentita points ----------

    const availablePoints = Number(me?.profile?.balance||0);
    const loadingPointsId = 'ShoppingDetails.usePoints'
    //Start using points
    const usePoints = useCallback(()=>{
        if(!availablePoints || !productsTotal)
            return;
        const toUse = availablePoints > productsTotal? productsTotal : availablePoints;
        api.carts.update( { id: myCart.id, params:{ useBalance: toUse+'',sGroups: cartSGroups}, customProp: cartCustomProp, loadingId:loadingPointsId } )
    },[api, availablePoints, productsTotal, myCart]);
    const loadingPoints = useSelector(s=>!!s.loadingIds[loadingPointsId]);
    //Edit points amount
    const [editingPoints, startEditingPoints, stopEditingPoints] = useBoolean();
    const editPoints = useCallback((newAmount)=>{
        if(!availablePoints) {
            stopEditingPoints();
            return getNotifier().error("No tienes puntos disponibles");
        }
        const toUse = Number(newAmount);
        if(isNaN(toUse) || toUse < 0) {
            return getNotifier().error("Ingresa un número válido");
        }
        if(availablePoints < toUse){
            return getNotifier().error("No tienes suficientes puntos");
        }
        if(productsTotal < toUse){
            return getNotifier().error(`El máximo de puntos en esta orden es de ${productsTotal}`);
        }
        api.carts.update( { id: myCart.id, params:{ useBalance: toUse+'',sGroups: cartSGroups}, customProp: cartCustomProp, loadingId:loadingPointsId } )
            .then(()=>{
                stopEditingPoints();
            })
    },[api, stopEditingPoints, availablePoints, myCart, productsTotal]);

    // ------- Edit Order quantity ----------
    const handleCartProductBlur = useCallback(()=>{
        setFocusedCartProduct(null);

        if(!Number(newProductQuantity))
            return getNotifier().error("Cantidad inválida.");

        api.cartProducts.update({ id: focusedCartProduct, params:{ quantity: Number(newProductQuantity) } })
            .then(()=>{
                getNotifier().success("Producto editado.");
                loadCart();
            })
            .catch(()=>{
                getNotifier().error("Error al editar producto.");
                loadCart();
            })
    },[api, focusedCartProduct, newProductQuantity, loadCart]);

    const goToPayment = useCallback((id) => history.push(paths.orderDetail.replace(':id', id)), [history]);

// ------- Create Order ----------
    const handleCreateOrder = useCallback(() => {
        if(!cartProducts?.length)
            return;
        if (!me.profile?.zipCode) {
            return getNotifier().error('Ve a tu perfil y completa tu dirección de entrega para poder concretar tu orden de compra.')
        }

        const order = createOrderFromCart( myCart );
        order.user = me.id;
        order.shipping = shipping;
        order.total += order.shipping;
        order.comment = form.notes||'';

        api.orders.create({params: order}).then((order) => {
            getNotifier().info('¡Se ha realizado tu orden de compra correctamente!');
            shoppingCartOff();
            api.carts.delete({id: myCart.id}).then(()=>{
                //Reload cart
                api.carts.get({params: {user: me.id, sGroups: cartSGroups}, customProp: cartCustomProp});
                //Reload me to change the user's points
                api.me.get();
                //Redirect user to the order details
                goToPayment(order.id);
            });
        });

        api.me.get(); // update profile to show balance in sidebar
    }, [api, me, shoppingCartOff, myCart, cartProducts, form, shipping, goToPayment]);

    const totalItems = myCart?.cartProducts.reduce( (total, cProduct)=>total+(cProduct.quantity||0), 0 );

    const pricedProducts = useMemo( ()=>fillOrderProductPrices(cartProducts), [cartProducts] );

    const determineStatus = useCallback((product) => {
        if (product) {
            if (product.isActive === false) { return { status: "NO DISPONIBLE", color: "text-danger" } }
            return productStockStatus(product.stock);
        }
        return { status: "CARGANDO...", color: "" };
    }, []);

    return (<>
            <div className={'ShoppingDetails-button cursor-pointer' + (smallButton ? ' hidden-cont':' open-cont')} onClick={() => shoppingCartOn()}>
                {!smallButton?
                    <button className='btn cart-button'> Ver mi carrito </button> :
                    <FontAwesomeIcon icon={faShoppingCart} className='hidden-icon text-danger'/>}
                {totalItems && <span className='count-badge'>{totalItems}</span>}
            </div>
            { showShoppingCart &&
            <Modal
                className={'CartModal'}
                title={'Mi carrito de compras'}
                onClose={shoppingCartOff}
            >
                <div className="heading cf d-flex justify-content-between align-items-center">
                    <h1 className="font-weight-bolder mentita-color">Mi orden</h1>
                </div>

                <div className="cart">
                    <ul className="cartWrap">
                        {pricedProducts.map((cartProduct, idx) => (
                            <li className="item d-flex justify-content-between" key={cartProduct?.id||idx}>
                                <div className="cartSection d-flex">
                                    <div className="overlay-wrapper rounded text-center image-container">
                                        <div className="image" style={{backgroundImage: `url(${getProductPhotoUrl(cartProduct.product, api)})` }}>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column ml-3">
                                        <p className="itemNumber"># {idx+1}</p>
                                        <h3 className='mb-0'># {cartProduct?.product.name}</h3>
                                        <div className="d-flex align-items-center">
                                            <p>{focusedCartProduct!==cartProduct.id?
                                                <input type="number" className="qty"
                                                        key={cartProduct.id}
                                                        data-id={cartProduct.id}
                                                        value={cartProduct?.quantity||0}
                                                        onFocus={handleCartProductFocus}
                                                        onChange={noop}
                                                />:
                                                <input type="number" className="qty"
                                                        key={cartProduct.id}
                                                        data-id={cartProduct.id}
                                                        value={newProductQuantity}
                                                        onChange={handleQuantityChange}
                                                        onBlur={handleCartProductBlur}
                                                />}
                                            </p>
                                            <p className={`stockStatus ${determineStatus(cartProduct?.product)?.color}`}>
                                                {determineStatus(cartProduct?.product)?.status} ( {cartProduct?.product.stock||0} disponibles. )
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="prodTotal text-center">
                                        <p className='m-0 font-weight-bolder mentita-color'>{ cartProduct.price ?
                                            '$\xa0' + moneyFormatter( cartProduct.price ):
                                            "Sin precio"}
                                        </p>
                                    </div>
                                    <div className="removeWrap ml-4 d-flex align-items-center"
                                            data-id={cartProduct.id}
                                            onClick={removeItem}>
                                        <span className="remove">x</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='container-fluid'>
                    <div className="mb-2 row mt-4">
                        <div className="col-12 col-md-6">
                            <label className="font-weight-bolder">Notas</label>
                            <textarea className="form-control" value={form.notes || ''} onChange={handleInputChange('notes')} placeholder='...' />
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="font-weight-bolder text-right">Mis puntos mentita</p>
                            <p className='points-balance'>{ me?.profile?.balance||0 }</p>
                        </div>
                    </div>
                </div>
                <div className="subtotal mt-4">
                    <ul className='list-group'>

                        {!!availablePoints && <li className="list-group-item d-flex justify-content-between final">

                            {!pointsDiscount &&
                            <button className='btn btn-sm btn-danger' disabled={loadingPoints} onClick={usePoints}>Usar puntos</button>}

                            {!!pointsDiscount &&
                            <>
                                <b className='mentita-blue'>Usar puntos</b>
                                {!editingPoints ?
                                    <span className="value">
                                    {myCart.useBalance}{' '}
                                        <button className='btn-clear' onClick={startEditingPoints}><FontAwesomeIcon
                                            icon={faPencilAlt}/></button>
                                </span> :
                                    <FieldEditor
                                        onCancel={stopEditingPoints}
                                        placeholder={"Puntos a usar..."}
                                        onConfirm={editPoints}
                                        disabled={loadingPoints}
                                    />
                                }
                            </>}
                        </li>}
                        <li className="list-group-item d-flex justify-content-between final"><b className='mentita-blue'>Subtotal</b><span className="value">$ { moneyFormatter( productsTotal, 'MXN' )}</span></li>
                        <li className="list-group-item d-flex justify-content-between final"><b className='mentita-blue'>Costo de envío</b><span className="value">$ { moneyFormatter( shipping, 'MXN' )}</span></li>
                        <li className="list-group-item d-flex justify-content-between final"><h5 className='mentita-blue'>Total</h5><span className="value">$ { moneyFormatter( total, 'MXN' ) }</span></li>
                        <li className="list-group-item d-flex justify-content-between"><span className="btn btn-danger continue text-mentita" onClick={handleCreateOrder}>Realizar orden</span></li>
                    </ul>
                </div>
            </Modal>}
        </>
    );
}

ShoppingDetails.displayName="ShoppingDetails";

export default ShoppingDetails;
