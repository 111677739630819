import React, {useContext, useMemo} from 'react';
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import {getProductsTableColumns} from "../../productIndexUtils";
import {ProductSGroups} from "../../../../../services/modelUtils/productUtils";
import useTideTable from "../../../../../hooks/useTideTable";
import {ApiContext} from "../../../../../services/api/api-config";

const ProductsTableView = ({ filters, onProductEdit, onShowPrices, deleteProduct, toggleProductActive, onAddProductToCart, customProp }) => {

    const api = useContext(ApiContext);

    const productsStockColumns = useMemo(()=>getProductsTableColumns({ api, deleteProduct, onShowPrices, toggleProductActive, onProductEdit }),
        [api, deleteProduct, onShowPrices, toggleProductActive, onProductEdit]);

    const productsStockFilters = useMemo(()=>({
        sGroups: ProductSGroups,
        pagination: true,
        ...filters
    }),[filters]);

    const requestOptions = useMemo(()=>({customProp}), [customProp]);
    const productsTable = useTideTable({
        entity: 'products',
        columns: productsStockColumns,
        requestFilters: productsStockFilters,
        requestOptions
    });

    return (
        <div className="ProductsTableView card card-custom card-shadowless product-container">
            <div className="card-body p-5">
                <TideReactTable {...productsTable.tableProps}/>
            </div>
        </div>
    );
};

export default ProductsTableView;
