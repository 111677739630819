import React from 'react';
import './ShareModal.scss';
import Modal from "../../../../components/Modal/Modal";
import gatitos from './assets/gatitos.png';
import ReferralActions from "./components/ReferralActions";

const ShareModal = ({ onClose }) => {


    return (
        <Modal
            title={"Programa de aliadas"}
            className={"ShareModal"}
            onClose={onClose}>
            <div className='share-content'>
                <div className='description'>
                    <p >¡Bienvenida al programa de aliadas! Podrás ganar 10% de las compras de tus referidas en puntos</p>
                    <ReferralActions />
                </div>
                <div className='image'>
                    <img src={gatitos} alt='Aliadas' className='aliadas-img'/>
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
