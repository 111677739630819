import React, { useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../hooks/useTideTable';
import './ProductCategoriesAdmin.scss';
import { categoriesSGroups } from '../../../../../services/modelUtils/categoriesUtils';
import CategoryFormModal from '../CategoryFormModal/CategoryFormModal';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';

const ProductCategoriesAdmin = () => {
    const api = useContext(ApiContext);
    const [showCategoryModal, setShowCategoryModal] = useState();
    const [showConfirmDelete, setShowConfirmDelete] = useState();
    const [category, setCategory] = useState();

    const confirmDelete = useCallback((category) => {
        setCategory(category);
        setShowConfirmDelete(true);
    }, []);
    
    const categoryModal = useCallback((category) => {
        setCategory(category);
        setShowCategoryModal(true);
    }, []);

    const categoriesColumns = useCallback(() => [
        {Header: "ID", accessor: category => category.id||'Sin información'},
        {Header: "Nombre", accessor: 'name'},
        {Header: "Creación", accessor: category =>  moment(category.createdDate).format('DD/MM/YYYY')},
        {
            Header: "Acciones",
            accessor: category => (
                <div className='d-flex justify-content-center align-items-center gap-2'>
                    <div className="actions">
                        <button className="btn btn-primary" onClick={() => categoryModal(category)}>Editar</button>
                    </div>
                    <div className="actions">
                        <button className="btn btn-danger" onClick={() => confirmDelete(category)}>Eliminar</button>
                    </div>
                </div>
            )            
        }
    ], [categoryModal, confirmDelete]);

    const columns = useMemo(() => categoriesColumns(), [categoriesColumns]);

    const requestFilters = useMemo(()=>({
        "order[name]": "DESC",
        sGroups: categoriesSGroups,
    }),[]);

    const table = useTideTable({
        entity: 'categories',
        columns,
        requestFilters,
    });

    const deleteCategory = useCallback(() => {
        api.categories.delete({id: category.id})
            .then(() => {
                setShowConfirmDelete(false);
                setCategory(null);
                table.reload();
                getNotifier().success('Categoría eliminada correctamente');
            })
            .catch( e => getNotifier().error(e.message) );
    }, [api, category, table]);
    
    return (
        <div className='ProductCategoriesAdmin'>
            <div className='text-right p-5'>
                <span className="btn btn-secondary"
                    onClick={() => setShowCategoryModal(true)}
                >
                    Añadir
                </span>
            </div>

            <TideReactTable {...table.tableProps}/>

            {showCategoryModal && <CategoryFormModal 
                onClose={() => setShowCategoryModal(false)}
                reloadTable={() => table.reload()}
                category={category}
            />}

            {showConfirmDelete && <ConfirmDeleteModal
                onClose={() => setShowConfirmDelete(false)}
                reloadTable={() => table.reload()}
                onConfirm={() => {}}
                mainButtonAction={() => deleteCategory()}
            />}
        </div>
    );
}

export default ProductCategoriesAdmin;