import React, {useCallback, useContext, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ApiContext } from '../../../../../services/api/api-config';
import { orderTableSGroups } from '../../../../../services/modelUtils/orderUtils';
import "./OrderEdit.scss";
import OrderForm from "../../../../../components/OrderForm/OrderForm";
import {paths} from "../../../../../services/routes/appRoutes";

const OrderEdit = () => {
    const id = useParams().id;
    const api = useContext(ApiContext);
    const [orderObject, setOrderObject] = useState();
    const me = useSelector(({api})=>api.me);

    useEffect(()=>{
        api.orders.get({id: id, params: {sGroups: orderTableSGroups(me)}, customProp: 'orderToEdit'}).then((response) => {
            setOrderObject(response);
        });
    },[id, setOrderObject, me, api]);

    const history = useHistory();
    const onClose = useCallback(()=>{
        history.push( paths.orders );
    },[history]);

    return (
        <div className="OrderEdit">
            <OrderForm orderToEdit={orderObject} onClose={onClose}/>
        </div>
    );
}

export default OrderEdit;
