import React, {useCallback} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import TideEntitySelect from "../../../TideEntitySelect/TideEntitySelect";
import {productSelectorFilters} from "../../../../services/modelUtils/productUtils";
import {moneyFormatter} from "../../../../services/currencyUtils";
import useCallbackCreator from "use-callback-creator";
import {getEmptyOrderProduct} from "../../../../services/modelUtils/orderProductUtils";
import {fillOrderProductPrices, fillOrderProductPublicPrices} from "../../../../services/modelUtils/orderUtils";

const OrderProductsListForm = ({ orderProducts, order, onChange, canEditOrder }) => {

    const onOrderProductChange = useCallback(( index, orderProduct)=>{
        let newOrderProducts = [...orderProducts];
        newOrderProducts[index] = orderProduct;

        // if order doesn't have a user is anonymous
        if(!order?.user && order?.id){
            newOrderProducts = fillOrderProductPublicPrices( newOrderProducts );
        } else {
            newOrderProducts = fillOrderProductPrices( newOrderProducts );
        }

        onChange(newOrderProducts);
    },[onChange, orderProducts, order]);

    const onProductChange = useCallbackCreator( (index, product)=>{
        onOrderProductChange( index,  {...orderProducts[index], product });
    },[orderProducts, onOrderProductChange]);

    const onQuantityChange = useCallbackCreator( (index, event)=>{
        onOrderProductChange( index,  {...orderProducts[index], quantity: event.target.value });
    },[orderProducts, onOrderProductChange]);

    const onIsRewardChange = useCallbackCreator( (index, event)=>{
        onOrderProductChange( index,  {...orderProducts[index], isReward: !!event.target.checked });
    },[orderProducts, onOrderProductChange]);

    const handleAddProduct = useCallback(()=>{
        onChange([...orderProducts, getEmptyOrderProduct()])
    },[onChange, orderProducts]);

    const removeOrderProduct = useCallbackCreator((index)=>{
        const newOrderProducts = [...orderProducts];
        newOrderProducts.splice(index, 1);
        onChange(newOrderProducts);
    },[onChange, orderProducts]);

    return (
        <div className={"OrderProductsListForm"}>
            {(orderProducts?.map((orderProduct, idx) => (

                    <div key={idx} className="d-flex flex-wrap border-bottom border-danger py-3">
                        <div className="col-12 col-md-1 mb-3 mt-md-0 justify-content-center d-flex align-items-center">
                            {canEditOrder(order) &&
                                <FontAwesomeIcon icon={faTrash} className='text-danger cursor-pointer' onClick={removeOrderProduct(idx)} />}
                        </div>
                        <div className="col-12 col-md-5">
                            <label className="font-weight-bolder">Producto</label>
                            {canEditOrder(order) &&
                                <TideEntitySelect
                                    entity='products'
                                    value={orderProduct?.product}
                                    labelCreator={u=> `${u.name} - ${u.details} ( ${u.stock||'Sin stock'} )`}
                                    onChange={onProductChange(idx)}
                                    additionalFilters={productSelectorFilters}
                                    apiCustomProp={'OrderProductsListForm.products'}
                                />}
                            
                            {!canEditOrder(order) && 
                                <div> { orderProduct?.product?.name }</div>}
                        </div>
                        <div className="col-6 col-md-2 mt-3 mt-md-0">
                            <label className="font-weight-bolder">Cantidad</label>
                            
                            {canEditOrder(order) &&
                                <input type="number" disabled={!orderProduct?.product} value={orderProduct.quantity} onChange={onQuantityChange(idx)} min='0' className="form-control" />}
                            
                            {!canEditOrder(order) &&
                                <div>{orderProduct.quantity}</div>}
                        </div>
                        <div className="col-6 col-md-2 mt-3 mt-md-0">
                            <label className="font-weight-bolder">Precio</label>
                            <p className="form-control form-control-solid">$&nbsp;{moneyFormatter(orderProduct.price)}</p>
                        </div>
                        <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
                            <div className='d-flex align-items-center'>
                                {canEditOrder(order) && 
                                    <input className="form-check-input cursor-pointer mt-3" 
                                        type="checkbox" 
                                        checked={orderProduct.isReward} 
                                        onChange={onIsRewardChange(idx)} 
                                        id={`defaultCheck${idx}`} 
                                    />}
                                
                                {canEditOrder(order) && 
                                    <label className="form-check-label font-weight-bolder mt-3">
                                        ¿Es regalo?
                                    </label>}

                                {!canEditOrder(order) && <
                                    div>Es regalo: {orderProduct.isReward ? 'Sí' : 'No'}</div>}
                            </div>
                        </div>
                    </div>
                ))
            )}
            
            {canEditOrder(order) &&
                <div className="d-flex align-items-center justify-content-around my-2">
                    <div className="btn btn-success d-flex align-items-center mb-1 justify-content-around" onClick={handleAddProduct}>Añadir producto<FontAwesomeIcon icon={faPlus} className='ml-3' /></div>
                </div>}
        </div>
    );
};

export default OrderProductsListForm;
