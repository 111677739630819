import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import Modal from '../../../../../components/Modal/Modal';
import useFormState from '../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../services/api/api-config';
import { prepareCategoryFormForServer } from '../../../../../services/modelUtils/categoriesUtils';
import { getNotifier } from '../../../../../services/notifier';

const CategoryFormModal = ({ category, onClose, reloadTable }) => {
    const {form, handleInputChange, setForm} = useFormState({name: '', isActive: true});
    const api = useContext(ApiContext);
    const isEdit = !!form.id;

    const handleSaveCategory = () => {
        const id = form.id;
        const method = form.id ? 'update' : 'create';
        let categoryData = null;

        try {   
            categoryData = prepareCategoryFormForServer(form);
        } catch (error) {
            return getNotifier().error(error.message);
        }

        api.categories[method]({id, params: categoryData})
            .then( () => {
                getNotifier().success('Categoría creada correctamente');
                reloadTable();
                onClose();
            } );
    }
    
    useEffect(() => {
        if (category) {
            setForm(category);
        }
    }, [category, setForm]);
    
    return (
        <Modal
            title={isEdit ? 'Editar categoría' : 'Agregar categoría'}
            onClose={onClose}
            className="CategoryFormModal"
        >
            <form className="form">
                <div className="card-body">

                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Nombre de la categoría</span>
                            </div>
                            <input type="text" value={form.name || ''} onChange={handleInputChange('name')}
                                   className="form-control" placeholder={'Ingresa el nombre de la categoría'}/>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-around mt-4">
                        <div 
                            className="btn btn-success d-flex align-items-center mb-1 justify-content-around" 
                            onClick={handleSaveCategory}
                        >
                            {isEdit ? 'Guardar cambios' : 'Crear categoría'}
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default CategoryFormModal;