import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useContext, useCallback } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useFormState from '../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../services/api/api-config';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import { useSelector } from 'react-redux';
import './ShippingZoneAdmin.scss';
import _ from 'lodash';
import {getNotifier} from "../../../../../services/notifier";
import {STATE_ACTION_SET} from "tide-api";


const ShippingZoneAdmin = () => {
  const api = useContext(ApiContext);
  const [ edit,,stopEditing,editToggle ] = useBoolean(false);
  const { form, handleInputChange, setForm } = useFormState({});

  useEffect(() => {
    api.settings.get({id: "shipping_zones", customProp: 'shippingZones'})
        .then( setting=>setting?.value && setForm(JSON.parse(setting.value)))
  }, [api, setForm]);

  const zonesString = useSelector(({api})=>api.shippingZones?.value );
  const shippingZones = zonesString? _.map( JSON.parse(zonesString), (price, zone)=>({ zone, price })) : [];

  const saveShippingZones = useCallback(() => {
    const value = {};
    const zones = _.keys(form);
    //Validate input and convert to number
    for( let i=0; i<zones.length; i++){
      const zone = zones[i];
      value[zone] = Number(form[zone]);
      if( isNaN( value[zone] ))
        return getNotifier().error(`La zona ${zone} tiene un valor inválido`);
    }
    api.settings.update({id: "shipping_zones", params: { value:JSON.stringify(value) }, customProp: 'shippingZones', stateAction: STATE_ACTION_SET})
        .then(stopEditing);
  }, [form, api, stopEditing])

  return (
    <div className="ShippingZoneAdmin mx-auto col-lg-12">
      <div className="card card-custom gutter-b">
        <div className="card-header border-0 pt-5 justify-content-end">
          <div>
            {edit &&
              <button className="btn btn-success btn-sm mb-4 mr-5" onClick={saveShippingZones}>
                <span className="svg-icon ml-1 font-weight-bolder svg-icon-md svg-icon-success">
                  Guardar
                </span>
              </button>
            }
            <button className="btn btn-icon btn-light btn-sm mb-4" onClick={() => editToggle()}>
              <span className="svg-icon svg-icon-md svg-icon-success">
                <FontAwesomeIcon icon={faEdit} className="h-75"/>
              </span>
            </button>
          </div>
        </div>
        <div className="card-body pt-0 pb-0 my-3">
          <div className="row d-flex justify-content-between px-5 text-center">
            {shippingZones.map(({zone, price}) =>
                <div className='mt-2' key={zone}>
                  <p className="font-weight-bolder mb-0 magenta-color">{zone}</p>
                  {edit?
                    <input type="number" className="form-control" onChange={handleInputChange(zone)} value={form[zone]||''}/> :
                    <p className="text-info">$&nbsp;{moneyFormatter(price)}</p>}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShippingZoneAdmin;
