import React, {useCallback, useContext, useEffect, useState} from 'react';
import "./ProductsIndex.scss";
import ProductForm from "./components/ProductForm/ProductForm";
import PricesModal from "./components/PricesModal/PricesModal";
import { ApiContext } from '../../../services/api/api-config';
import { useSelector } from 'react-redux';
import { getNotifier } from '../../../services/notifier';
import useBoolean from '../../../hooks/useBoolean';
import {getProductPhotoUrl, ProductSGroups} from '../../../services/modelUtils/productUtils';
import { cartCustomProp, cartSGroups } from '../../../services/modelUtils/cartUtils';
import {ReactComponent as Icon} from "../../../assets/img/logo.svg";
import _  from 'lodash';
import ProductsAdminHeader from "./components/ProductsAdminHeader/ProductsAdminHeader";
import ProductsMosaicView from "./components/ProductsMosaicView/ProductsMosaicView";
import ProductsTableView from "./components/ProductsTableView/ProductsTableView";
import { categoriesSGroups } from '../../../services/modelUtils/categoriesUtils';

const defaultFilters = {'order[name]':'ASC'};

const ProductsIndex = () => {
	const api = useContext(ApiContext);

	const [edit, setEdit] = useState();
	const [viewStyle, setViewStyle] = useState('mosaic');
	const [filters, setFilters] = useState({...defaultFilters});
	const [pricesToShow, setPricesToShow] = useState();
	const closePrices = useCallback(()=>setPricesToShow(null),[]);
	const [category, setCategory] = useState();
	const [categories, setCategories] = useState([]);
	const customProp = 'ProductsIndex.productTable';

	const [addingProduct, startAddingProduct, stopAddingProduct] = useBoolean();

	const me = useSelector(({api})=>api.me);

	/* Delete product :: BEGIN */
    const deleteProduct = useCallback((product) => {
            api.products.delete({id:product.id, customProp})
			.catch((err) => {
				getNotifier().error(err.detail);
			});
    }, [api]);
	/* Delete product :: END */

	/* AddProduct to Cart :: BEGIN */
	const handleProductPostToCart = useCallback((product) => {
		api.cartProducts.create({
			params: {product: product.id,
			quantity: 1
		}
		}).then(() => api.carts.get({params: {user: me.id, sGroups: cartSGroups}, customProp: cartCustomProp}));
	}, [api, me]);
	/* AddProduct to Cart :: END */

	/* Set product Off :: BEGIN */
	const toggleProductActive = useCallback((product) => {
		if (_.isEmpty(product.productPrices)) { getNotifier().error('El producto no tiene precios de aliada') }

		api.products.update({id:product.id, customProp, params: {isActive: !product.isActive, sGroups: ProductSGroups}});
	}, [api]);
	/* Set product Off :: END */

	const handleCloseForm = useCallback(()=>{
		setEdit(null);
		stopAddingProduct();
	},[stopAddingProduct]);

	//The different views may load different page sizes, so we better clean the data and let them load from scratch
	useEffect(()=>{
		api.clearProperty(customProp);
	},[api, viewStyle]);

	useEffect(() => {
		api.categories.get({params: {sGroups: categoriesSGroups}})
			.then(setCategories)
			.catch((err) => getNotifier().error(err.detail));
	}, [api]);	

	useEffect(() => {
        if(category)
            setFilters(prev => ({...prev, 'category': category}));
        else    
            setFilters({...defaultFilters});
    }, [category]);

    return (
			<>
				{pricesToShow && <PricesModal pricesOff={closePrices} distribuitorPrices={pricesToShow}/>}

        <div className={"ProductsIndex"}>
            <div className="d-flex flex-row">

				{!!(edit || addingProduct) &&
				<div className="flex-column offcanvas-mobile mx-auto" id="kt_profile_aside">
					<ProductForm edit={edit} closeModal={handleCloseForm} returnPhotoUrl={getProductPhotoUrl} customProp={customProp}/>
				</div>}
				<div className="flex-row-fluid ml-lg-8">
					<div className="card products-index card-custom card-stretch gutter-b">
						<div className="card-body">
							<div className="card card-custom mb-12" style={{backgroundColor:"transparent"}}>
								<div className="card-body content-header text-center rounded p-0 d-flex flex-column" style={{backgroundColor:"transparent"}}>
									<h1 className="font-weight-bolder mx-auto color-purple mb-0">Invita a alguien a unirse como Aliada<br/><span>y por cada orden de compra que haga, <span className='font-weight-bolder'>¡tú ganas el 10%!</span> </span><br/><Icon className='logo-icon'/></h1>
								</div>
							</div>
							<div className="mb-11">
								{me.role?
								<div className="d-flex justify-content-end align-items-center mb-7 flex-column flex-md-row">
									<ProductsAdminHeader
										filters={filters} onFiltersChange={setFilters}
										viewStyle={viewStyle} setViewStyle={setViewStyle}
										onNewProductClick={startAddingProduct}
									/>
								</div>:
								<>
									<div className="d-flex justify-content-end align-items-center mb-7 flex-column flex-md-row">
										<div>
											<h2 className="font-weight-bolder color-purple font-size-h3 mb-0">Lista de productos</h2>
											<div className="d-flex mt-2">
												<select className="form-control mr-3"
													
													onChange={(e)=>setCategory(e.target.value)}
												>
													<option value="Selecciona una categoría">Sin categoría</option>
													{categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
												</select>
												<input className="form-control mr-sm-2" type="search" placeholder="Buscar por nombre" aria-label="Search" value={filters.name} onChange={e=>setFilters({...filters, name:e.target.value})} />
											</div>
										</div>
									</div>
									<div className='text-center font-weight-bolder font-size-h1 color-purple'>
										Inicia tu orden de compra seleccionando los productos al carrito
									</div>
								</>}

								{viewStyle === 'table' ? (
									<ProductsTableView
										filters={filters}
										onProductEdit={setEdit}
										onShowPrices={setPricesToShow}
										deleteProduct={deleteProduct}
										toggleProductActive={toggleProductActive}
										onAddProductToCart={handleProductPostToCart}
										customProp={customProp}
									/>
								)
								:
									<ProductsMosaicView
										filters={filters}
										onProductEdit={setEdit}
										onShowPrices={setPricesToShow}
										deleteProduct={deleteProduct}
										toggleProductActive={toggleProductActive}
										onAddProductToCart={handleProductPostToCart}
										customProp={customProp}
									/>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
      </div>
			</>
    );
}

ProductsIndex.displayName="ProductsIndex";

export default ProductsIndex;
