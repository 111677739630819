import React, { useCallback, useContext, useEffect, useState } from 'react';
import "./ProductForm.scss";
import {faMinus, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useFormState from '../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../services/api/api-config';
import { ProductSGroups } from '../../../../../services/modelUtils/productUtils';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import _ from 'lodash';
import { getNotifier } from '../../../../../services/notifier';
import {useSelector} from "react-redux";
import { categoriesSGroups } from '../../../../../services/modelUtils/categoriesUtils';

const ProductForm = ({edit, returnPhotoUrl, closeModal, customProp}) => {
    const [distributorPrices, setDistributorPrices] = useState([]);
	const api = useContext(ApiContext);
	const [file, setFile] = useState(null);
	const hiddenFileInput = React.useRef(null);
	const [imgSrc, setImgSrc] = useState(returnPhotoUrl(edit, api));
	const {form, handleInputChange, setForm} = useFormState({});
	const [categories, setCategories] = useState([]);

	/* Distributor Prices methods section :: BEGIN */
    const handleNewDistributorPrice = useCallback(() => {
        setDistributorPrices([...distributorPrices, {
            minQuantity: 0,
            maxQuantity: 0,
            price: 0
        }]);
    }, [setDistributorPrices, distributorPrices]);

	const handleDeleteDistributorLastPrice = useCallback((index) => {
		const newArray = [...distributorPrices];
		newArray.splice(index, 1);
		setDistributorPrices(newArray);
	}, [distributorPrices]);

    const handlePriceChange = useCallback((e, key, index) => {
		const newArray = [...distributorPrices];
		newArray[index][key] = Number(e.target.value);
		setDistributorPrices(newArray);
    }, [setDistributorPrices, distributorPrices]);
	/* Distributor Prices methods section :: END */

	/* Handle input file change :: BEGIN */
	const handleFileSelection = useCallback(e => {
        if (!e.target?.files?.length)
            return;
        const file = e.target.files[0];
		setImgSrc(URL.createObjectURL(file))

        setFile(file);
    }, [setFile]);

	const handleMockClickToFile = useCallback(e => {
		hiddenFileInput.current.click();
	}, []);
	/* Handle input file change :: END */

	const productPricesAreValid = useCallback((productPrices) => {
		if (_.isEmpty(productPrices)) { return true }

		let result = true;

		productPrices.forEach((productPrice) => {
			if (productPrice.maxQuantity < productPrice.minQuantity) { return result = false }
			if (productPrice.price === 0) { return result = false }
			if (productPrice.maxQuantity === 0 || productPrice.maxQuantity < 0) { return result = false }
			if (productPrice.minQuantity < 0 ) { return result = false }
		})

		return result;
	}, []);

	const handleSaveProduct = useCallback(() => {

		const apiMethod = edit ? 'update' : 'create';
		api.products[apiMethod]({
			...(edit && {id: edit.id+''}),
			customProp,
			loadingId:'ProductForm',
			params: {
				...form,
				weight: Number(form.weight),
				wholesaleGroup: form.wholesaleGroup,
				providerPrice: Number(form.providerPrice)||0,
				publicPrice: Number(form.publicPrice)||0,
				stock: Number(form.stock),
				productPrices: [...distributorPrices],
				sGroups: ProductSGroups
			},
			files: file
		}).then(closeModal);

	}, [api, form, file, distributorPrices, closeModal, edit, customProp]);


	const handleSubmit = useCallback((e) => {
		e.preventDefault();

		const errorMessage = 'Los precios del producto tienen inconsistencias.';

		if (!productPricesAreValid(distributorPrices)) { return getNotifier().error(errorMessage) }

		handleSaveProduct();
	}, [handleSaveProduct, productPricesAreValid, distributorPrices]);

	useEffect(() => {
		if (edit) {
			setForm({
				name: edit.name,
				details: edit.details,
				stock: edit.stock,
				providerPrice: edit.providerPrice,
				weight: Number(edit.weight),
				wholesaleGroup: edit.wholesaleGroup,
				publicPrice: edit.publicPrice,
				category: edit.category?.id,
			});
			setDistributorPrices(edit.productPrices||[]);
		}
	}, [edit, setForm, setDistributorPrices]);

	useEffect(() => {
		// load categories
		api.categories.get({params: { sGroups: categoriesSGroups }})
			.then(setCategories);
	}, [api]);

	const isLoading = useSelector(s=>!!s.loadingIds['ProductForm'])

    return (
        <div className="ProductForm">
            <div className="card card-custom gutter-b">
			    <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column mb-3">
			    		<span className="card-label font-size-h3 font-weight-bolder text-dark">{edit ?'Editar producto':'Añadir nuevo producto'}</span>
			    		<span className="text-muted mt-5 font-weight-bolder font-size-lg">Imagen del producto</span>
			    	</h3>
					<div className="close-container d-flex justify-content-end p-5 cursor-pointer" onClick={closeModal}>
                        <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
                    </div>
			    </div>
			    <div className="card-body pt-4">
			    	<form>
			    		<div className="d-flex mb-8">
			    			<div className="symbol symbol-70 flex-shrink-0 mr-4">
			    				<div className="symbol-label image-holder">
									{imgSrc &&
										<img src={imgSrc} className='image-display' alt="file-display"/>
									}
								</div>
			    			</div>
			    			<div className="symbol symbol-70 flex-shrink-0" onClick={handleMockClickToFile}>
			    				<span className="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
			    				<span className="svg-icon svg-icon-lg m-0">
			    					{/*begin::Svg Icon | path:assets/media/svg/icons/Design/Image.svg*/}
									<input
                            			type='file'
										ref={hiddenFileInput}
										onChange={handleFileSelection}
										style={{display: 'none'}}
                        			/>
			    					{/*end::Svg Icon*/}
			    				</span>{file ? 'Cambiar' : 'Subir'} imágen</span>
			    			</div>
			    			{/*end::Symbol*/}
			    		</div>
			    		{/*end::Product images*/}
			    		{/*begin::Product info*/}
			    		<div className="mt-6">
			    			<div className="text-muted mb-4 font-weight-bolder font-size-lg">Información del producto</div>
			    			{/*begin::Input*/}
			    			<div className="form-group mb-8">
			    				<label className="font-weight-bolder">Nombre</label>
			    				<input type="text" className="form-control form-control-solid form-control-lg" value={form.name||''} onChange={handleInputChange('name')} placeholder="Ingresa el nombre del producto" />
			    			</div>
			    			<div className="form-group mb-8">
                                <label className="font-weight-bolder">Detalles</label>
			    				<input type="text" className="form-control form-control-solid form-control-lg" value={form.details||''} onChange={handleInputChange('details')} placeholder="Ingresa detalles del producto" />
			    			</div>

							<div className="form-group mb-8">
                                <label className="font-weight-bolder">Categoría</label>
			    				
								<select className="form-control"
									value={form.category || 'Selecciona una opción'} 
									onChange={handleInputChange('category')}
								>
									<option value="Selecciona una categoría">Sin categoría</option>
									{categories.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
								</select>
			    			</div>

			    			<div className="form-group d-flex">
								<div className="container-fluid p-0">
									<div className="row">
										<div className="col-12 col-lg-6 mb-8 mb-lg-0">
											<label className="font-weight-bolder">Stock</label>
											<input type="number" className="form-control form-control-solid form-control-lg" value={form.stock||''} onChange={handleInputChange('stock')} placeholder="Cantidad del producto" />
										</div>
										<div className="col-12 col-lg-6 mb-8 mb-lg-0">
											<label className="font-weight-bolder">Precio interno</label>
											<input type="number" className="form-control form-control-solid form-control-lg" value={form.providerPrice||''} onChange={handleInputChange('providerPrice')} placeholder="$" />
										</div>
									</div>
								</div>
							</div>
			    			<div className="form-group d-flex">
								<div className="container-fluid p-0">
									<div className="row">
										<div className="col-12 col-lg-6 mb-8 mb-lg-0">
											<label className="font-weight-bolder">Precio público</label>
											<input 
												type="number" 
												className="form-control form-control-solid form-control-lg" 
												value={form.publicPrice||''} 
												onChange={handleInputChange('publicPrice')} 
												placeholder="Precio público" 
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="form-group d-flex">
								<div className="container-fluid p-0">
									<div className="row">
										<div className="col-12 col-lg-6 mb-8 mb-lg-0">
											<label className="font-weight-bolder">Peso del producto</label>
											<input type="number" className="form-control form-control-solid form-control-lg" value={form.weight||''} onChange={handleInputChange('weight')} placeholder="Gramos" />
										</div>
										<div className="col-12 col-lg-6 mb-8 mb-lg-0">
											<label className="font-weight-bolder">Grupo de Mayoreo</label>
											<input type="text" className="form-control form-control-solid form-control-lg" value={form.wholesaleGroup||''} onChange={handleInputChange('wholesaleGroup')} placeholder="Grupo" />
										</div>
									</div>
								</div>
							</div>
                            <div className="separator separator-dashed my-1" />
			    		</div>
			    		{/*end::Product info*/}
						{distributorPrices?.map((price, i) => (
                        	<div key={i} className='d-flex align-items-center'>
                        	    <div className="font-weight-bolder text-dark-75 align-middle pb-5">
									<div className="d-flex flex-column">
										<p className='mb-0 mt-1'>Cantidad mínima:</p>
                        	        	<input onChange={(e) => handlePriceChange(e, 'minQuantity', i)} type="number" value={price.minQuantity} className="form-control form-control-solid form-control-lg" placeholder="Mínimo"/>
									</div>
                        	    </div>
                        	    <div className="font-weight-bolder text-dark-75 align-middle pb-5">
									<div className="d-flex flex-column mx-3">
										<p className='mb-0 mt-1'>Cantidad máxima:</p>
                        	        	<input onChange={(e) => handlePriceChange(e, 'maxQuantity', i)} type="number" value={price.maxQuantity} className="form-control form-control-solid form-control-lg" placeholder="Máximo"/>
									</div>
								</div>
                        	    <div className="font-weight-bolder text-dark-75 align-middle pb-5">
									<div className="d-flex flex-column">
										<p className='mb-0 mt-1'>Precio:</p>
                        	        	<input onChange={(e) => handlePriceChange(e, 'price', i)} type="number" value={price.price} className="form-control form-control-lg" placeholder="Precio"/>
									</div>
								</div>
								<div className="d-flex align-items-center cursor-pointer" onClick={() => handleDeleteDistributorLastPrice(i)}>
									<FontAwesomeIcon icon={faMinus} className='ml-3 text-danger'/>
								</div>
								<div className="col-3 d-flex flex-column">
                                    <label className="font-weight-bolder text-center">Utilidad</label>
                                    <p className="form-control form-control-solid text-center">${moneyFormatter( price.price - form.providerPrice )}</p>
                                </div>
                        	</div>
                        ))
                        }
						<div className="d-flex align-items-center justify-content-around my-2">
                            <div className="btn btn-success d-flex align-items-center mb-1 justify-content-around" onClick={handleNewDistributorPrice}>Añadir precio a la aliada<FontAwesomeIcon icon={faPlus} className='ml-3'/></div>
						</div>
						{/*end::Color*/}
						<button type="submit" disabled={isLoading} onClick={handleSubmit} className="btn btn-primary font-weight-bolder mr-2 px-8">Guardar</button>
			    		<button type="reset" className="btn btn-clear font-weight-bolder text-muted px-8" onClick={closeModal}>Cancelar</button>
			    		{/*end::Input*/}
			    	</form>
			    	{/*end::Form*/}
			    </div>
			    {/*end::Body*/}
			</div>
        </div>
    );
}

export default ProductForm;
