import React, { useCallback, useContext, useEffect, useState } from 'react';
import "./CatalogueList.scss";
import "../../../../../assets/styles/theme.scss";
import {faBookOpen} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CatalogueEdit from '../CatalogueEdit/CatalogueEdit';
import useBoolean from '../../../../../hooks/useBoolean';
import { ApiContext } from '../../../../../services/api/api-config';
import {useSelector} from "react-redux";
import { getNotifier } from "../../../../../services/notifier";
import { listOfCategoriesIdsThatCannotRemove } from '../../../../../services/modelUtils/paymentCategoryUtils';
import { listOfPaymentMethodsIdsThatCannotRemove } from '../../../../../services/modelUtils/paymentMethodUtils';
import _ from 'lodash';

const CatalogueList = ({catalogue}) => {

    const api = useContext(ApiContext);
    const [buttonActive, setButtonActive] = useState(0);
    const [modalDisplay, openModal, closeModal,] = useBoolean(false);
    const [catalogueEditable, setCatalogueEditable] = useState();
    const [isEdit, setIsEdit] = useState();
	const [catalogueTypeToRequest, setCatalogueTypeToRequest] = useState('income');
	const entityName = catalogue?.entityName;

	/*BEGIN Gets payment_type onclick to apply on api request and display */
	const chooseTypeOnClick = useCallback((index, item) => {
		setButtonActive(index);
		const requestType = item === 'Ingresos' ? 'income' : 'expense';
		setCatalogueTypeToRequest(requestType);
	}, []);
	/*END Gets payment_type onclick to apply on api request and display */

    useEffect(() => {
        if (entityName) {
			if (entityName === 'paymentMethods' && !catalogueTypeToRequest) return;
			if (entityName === 'paymentCategories' && !catalogueTypeToRequest) return;
			const params = entityName === 'providers' ? {pagination:false} : {pagination:false, paymentType: catalogueTypeToRequest};
            api[entityName].get({params, customProp: entityName === 'providers'?entityName:entityName+catalogueTypeToRequest});
		}
    }, [api, entityName, catalogueTypeToRequest, chooseTypeOnClick]);

	const catalogueCollection = useSelector( s=>s.api[entityName === 'providers'?entityName:entityName+catalogueTypeToRequest] );

    const catalogueModifyResponse = useCallback((checkEdit, catalogueToEdit=null) => {
        setIsEdit(checkEdit);
        openModal();
        setCatalogueEditable(catalogueToEdit);
    }, [setIsEdit, openModal, setCatalogueEditable]);

    const removeCatalogue = useCallback((catalogueId) => {
        if (entityName)
			api[entityName].update({id:catalogueId+'', params: {isActive: false}, customProp: entityName === 'providers'?entityName:entityName+catalogueTypeToRequest});
    }, [api, entityName, catalogueTypeToRequest]);

    const deleteCatalogue = useCallback((catalogueId) => {
            api[entityName].delete({id:catalogueId+'', customProp: entityName === 'providers'?entityName:entityName+catalogueTypeToRequest}).catch((err) => {
				getNotifier().error(err.detail);
			});
    }, [api, entityName, catalogueTypeToRequest]);

    const activeCatalogue = useCallback((catalogueId) => {
		api[entityName].update({id:catalogueId+'', params: {isActive: true}, customProp: entityName === 'providers'?entityName:entityName+catalogueTypeToRequest});
    }, [api, entityName, catalogueTypeToRequest]);

    return (
        <div className="CatalogueList">
            {modalDisplay && <CatalogueEdit closeModal={closeModal} entity={entityName} isEdit={isEdit} currentCatalogue={catalogueEditable}/>}
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-weight-bolder text-dark">{catalogue?.name}</span>
			    	</h3>
					{!catalogue &&
							<div className="div mx-auto border text-center rounded bg-secondary w-100 py-4">
								<h4 className=''>Selecciona un tipo de catálogo</h4>
							</div>
					}
                    <div className="card-toolbar">
						<ul className="nav nav-pills nav-pills-sm nav-dark-75">
                        {catalogue?.filters?.map((item, index) =>
							<li className="nav-item cursor-pointer" key={index} id={index}>
								<span key={item.id} className={"nav-link py-2 px-4 cursor-pointer" + (buttonActive === index && ' active')} data-toggle="tab" onClick={() => chooseTypeOnClick(index, item)}>{item}</span>
							</li>
                        )}
						{entityName &&
                            <li className="nav-item cursor-pointer">
								<span className={"nav-link text-light py-2 px-4 btn-info cursor-pointer"} data-toggle="tab" onClick={() => catalogueModifyResponse(false)}>Añadir</span>
							</li>
						}
						</ul>
					</div>
			    </div>
				{catalogue?.Component && <catalogue.Component />}
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
			            	<div className="table-responsive">
			            		    <table className="table table-borderless table-vertical-center">
			            		    	<thead>
			            		    		<tr>
			            		    			<th className="p-0 w-50px"></th>
			            		    			<th className="p-0 min-w-150px"></th>
			            		    			<th className="p-0 min-w-120px"></th>
			            		    			<th className="p-0 min-w-70px"></th>
			            		    			<th className="p-0 min-w-70px"></th>
			            		    			<th className="p-0 min-w-50px"></th>
			            		    		</tr>
			            		    	</thead>
			            		    	<tbody>
                                            {catalogueCollection?.map((itemCatalogue, index) => (
			            		    		    <tr id={index} key={index}>
			            		    		    	<td className="pl-0">
			            		    		    		<div className="symbol symbol-50 symbol-light mr-2 mt-2">
			            		    		    			<span className="symbol-label">
                                                                <FontAwesomeIcon icon={faBookOpen} className="h-75"/>
			            		    		    			</span>
			            		    		    		</div>
			            		    		    	</td>
			            		    		    	<td className="pl-0">
			            		    		    		<span className="text-muted font-weight-bolder text-hover-primary mb-1">Nombre</span>
			            		    		    		<span className="text-dark font-weight-bolder d-block font-size-lg">{itemCatalogue.name}</span>
			            		    		    	</td>
			            		    		    	<td></td>
													{entityName !== 'providers' &&
			            		    		    	<td className="text-right">
			            		    		    		<span className="text-muted font-weight-bold d-block font-size-sm">{itemCatalogue.type ? "Tipo":"Descripción"}</span>
			            		    		    		<span className="text-dark-75 font-weight-bolder d-block font-size-lg">{itemCatalogue.description || itemCatalogue.type || 'Sin información'}</span>
			            		    		    	</td>
													}
													{ itemCatalogue.isActive === true && !_.includes(listOfPaymentMethodsIdsThatCannotRemove, itemCatalogue.id) ?
			            		    		    	<td className="text-right">
			            		    		    		<span className="font-weight-bolder btn btn-danger cursor-pointer" onClick={() => removeCatalogue(itemCatalogue.id)}>Desactivar</span>
			            		    		    	</td> :
														!_.includes(listOfPaymentMethodsIdsThatCannotRemove, itemCatalogue.id) && 
															<td className="text-right">
																<span className="font-weight-bolder btn btn-success cursor-pointer" onClick={() => activeCatalogue(itemCatalogue.id)}>Activar</span>
															</td>
													}
													<td className="text-right">
			            		    		    		<span className="svg-icon svg-icon-md svg-icon-danger cursor-pointer text-danger">
															{ !_.includes(listOfCategoriesIdsThatCannotRemove, itemCatalogue.id) &&
																	!_.includes(listOfPaymentMethodsIdsThatCannotRemove, itemCatalogue.id) &&
																<FontAwesomeIcon icon={faTrashAlt} className="h-75" onClick={() => deleteCatalogue(itemCatalogue.id)}/>
															}
														</span>
			            		    		    	</td>
			            		    		    	{!_.includes(listOfPaymentMethodsIdsThatCannotRemove, itemCatalogue.id) &&
														<td className="text-right pr-0">
															<span className="btn btn-icon btn-light btn-sm">
																<span className="svg-icon svg-icon-md svg-icon-success">
																	<FontAwesomeIcon icon={faEdit} className="h-75" onClick={() => catalogueModifyResponse(true, itemCatalogue)}/>
																</span>
															</span>
														</td>}
			            		    		    </tr>
                                            ))}
			            		    	</tbody>
			            		    </table>
			            	</div>
			            	{/*end::Table*/}
			            </div>
			            {/*end::Tap pane*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CatalogueList;
