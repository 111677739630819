import React, { useState } from 'react';
import "./Accumulative.scss";
import SumWidget from './SumWidget/SumWidget';
import TableWidget from './TableWidget/TableWidget';

const Accumulative = () => {
    const [tableQuery, setTableQuery] = useState();

    return (
        <div className="Accumulative container-fluid mt-5">
            <div className="row">
                <SumWidget type='expense' setTableQuery={setTableQuery} />
                <SumWidget type='income' setTableQuery={setTableQuery} />
            </div>
            <div className="row">
                {tableQuery && <TableWidget tableQuery={tableQuery}/>}
            </div>
        </div>
    );
}
 
export default Accumulative;