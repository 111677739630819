import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useBoolean from '../../../../../hooks/useBoolean';
import { ApiContext } from '../../../../../services/api/api-config';
import {
    orderStatusTrans,
    orderStatusTransColor,
    orderTableSGroups,
    sumTotalWeight,
    kueskiResponse
} from '../../../../../services/modelUtils/orderUtils';
import "./OrderDetails.scss";
import {
    getProductPhotoUrl,
    getTotalUtilityFromOrder, getTotalUtilityFromOrderProduct,
} from "../../../../../services/modelUtils/productUtils";
import { moneyFormatter } from '../../../../../services/currencyUtils';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import loadAnim from "../../../../../assets/animations/890-loading-animation.json";
import Lottie from 'lottie-react';
import _ from 'lodash';
import Modal from "../../../../../components/Modal/Modal";
import Payment from '../Payment/Payment';
import {KueskiPayment} from "../KueskiPayment/KueskiPayment";

const OrderDetails = () => {

    const id = useParams().id;
    const api = useContext(ApiContext);
    const [orderObject, setOrderObject] = useState();
    const [loadingEntity,, loadingOff] = useBoolean(true);
    const [showUtility,,, toggleUtility] = useBoolean(false);
    const me=useSelector(({api})=>api.me);
    const [showingPaymentModal, setShowingPaymentModal] = useState();
    const [showingKueskiPaymentModal, setShowingKueskiPaymentModal] = useState();
    const [showingPaymentResponse, setShowingPaymentResponse] = useState();
    const [paymentMessage, setPaymentMessage] = useState('');

    useEffect(()=>{
        api.orders.get({id: id, params: {sGroups: orderTableSGroups(me), customProp: 'orderDetail'}}).then((response) => {
            setOrderObject(response);
            loadingOff();
        });
    },[id, setOrderObject, api, loadingOff, me]);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const type = params.get('type');
        const message = kueskiResponse[type];

        if(message && message.length > 0){
            setPaymentMessage(message);
            setShowingPaymentResponse(true);
        }
    }, [orderObject]);


    const closeModalAndUpdateOrder = () => {
        setShowingPaymentModal(false);
        api.orders.get({id: id, params: {sGroups: orderTableSGroups(me), customProp: 'orderDetail'}}).then((response) => {
            setOrderObject(response);
            loadingOff();
        });
    }

    return (
        <div className="OrderDetails">
            {loadingEntity ?
                <div className="OrderDetails-card d-flex flex-column">
                    <div className="OrderDetails-header">
                        <div className="row header-row">
                            <div className="col-12 col-lg-3 d-flex flex-column">
                                <Lottie animationData={loadAnim}  loop={true} />
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className="OrderDetails-card d-flex flex-column">
                <div className="OrderDetails-header">
                    <div className="row header-row">
                        <div className="col-12 text-center mb-3 border-bottom border-light">
                            <p className='text-info font-weight-bolder mb-1'>Dirección</p>
                            <p className='text-medium font-bold'>
                                {orderObject?.user ?
                                    <>
                                        {orderObject?.user?.profile?.street}, 
                                        {orderObject?.user?.profile?.suburb}. 
                                        {orderObject?.user?.profile?.city}, 
                                        {orderObject?.user?.profile?.state}. 
                                        CP. {orderObject?.user?.profile?.zipCode} 
                                    </>
                                    :
                                    <>
                                        <b>Invitado</b><br/>
                                        Calle: {orderObject?.guest?.street}, 
                                        Colonia: {orderObject?.guest?.neighbourhood?.name}, 
                                        Delegación: {orderObject?.guest?.neighbourhood?.municipality?.name}, 
                                        Estado: {orderObject?.guest?.neighbourhood?.municipality?.state.name}, 
                                        CP. {orderObject?.guest?.zipCode} 
                                    </>}
                            </p>
                        </div>
                        <div className="col-12 col-lg-4 d-flex flex-column">
                            <h1 className='magenta-color'>{orderObject?.guest ? `INVITADO: ${orderObject?.guest.name}` : orderObject?.user?.name }</h1>
                            <p className='text-info text-medium font-bold'>{orderObject?.guest ? orderObject?.guest.email : orderObject?.user.email}</p>
                        </div>
                        <div className="created col-12 col-lg-4 text-right">
                            <p className='text-danger text-center'><span className='magenta-color font-bold' >ID ODC:</span><span className='text-info font-bold text-medium'> {orderObject?.id}</span></p>
                            <p className='mentita-color text-center'>
                                <span className='magenta-color font-bold' >
                                    Cel. Aliada:</span><span className='text-info font-bold text-medium'>
                                        {orderObject?.user ?
                                            orderObject?.user?.profile?.phone||'SIN INFORMACIÓN'
                                            :
                                            orderObject?.guest?.phone||'SIN INFORMACIÓN'
                                        }
                                </span>
                            </p>
                        </div>
                        <div className="created col-12 col-lg-4 text-right">
                            <p className='text-danger text-center'><span className='magenta-color font-bold' >Fecha de creación:</span><span className='text-info font-bold text-medium'> {moment(orderObject?.createdDate).format('DD/MM/YYYY')}</span></p>
                            <p className='mentita-color text-center'><span className='magenta-color font-bold' >Número de rastreo:</span><span className='text-info font-bold text-medium'> {orderObject?.trackingNumber||'SIN INFORMACIÓN'}</span></p>
                        </div>
                    </div>
                </div>
                <div className="OrderDetails-body table-responsive">
                    <table className='table'>
                        <thead className='table-head'>
                            <tr className='table-danger'>
                                <th scope='col'>Imagen</th>
                                <th scope='col'>Nombre</th>                                
                                <th scope='col'>Detalles</th>
                                <th scope='col'>Categoría</th>
                                <th scope='col'>Cantidad</th>
                                <th scope='col'>Peso</th>
                                {showUtility && me.role &&
                                    <>
                                        <th scope='col'>Utilidad por producto</th>
                                        <th scope='col'>Precio de proveedor</th>
                                        <th scope='col'>Utilidad total</th>
                                    </>
                                }
                                <th scope='col'>Precio Unitario</th>
                                <th scope='col'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderObject?.orderProducts?.map((orderProduct) => (
                                <tr key={orderProduct.id}>
                                    <th className="image-container" style={{backgroundImage: `url(${getProductPhotoUrl(orderProduct.product, api)})`}}>
                                    </th>
                                    <th>
                                        <p className='mb-0 font-weight-bolder'>{orderProduct.product?.name||'Sin información'}</p>
                                        <p className='mb-0 text-info text-medium'>ID: {orderProduct.product?.id}</p>
                                    </th>
                                    <th className='details-box'>
                                        {orderProduct.product?.details}
                                    </th>
                                    <th className='details-box'>
                                        {orderProduct.product?.category?.name||'Sin categoría'}
                                    </th>
                                    <th>
                                        <div className="quantity-box">
                                            <p className='mb-0'>{orderProduct.quantity}</p>
                                        </div>
                                    </th>
                                    <th className='details-box'>
                                        {(orderProduct.product.weight||'-')+'gr.'}
                                    </th>
                                    {showUtility && me.role &&
                                        <>
                                            <th>
                                                {orderProduct.isReward ?
                                                    <p className='text-info'>¡Regalo mentita!</p>
                                                :
                                                    <p>$&nbsp;{moneyFormatter((orderProduct.price / orderProduct.quantity) - orderProduct.product?.providerPrice)}</p>
                                                }
                                            </th>
                                            <th>
                                                {orderProduct.isReward ?
                                                    <p className='text-info'>¡Regalo mentita!</p>
                                                :
                                                    <p>$&nbsp;{moneyFormatter(orderProduct.product?.providerPrice)}</p>
                                                }
                                            </th>
                                            <th>
                                                {orderProduct.isReward ?
                                                    <p className='text-info'>¡Regalo mentita!</p>
                                                :
                                                    <p>$&nbsp;{moneyFormatter( getTotalUtilityFromOrderProduct( orderProduct ) )}</p>
                                                }
                                            </th>
                                        </>
                                    }
                                    <th>
                                        {orderProduct.isReward ?
                                            <p className='text-info'>¡Regalo mentita!</p>
                                            :
                                            <p>$&nbsp;{moneyFormatter( orderProduct.price / orderProduct.quantity )}</p>
                                        }
                                    </th>
                                    <th>
                                        {orderProduct.isReward ?
                                            <p className='text-info'>¡Regalo mentita!</p>
                                            :
                                            <p>$&nbsp;{moneyFormatter(orderProduct.price)}</p>
                                        }
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-5 row justify-content-between">
                    <div className="OrderInfo-box col-12 col-lg-4 rounded pt-4">
                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold'>Status</p>
                            <p style={{color: orderStatusTransColor[orderObject?.status] }}>{orderStatusTrans[orderObject?.status]}</p>
                        </div>

                        {orderObject?.status==='paid' && (orderObject?.orderPaymentMethod==='TDC_MERCADOPAGO' || orderObject?.orderPaymentMethod==='KUESKI') &&
                            <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                                <p className='font-bold'>Tipo pago</p>
                                <p>{orderObject.orderPaymentMethod}</p>
                            </div>}

                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold mt-2'>Descuento</p>
                            <p>$&nbsp;{orderObject.discount ? moneyFormatter(orderObject?.discount):moneyFormatter(0)}</p>
                        </div>
                        {showUtility && me.role &&
                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold mt-2'>Utilidad</p>
                            <p>$&nbsp;{moneyFormatter(getTotalUtilityFromOrder(orderObject))}</p>
                        </div>
                        }
                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold mt-2'>Total de productos</p>
                            <p>{ _.reduce((orderObject?.orderProducts), (acc, current) => { return acc + current.quantity }, 0) }</p>
                        </div>
                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold mt-2'>Costo de envío</p>
                            <p>$&nbsp;{orderObject.shipping && moneyFormatter(orderObject?.shipping)}</p>
                        </div>
                        <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                            <p className='font-bold mt-2'>Peso acumulado</p>
                            <p>{sumTotalWeight(orderObject?.orderProducts)+'gr.'}</p>
                        </div>
                        <div className="OrderInfo-items mt-4 mb-2 d-flex align-items-center justify-content-between">
                            <p className='font-bold'>Saldo usado</p>
                            <p>$&nbsp;{ moneyFormatter(orderObject?.balanceUsed||0) }</p>
                        </div>
                        <div className="OrderInfo-items mt-4 mb-2 d-flex align-items-center justify-content-between">
                            <p className='font-bold'>Subtotal</p>
                            <p>$&nbsp;{moneyFormatter(orderObject?.subtotal)}
                            </p>
                        </div>
                        <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                            <p className='font-bold'>Total de la orden</p>
                            <p>$&nbsp;{orderObject?.total && moneyFormatter(orderObject?.total)}</p>
                        </div>
                    </div>
                    <div className="detailsContainer d-flex flex-column align-items-center pb-0">
                        <p className='text-info font-weight-bolder mb-1'>Notas de orden de compra</p>
                        <p className='text-medium font-bold'>{orderObject?.comment||'No existen notas para esta orden de compra.'}</p>
                    </div>
                    <div className="OrderPDF-box mt-5 w-100 d-flex justify-content-end">
                        {me.role &&
                        <div className="detailsContainer d-flex flex-column align-items-center mr-5 pb-0">
                            <p className='text-danger mb-1'>Mostrar detalles</p>
                            <FontAwesomeIcon icon={faEye} className={'close-icon cursor-pointer ' + (showUtility?'text-danger':'text-info')} onClick={toggleUtility}/>
                        </div>
                        }

                            {orderObject.status==='pending' && orderObject.total>500 &&
                                <button type="button"
                                    onClick={() => {setShowingKueskiPaymentModal(true)}}
                                    className="btn btn-primary mr-3 btn-kueski"
                                >
                                    Pagar con kueskiPay
                                </button>}

                            {orderObject.status==='pending' &&
                                <button type="button"
                                    onClick={() => {setShowingPaymentModal(true)}}
                                    className="btn btn-primary mr-3">
                                    Pagar con tarjeta
                                </button>}

                        <a target="_blank" rel="noopener noreferrer" href={api.orders.exportPdfUrl(orderObject)}>
                            <div className="btn btn-success">Exportar PDF</div>
                        </a>
                    </div>
                </div>

                {showingPaymentResponse &&
                    <Modal
                        title={paymentMessage}
                        onClose={() => {setShowingPaymentResponse(false)}}>
                    </Modal>}

                {showingKueskiPaymentModal &&
                    <Modal
                        title={"Pagar orden con KueskiPay"}
                        onClose={() => {setShowingKueskiPaymentModal(false)}}>
                        <KueskiPayment
                            orderObject={orderObject}
                            onClose={closeModalAndUpdateOrder}
                        />
                    </Modal>}

                {showingPaymentModal &&
                    <Modal
                        title={"Pagar orden con tarjeta"}
                        onClose={() => {setShowingPaymentModal(false)}}>
                        <Payment
                            orderObject={orderObject}
                            onClose={closeModalAndUpdateOrder}
                        />
                    </Modal>}

            </div>
            }
        </div>
    );
}

export default OrderDetails;
