import React, { useCallback, useContext, useMemo } from 'react';
import "./ShoppingDetails.scss";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ApiContext } from '../../../../../services/api/api-config';
import useBoolean from '../../../../../hooks/useBoolean';
import { getProductPhotoUrl, productStockStatus } from '../../../../../services/modelUtils/productUtils';
import {moneyFormatter} from "../../../../../services/currencyUtils";
import {
    fillOrderProductPublicPrices,
    getCartProductsTotalPublic,
    //shippingCalculator
} from "../../../../../services/modelUtils/orderUtils";
//import useShippingZones from "../../../../../hooks/useShippingZones";
import Modal from '../../../../../components/Modal/Modal';

const ShoppingDetails = ({ cart:myCart, setCart, handleShowRegister, smallButton }) => {
    const api = useContext(ApiContext);
    //const zones = useShippingZones();
    const [showShoppingCart, shoppingCartOn, shoppingCartOff] = useBoolean();
    const cartProducts = useMemo(() => myCart.cartProducts||[], [myCart]);

    // ------ Compute cart prices -------
    const productsTotal = useMemo( ()=>getCartProductsTotalPublic(cartProducts), [cartProducts] );
    const shipping = 0; //shippingCalculator( productsTotal, null, zones, null);
    const total = productsTotal + shipping;

    // -------  Remove item from cart ---------
    const removeItem= useCallback( (cartProduct) => {
        const {product} = cartProduct;
        
        const cartProducts = myCart.cartProducts.filter(cp => cp?.product.id !== product.id);

        setCart({
            ...myCart,
            cartProducts
        });
    }, [myCart, setCart]);

    const totalItems = myCart?.cartProducts?.reduce( (total, cProduct)=>total+( Number(cProduct.quantity) ||0), 0 );

    const pricedProducts = useMemo( ()=>fillOrderProductPublicPrices(cartProducts), [cartProducts] );

    const determineStatus = useCallback((product) => {
        if (product) {
            if (product.isActive === false) { return { status: "NO DISPONIBLE", color: "text-danger" } }
            return productStockStatus(product.stock);
        }
        return { status: "CARGANDO...", color: "" };
    }, []);

    const handleQuantityChange = (e, idx) => {
        const { value } = e.target;
        const newCartProducts = [...cartProducts];
        newCartProducts[idx].quantity = value;

        setCart({
            ...myCart,
            cartProducts: newCartProducts
        });        
    };

    return (<>
            <div className={'ShoppingDetails-button cursor-pointer' + (smallButton ? ' hidden-cont':' open-cont')} onClick={() => shoppingCartOn()}>
                {!smallButton?
                    <button className='btn cart-button'> Ver mi carrito </button> :
                    <FontAwesomeIcon icon={faShoppingCart} className='hidden-icon text-danger'/>}
                {totalItems && <span className='count-badge'>{totalItems}</span>}
            </div>
            { showShoppingCart &&
            <Modal
                className={'CartModal'}
                title={'Mi carrito de compras'}
                onClose={shoppingCartOff}
            >
                <div className="heading cf d-flex justify-content-between align-items-center">
                    <h1 className="font-weight-bolder mentita-color">Mi orden</h1>
                </div>

                <div className="cart">
                    <ul className="cartWrap">
                        {pricedProducts.map((cartProduct, idx) => (
                            <li className="item d-flex justify-content-between" key={cartProduct?.id||idx}>
                                <div className="cartSection d-flex">
                                    <div className="overlay-wrapper rounded text-center image-container">
                                        <div className="image" style={{backgroundImage: `url(${getProductPhotoUrl(cartProduct.product, api)})` }}>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column ml-3">
                                        <p className="itemNumber"># {idx+1}</p>
                                        <h3 className='mb-0'># {cartProduct?.product.name}</h3>
                                        <div className="d-flex align-items-center">
                                            <p>
                                                <input type="number" className="qty"
                                                    key={idx}
                                                    value={cartProduct.quantity||0}
                                                    onChange={e => handleQuantityChange(e, idx)}
                                                />
                                            </p>
                                            <p className={`stockStatus ${determineStatus(cartProduct?.product)?.color}`}>
                                                {determineStatus(cartProduct?.product)?.status} ( {cartProduct?.product.stock||0} disponibles. )
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="prodTotal text-center">
                                        <p className='m-0 font-weight-bolder mentita-color'>{ cartProduct.price ?
                                            '$\xa0' + moneyFormatter( cartProduct.price ):
                                            "Sin precio"}
                                        </p>
                                    </div>
                                    <div className="removeWrap ml-4 d-flex align-items-center"
                                            onClick={() => removeItem(cartProduct)}>
                                        <span className="remove">x</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='container-fluid'>
                    <div className="mb-2 row mt-4">
                        <div className="col-12 col-md-6">
                            <label className="font-weight-bolder">Notas</label>
                            <textarea className="form-control" 
                                value={myCart.notes || ''} 
                                onChange={e => setCart({...myCart, notes: e.target.value})}
                                placeholder='...' 
                            />
                        </div>
                    </div>
                </div>
                <div className="subtotal mt-4">
                    <ul className='list-group'>
                        <li className="list-group-item d-flex justify-content-between final">
                            <b className='mentita-blue'>
                                Subtotal
                            </b>
                            <span className="value">
                                $ { moneyFormatter( productsTotal, 'MXN' )}
                            </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between final">
                            <b className='mentita-blue'>
                                Costo de envío
                            </b>
                            <span className="value">
                                Pendiente
                            </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between final">
                            <h5 className='mentita-blue'>
                                Total
                            </h5>
                            <span className="value">
                                $ { moneyFormatter( total, 'MXN' ) }
                            </span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between">
                            <span 
                                className="btn btn-danger continue text-mentita" 
                                onClick={() => {
                                    handleShowRegister(); 
                                    shoppingCartOff();
                                }}>
                                Realizar orden
                            </span>
                        </li>
                    </ul>
                </div>
            </Modal>}
        </>
    );
}

ShoppingDetails.displayName="ShoppingDetails";

export default ShoppingDetails;
