import { copyPropertyIdOrThrow, copyPropertyOrThrow } from "../formUtils";

export const prepareGuestFormForServer = ( form )=>{
    const guest = {};

    copyPropertyOrThrow(form, guest, 'name', 'Por favor llena el campo de nombre', {trim: true});
    copyPropertyOrThrow(form, guest, 'lastname', 'Por favor llena el campo de apellidos', {trim: true});
    copyPropertyOrThrow(form, guest, 'phone', 'Por favor llena el campo de teléfono', {trim: true});
    copyPropertyOrThrow(form, guest, 'email', 'Por favor llena el campo de email', {trim: true});
    copyPropertyOrThrow(form, guest, 'street', 'Por favor llena el campo de calle y número', {trim: true});
    copyPropertyOrThrow(form, guest, 'zipCode', 'Por favor llena el campo de código postal', {trim: true});
    copyPropertyOrThrow(form, guest, 'addressReference', 'Por favor llena el campo de referencia de tu dirección', {trim: true});
    copyPropertyIdOrThrow(form, guest, 'neighbourhood', 'El código postal introducido no es válido', {trim: true});

    return guest;
}