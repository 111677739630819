import moment from 'moment';
import React from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../hooks/useTideTable';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import { paymentsSGroups } from "../../../../../services/modelUtils/accountingUtils";
import "./Status.scss"

const requestOptions={customProp: 'statusTable'};
const Status = () => {
    const paymentsColumns = useCallback(() => [
        {Header: "ID", accessor: payment => payment.id||'Sin información'},
        {Header: "Monto", accessor: payment => "$ " +moneyFormatter(payment?.amount)},
        {Header: "Tipo", accessor: payment => payment?.type === 'expense' ? 'Egreso' : 'Ingreso'},
        {Header: "Orden ID", accessor: payment => payment?.originOrder?.id||'Sin información'},
        {Header: "Referencia", accessor: payment => `${payment?.reference}`},
        {Header: "Método de pago", accessor: payment => `${payment?.paymentMethod?.name}`||'Sin información'},
        {Header: "Categoría", accessor: payment => `${payment?.paymentCategory?.name}`||'Sin información'},
        {Header: "Fecha de creación", accessor: payment =>  moment(payment.createdDate).format('DD/MM/YYYY')},
    ], []);

    const requestFilters = useMemo(()=>({
        "order[createdDate]": "DESC",
        sGroups: paymentsSGroups,
    }),[]);

    const columns = useMemo(() => paymentsColumns(), [paymentsColumns]);
    const table = useTideTable({
        entity: 'payments',
        columns,
        requestFilters,
        requestOptions
    });

    return (
        <div className="Status">
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-size-h3 font-weight-bolder text-dark center-text">Estado de Cuenta</span>
			    	</h3>
			    </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
                            <TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Status;