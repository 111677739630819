import React, { useContext, useEffect, useState } from 'react';
import "./LoansEdit.scss";
import LoansForm from "../LoansForm/LoansForm";
import { useParams } from 'react-router';
import { ApiContext } from '../../../../../../services/api/api-config';

const LoansEdit = () => {
    const id = useParams().id;
    const api = useContext(ApiContext);
    const [loanObject, setLoanObject] = useState();

    useEffect(()=>{
        api.loans.get({id: id}).then((response) => {
            setLoanObject(response);
        });
    },[id, setLoanObject, api]);

    return (
        <div className="LoansEdit">
            <LoansForm loanObject={loanObject}/>
        </div>
    );
}
 
export default LoansEdit;