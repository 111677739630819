import _ from "lodash";
import {getUnitPriceFromQuantity} from "./productUtils";
import {getEmptyOrderProduct} from "./orderProductUtils";
import { moneyFormatter } from "../currencyUtils";
import LogoKueskiSmall from "../../assets/img/kueski/kueski-logo-small.png";
import LogoMercadoPagoSmall from "../../assets/img/mercadopago/logo-mercadopago-small.png";

// ------ Order form utils --------

export const getEmptyOrder = ()=>({
    status: orderStatus.PENDING,
    shippingStatus: orderShippingStatus.PENDING,
    manualShipping: false,
    orderProducts:[ getEmptyOrderProduct() ],
    discount: "0",
    shipping: "0",
    balanceUsed: "0",
});

export const convertOrderToForm = ( order )=>{
    if(!order)
        return getEmptyOrder();
    return {
        ...order
    };
}

export const prepareOrderFormForServer = ( form )=>{

    const order = {
        id:form.id,
        status: form.status,
        shippingStatus: form.shippingStatus,
        manualShipping: !!form.manualShipping,
        trackingNumber: form.trackingNumber,
        comment: form.comment,
        user: form.user?.id,
        shipping: Number(form.shipping)||0,
        discount: Number(form.discount)||0,
        balanceUsed: (Number(form.balanceUsed)||0)+'',
        total: Number(form.total)
    };

    order.orderProducts = form.orderProducts?.map( (orderProduct, i)=>{
        if(!orderProduct?.product?.id)
            throw new Error(`Selecciona un producto para el artículo ${i+1}`);
        const quantity = Number(orderProduct?.quantity);
        if(isNaN(quantity) || quantity <= 0)
            throw new Error(`Ingresa una cantidad válida para el producto para el artículo ${i+1}`);
        return {
            id: orderProduct.id,
            product: orderProduct.product.id,
            quantity: quantity,
            isReward: !!orderProduct.isReward,
            price: !orderProduct.isReward? Number(orderProduct?.price):0
        };
    })

    if(!order.user && !form.guest)
        throw new Error("Debes seleccionar a la aliada");

    if(isNaN(order.shipping))
        throw new Error("Ingresa un valor de envío válido");
    if(isNaN(order.shipping))
        throw new Error("Ingresa un valor de saldo válido");
    if(!form.orderProducts?.length)
        throw new Error("Debes agregar al menos un producto");

    return order;
}

export const prepareOrderGuestFormForServer = ( form, cart, shipping, total )=>{
    const orderProducts = cart.cartProducts.map(cartProduct => {
        return {
            product: cartProduct.product,
            quantity: Number(cartProduct.quantity),
            isReward: false,
            price: Number(cartProduct.product.publicPrice)*Number(cartProduct.quantity)
        };
    });

    const order = {
        status: orderStatus.PENDING,
        shippingStatus: orderShippingStatus.PENDING,
        manualShipping: false,
        comment: form.comment,
        shipping: Number(shipping)||0,
        discount: Number(form.discount)||0,
        balanceUsed: '0',
        total: Number(total)
    };

    order.orderProducts = orderProducts.map( (cartProduct, i)=>{
        if(!cartProduct?.product?.id)
            throw new Error(`Selecciona un producto para el artículo ${i+1}`);
        const quantity = Number(cartProduct?.quantity);
        if(isNaN(quantity) || quantity <= 0)
            throw new Error(`Ingresa una cantidad válida para el producto para el artículo ${i+1}`);
        return {
            id: cartProduct.id,
            product: cartProduct.product.id,
            quantity: quantity,
            isReward: false,
            price: Number(cartProduct?.price)
        };
    })

    if(isNaN(order.shipping))
        throw new Error("Ingresa un valor de envío válido");
    if(isNaN(order.shipping))
        throw new Error("Ingresa un valor de saldo válido");
    if(!order.orderProducts?.length)
        throw new Error("Debes agregar al menos un producto");

    return order;
}


// ------ Order computing utils ---------

export const getOrderProductsTotal = ( order ) => {
    let total = 0;
    if(!order?.orderProducts?.length)
        return total;
    order.orderProducts.forEach( orderProduct => {
        if (orderProduct.isReward === false)
            total += Number(orderProduct.price);
    });
    return total;
}

export const orderShippingCalculatorForGuest = ( cart, neighbourhood, shippingZones ) => {
    const total = getOrderTotalForGuest(cart);
    const userZone = neighbourhood?.shippingZone;

    return shippingCalculator(total, userZone, shippingZones, 0, 0);
}

export const orderShippingCalculator = ( order, shippingZones ) => {

    const total = getOrderProductsTotal(order);
    let userZone = null;
    
    // if order is from user
    if(order?.user?.profile)
        userZone= order?.user?.profile?.neighbourhood?.shippingZone;
    
    // if order is from guest
    if(order?.guest?.neighbourhood)
        userZone = order?.guest?.neighbourhood?.shippingZone;

    return shippingCalculator(total, userZone, shippingZones, order.balanceUsed, order.discount);
}

export const shippingCalculator = ( total, userZone, zones, balanceUsed, discount )=>{
    const totalDiscount = Number(balanceUsed||0) + Number(discount||0);

    if (total - totalDiscount  >= 1500)
        return 0;
    if (userZone && zones && typeof zones[userZone] !== "undefined" ) {
        return zones[userZone];
    } else {
        return zones?.C || 220;
    }
}


export const getOrderTotal = ( order, shipping ) => {
    let total = 0;
    if(!order?.orderProducts?.length)
        return total;
    order.orderProducts.forEach( orderProduct => {
        if (orderProduct.isReward === false)
            total += Number(orderProduct.price);
    });
    if(Number(order.discount))
        total -= Number(order.discount);
    if(Number(order.balanceUsed))
        total -= Number(order.balanceUsed);
    if(total < 0)
        total=0;
    return total + (Number(shipping)||0);
}

export const getOrderTotalForGuest = ( order, shipping ) => {
    let total = 0;
    if(!order?.orderProducts?.length)
        return total;

    order.orderProducts.forEach( orderProduct => {
        total += (Number(orderProduct.product.publicPrice) * Number(orderProduct.quantity));
    });

    if(Number(order.discount))
        total -= Number(order.discount);
        
    if(total < 0)
        total=0;
    
    return total + (Number(shipping)||0);
}

/* Calculate total weight from order :: BEGIN */
export const sumTotalWeight = (orderProducts) => {
    if (!orderProducts) return 0;
    return orderProducts.reduce((a, b) => a + ((Number(b.product.weight )|| 0) * Number(b.quantity)), 0);
};
/* Calculate total weight from order :: END */

export const orderTableSGroups = (user) => {
    if (user.role) {
        return [
            ...previewOrderTable,
            'product_read_provider_price',
            'profile_read_neighbourhood',
            'neighbourhood_read',
            'neighbourhood_read_shipping_zone',
            'order_read_payment',
            'payment_read_payment_method',
            'payment_method_read',
            'order_read_guest',
            'guest_read',
            'neighbourhood_read_municipality',
            'municipality_read',
            'municipality_read_state',
            'state_read'
        ]
    } else {
        return previewOrderTable
    }
}
/* Order SGroups used in orders table :: END */

/*Gets the currentCart and returns an array of products ordered by id with counter :: BEGIN */
export const arrayOfFilterProducts = (currentCart) => {
        const arrayOfProducts = _.map(currentCart?.cartProducts, cartProduct => _.pick(cartProduct, 'product'));
        const filterProds =  _.map(arrayOfProducts,({ product }) => product);
        return [...filterProds.reduce( (mp, o) => {
            if (!mp.has(o.id)) mp.set(o.id, { ...o, count: 0 });
            mp.get(o.id).count++;
            return mp;
        }, new Map()).values()];
    };
/*Gets the currentCart and returns an array of products ordered by id with counter :: END */

/* Calculate order total from array of products :: BEGIN */
export const getCartProductsTotal = (cartProducts) => {

    const pricedProducts = fillOrderProductPrices(cartProducts);

    return pricedProducts.reduce(
        (total, cartProduct) => total + cartProduct.price,
        0);
}

/* Calculate order total from array of products :: BEGIN */
export const getCartProductsTotalPublic = (cartProducts) => {

    const pricedProducts = fillOrderProductPublicPrices(cartProducts);

    return pricedProducts.reduce(
        (total, cartProduct) => total + cartProduct.price,
        0);
}

/* Calculate order total from array of products :: END */

export const createOrderFromCart = ( cart )=>{

    let orderProducts = fillOrderProductPrices( cart.cartProducts )
        .map( orderProduct=>({...orderProduct, product: orderProduct.product.id, id: undefined }) );

    const useBalance = Number(cart.useBalance) || 0;

    return  {
        total: getCartProductsTotal(cart.cartProducts) - useBalance,
        balanceUsed: cart.useBalance,
        orderProducts,
        status: 'pending'
    }

};

export const getQuantityForWholesaleGroup = ( wholesaleGroup, orderProducts )=>{
    return orderProducts.reduce( (quantity, orderProduct)=>{
        if(orderProduct.product.wholesaleGroup === wholesaleGroup )
            return (Number(orderProduct.quantity)||0) + quantity;
        return quantity;
    }, 0 );
};

export const fillOrderProductPrices = ( orderProducts )=>{

    return orderProducts.map( orderProduct=>{
        if(!orderProduct.product){
            return orderProduct;
        }
        const quantity = getQuantityForWholesaleGroup( orderProduct.product.wholesaleGroup, orderProducts );
        const price = getUnitPriceFromQuantity( orderProduct.product, quantity );
        return {...orderProduct, price: orderProduct.quantity * price};
    });

};

export const fillOrderProductPublicPrices = ( orderProducts )=>{

    return orderProducts.map( orderProduct=>{
        if(!orderProduct.product){
            return orderProduct;
        }
        const price = orderProduct.product.publicPrice;
        return {...orderProduct, price: orderProduct.quantity * price};
    });

};

// ---------- CONSTANTS -------------

export const orderStatus = {
    PENDING: "pending",
    CANCELLED:"cancelled",
    PAID: "paid",
};

export const orderStatusTrans = {
    pending: "Pendiente",
    paid:"Pagada",
    cancelled:"Cancelada",
}

export const orderStatusTransColor = {
    pending: "rgb(255, 205, 1)",
    paid:"rgb(73, 94, 53)",
    cancelled:"rgb(255, 0, 0)",
}
export const orderStatusTransBackgroundColor = {
    pending: "rgba(255, 205, 1, 0.1)",
    paid:"rgba(73, 94, 53, 0.1)",
    cancelled:"rgba(255, 0, 0, 0.1)",
}


export const orderShippingStatus = {
    PENDING: "pending",
    RESERVED: "reserved",
    READY: "ready",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
    CANCELLED:"cancelled",
};

export const orderShippingStatusTrans = {
    pending: "Pendiente",
    cancelled:"Cancelar",
    ready:"Lista",
    shipped: "En ruta",
    delivered: "Entregado",
    reserved: "Apartado"
}

export const orderShippingStatusTransColor = {
    pending: "rgb(255, 205, 1)",
    cancelled: "rgb(255, 0, 0)",
    ready: "rgb(194, 134, 191)",
    shipped: "rgb(41, 109, 152)",
    delivered: "rgb(73, 94, 53)",
    reserved: "rgb(68, 68, 68)",
}

export const orderShippingStatusTransBackgroundColor = {
    pending: "rgb(255, 205, 1, 0.1)",
    cancelled: "rgb(255, 0, 0, 0.1)",
    ready: "rgb(194, 134, 191, 0.1)",
    shipped: "rgb(41, 109, 152, 0.1)",
    delivered: "rgb(73, 94, 53, 0.1)",
    reserved: "rgb(68, 68, 68, 0.1)",
}


/* Order SGroups used in orders table :: BEGIN */
export const previewOrderTable = [
    'order_read',
    'order_read_user',
    'user_read',
    'user_read_profile',
    'profile_read',
    'order_read_order_products',
    'order_read_balance_used',
    'order_product_read',
    'order_product_read_product',
    'product_read',
    'product_read_product_image',
    'app_file_read',
    'product_read_product_prices',
    'product_price_read',
    'created_date',
    'order_read_formatted_created_date',
    'order_read_payment_method',
    'order_read_month_from_created_date',
    'order_read_subtotal',
    'order_read_guest',
    'guest_read',
    'product_read_category',
        'category_read',
]

export const kueskiResponse = {
    correcto: 'El pago se ha procesado correctamente.',
    rechazado: 'No se pudo procesar el pago correctamente, intenta por favor con otro medio de pago.',
    cancelado: 'No se pudo procesar el pago correctamente, intenta por favor con otro medio de pago.',
    error: 'No se pudo procesar el pago correctamente, intenta por favor con otro medio de pago.'
};

export const getTotalWithPaymentMethod = (order) => {
    const enabledPaymentMethods = {
        tdc_mercado_pago: <img src={LogoMercadoPagoSmall} alt="Kueski"/>,
        kueski: <img src={LogoKueskiSmall} alt="Kueski"/>,
    };

    const total = order?.total;
    const paymentMethod = order?.payment?.paymentMethod?.id;

    return <div className="total-container">
        {paymentMethod &&
                <div className="payment-method-container">
                    {enabledPaymentMethods[paymentMethod]}
                </div>}
                $&nbsp;{ moneyFormatter(total) }
            </div>;
};
