import React, { useContext } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from "../../../../../services/notifier";
import LogoKueski from "../../../../../assets/img/kueski/kueski-logo.png";
import './KueskiPayment.scss';
import { useSelector } from 'react-redux';

const loadingId = "Payment.KueskiPayment.execute";

export const KueskiPayment = ({orderObject, onClose}) => {
    const api = useContext(ApiContext);
    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    const handleProcessPayment = () => {
        const paymentData = {orderId: orderObject.id};
        const guest = orderObject.guest;

        if(guest) {
            paymentData.guest = guest.id;
        }

        const request = api.orders.executeKueskiPayment({ id:orderObject.id, params:paymentData, loadingId, useCommonPath: !paymentData.guest });

        request.then((response) => {
            if(response.url){
                window.location.href = response.url;
            } else {
                getNotifier().error("Hubo un error al procesar tu pago");
            }
        });
    };

    return (<div className="KueskiPayment">
        <div className="KueskiPayment__container">
            <img src={LogoKueski} alt="Kueski"/>
            <h2 className="KueskiPayment__subTitle">Kueski Pay te permite hacer tus compras a crédito, pagar a plazos y sin tarjeta.</h2>

            <div className="KueskiPayment__steps">
                <div className="KueskiPayment__stepsContainer">
                    <div className="KueskiPayment__step">
                        <div>1</div>
                        <span>Crea tu cuenta</span>
                    </div>
                    <div className="KueskiPayment__step">
                        <div>2</div>
                        <span>Llena tu información</span>
                    </div>
                </div>
                <div className="KueskiPayment__stepsContainer">
                    <div className="KueskiPayment__step">
                        <div>3</div>
                        <span>Selecciona el plazo de pago</span>
                    </div>
                    <div className="KueskiPayment__step">
                        <div>4</div>
                        <span>Disfruta tu compra</span>
                    </div>
                </div>
            </div>

            <div className="KueskiPayment__details">
                <div className="KueskiPayment__messageRedirect">
                    Luego de hacer clic en "Pagar" serás redirigido a Kueski Pay <br/>
                    Paga a quincenas sin tarjeta de crédito o débito para completar tu compra de forma segura.<br/><br/>
                    <b>Para pagar con Kueski es necesario que el importe de la orden sea mayor o igual a $500MXN.</b>
                </div>
                
                <button type="button"
                    onClick={handleProcessPayment}
                    className="btn btn-primary mr-3 btn-kueski"
                    disabled={loading || (orderObject.total < 500)}
                >
                    {loading &&
                        <div className="loader-div mr-5"></div>}
                    Pagar
                </button>

                <div className='KueskiPayment__disclamer'>
                    Sujeto a aprobación de crédito. Consulta CAT, Comisión, restricciones y requisitos de contratación en &nbsp;
                    <a target='_blank' rel="noopener noreferrer" href="https://kueskipay.com">kueskipay.com</a>
                </div>
            </div>
        </div>
    </div>);
};
