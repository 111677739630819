import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import "./ReportWidget.scss";
import {DateRangePicker} from "react-dates";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import moment from 'moment';
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState"
import { ApiContext } from '../../../../services/api/api-config';
import { orderTableSGroups } from '../../../../services/modelUtils/orderUtils';
import { useSelector } from 'react-redux';
import { userListSGroups, userReferralasListSGroups } from '../../../../services/modelUtils/userUtils';
import { ProductSGroups } from "../../../../services/modelUtils/productUtils";

const ReportWidget = ({title, valueEntity, setValueEntity, useSelect = false, useDateRange = false, entity, query = false, id}) => {

    const api = useContext(ApiContext);
    const me = useSelector(({api})=>api.me);

    const {props, dateRange} = useDateRangePickerState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
        });

    const orderParams = useMemo(() => { 
        if (entity === 'orders') {
            return {
                token: api.token,
                "createdDate[after]": dateRange.startDate?.format('YYYY-MM-DD'),
                "createdDate[before]": dateRange.endDate?.format('YYYY-MM-DD 23:59'),
                user: valueEntity?.id,
                "order[createdDate]": "DESC",
                sGroups: orderTableSGroups(me)
            };
        } else if (entity === 'products') {
            return {
                token: api.token,
                sGroups: ProductSGroups
            };
        } else if (entity === 'users') {
            return {
                token: api.token,
                'exists[role]': false,
                sGroups: userListSGroups
            }
        } else if (entity === 'referrals') {
            return {
                token: api.token,
                'exists[role]': false,
                sGroups: userReferralasListSGroups
            }
        } else {
            return;
        }
    }, [api, dateRange, entity, valueEntity, me]);


    const requestUrl = api[entity].exportExcelUrl(orderParams);

    return (
        <div className="ReportWidget mt-3 col-12 col-lg-6">
            <div className={classNames("report-block open")}>
                <p className='title'>{title}<i className='fa fa-caret-down caret-icon'/>
                </p>
                <div className='body'>
                    <hr/>
                    <div className='container-fluid'>
                        <div className='row'>
                            {useDateRange && 
                                <div className='col-md-6'>
                                    <p className='filter-label'>Rango de fechas:</p>
                                    <DateRangePicker {...props} id={id} />
                                </div>
                            }
                            {useSelect &&
                                <div className='col-md-6'>
                                    <p className='filter-label'>Aliada:</p>
                                    <TideEntitySelect
                                        entity='users'
                                        value={valueEntity}
                                        onChange={setValueEntity}
                                        additionalFilters={{'exists[role]': false}}
                                    />
                                </div>
                            }
                        </div>
                        <div className='row'>
                            <a href={requestUrl} rel='noopener noreferrer' className='ml-4 mt-5'>
                                <button className='btn btn-danger'>Descargar</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ReportWidget;