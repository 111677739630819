import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useBoolean from '../../../../../hooks/useBoolean';
import useTideTable from '../../../../../hooks/useTideTable';
import "./Loans.scss";
import LoansForm from "./LoansForm/LoansForm";
import LoansPay from "./LoansPay/LoansPay";
import LoanPayments from "./LoanPayments/LoanPayments";
import {moneyFormatter} from "../../../../../services/currencyUtils";
import { paths } from '../../../../../services/routes/appRoutes';

const requestOptions={customProp: 'loansTable'};
const Loans = ({setCurrentEntity}) => {
    const [newLoan, newLoanOn, newLoanOff] = useBoolean(false);
    const [loanToPay, setLoanToPay] = useState();
    const [loanPaymentToShow, setLoanPaymentToShow] = useState();
    const history = useHistory();

    const goToEdit = useCallback((id) => history.push(paths.loansEdit.replace(':id', id)), [history]);

    const columns = useMemo(() => [
        {Header: "ID", accessor: loan => loan?.id},
        {Header: "Prestamista", accessor: loan => loan?.name},
        {Header: "Descripción", accessor: loan => loan?.description},
        {Header: "Interés", accessor: loan => `${loan?.interest}%`},
        {
            Header: "Editar el préstamo",
            Cell: ({row: {original} = {}}) =>
            <button className='btn btn-danger ml-6' onClick={() => goToEdit(original.id)}>
                Editar
            </button>
        },
        {
            Header: "Agregar pago",
            Cell: ({row: {original} = {}}) =>
            <button className='button-add ml-5' onClick={() => setLoanToPay(original)}>
                +
            </button>
        },
        {
            Header: "Ver pagos",
            Cell: ({row: {original} = {}}) =>
            <button className='btn btn-info' onClick={() => setLoanPaymentToShow(original)}>
                Pagos realizados
            </button>
        },
        {Header: "Monto inicial", accessor: loan => '$ '+moneyFormatter(loan.initialAmount)},
        {Header: "Monto actual", accessor: loan => Number(loan.actualAmount) < 0 ?'$ '+moneyFormatter(0) :'$ '+moneyFormatter(Math.abs(loan.actualAmount))},
        {Header: "Fecha de creación", accessor: loan =>  moment(loan.createdDate).format('DD/MM/YYYY')},
    ], [goToEdit, setLoanToPay, setLoanPaymentToShow]);

    const requestFilters = useMemo(()=>({
        sGroups: ['loan_read', 'created_date'],
        'order[createdDate]':"DESC"
    }),[]);

    const table = useTideTable({
        entity: 'loans',
        columns,
        requestFilters,
        requestOptions
    });

    return (
        <div className={"Loans " +( (newLoan || loanToPay || loanPaymentToShow) && "scroll-down-block")}>
            {loanToPay && <LoansPay loanToPay={loanToPay} setLoanToPay={setLoanToPay}/>}
            {newLoan && <LoansForm newLoanOff={newLoanOff} setCurrentEntity={setCurrentEntity}/>}
            {loanPaymentToShow && <LoanPayments requestLoan={loanPaymentToShow} setLoanPaymentToShow={setLoanPaymentToShow}/>}
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-size-h3 font-weight-bolder text-dark center-text">Préstamos</span>
			    	</h3>
			    	<button className='btn btn-outline-danger' onClick={newLoanOn}>
			    		Nuevo préstamo
                    </button>
			    </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
                            <TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loans;
