import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import loadingAnim from "../../../../../assets/animations/890-loading-animation.json";
import {
    getProductPhotoUrl,
    ProductSGroups
} from "../../../../../services/modelUtils/productUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {ApiContext} from "../../../../../services/api/api-config";
import _ from "lodash";
import { STATE_ACTIONS } from 'tide-api';
import CategoryLabel from '../../../../../components/CategoryLabel/CategoryLabel';

const { STATE_ACTION_CONCAT, STATE_ACTION_SET } = STATE_ACTIONS;

const ProductsMosaicView = ({ filters, onProductEdit, onShowPrices, deleteProduct, toggleProductActive, onAddProductToCart, customProp }) => {

    const api = useContext(ApiContext);

    const me = useSelector(({api})=>api.me);
    const productMeta = useSelector(s=>s.api[customProp+'Meta']);
    const productsList = useSelector(s=>s.api[customProp]);
    const itemsPage = productMeta?.currentPage||1;

    const loadData = useCallback(( filters, page )=>{
        return api.products.get({
            stateAction: page === 1? STATE_ACTION_SET : STATE_ACTION_CONCAT,
            customProp,
            params:{
                ...filters,
                itemsPerPage: 8,
                page,
                sGroups: ProductSGroups,
                isActive:me.role?filters.isActive?true:null:true,
            }
        })
    },[api, me, customProp]);

    const fetchData = useCallback(() => {
        loadData( filters, itemsPage+1 );
    }, [itemsPage, loadData, filters]);

    const throttledLoadData = useMemo( ()=>_.debounce(loadData, 650), [loadData] );

    useEffect(()=>{
        throttledLoadData( filters, 1 );
    },[throttledLoadData, filters]);





    return (
        <div className={"ProductsMosaicView"}>
            <>
                {productsList && productMeta &&
                <InfiniteScroll
                    dataLength={productsList?.length}
                    next={fetchData}
                    key={filters}
                    hasMore={productMeta.totalItems>(productsList.length)}
                    loader={<Lottie animationData={loadingAnim}  loop={true} />}
                    className='row'
                >
                    {productsList.map((product) => (
                        <div className="col-md-6 col-xxl-3 col-lg-3 mt-3" key={product.id}>
                            {/*begin::Card*/}
                            <div className={"card card-custom card-shadowless product-container" + ( !product.isActive && " opacity-60")}>

                                <CategoryLabel>{ product.category?.name }</CategoryLabel>

                                <div className="card-body p-5 mt-5">
                                    {/*begin::Image*/}
                                    <div className="overlay">
                                        <div className="overlay-wrapper rounded text-center image-container">
                                            <div className="mw-100 w-500px image" style={{backgroundImage: `url(${getProductPhotoUrl(product, api)})` }}>
                                            </div>
                                            {product.stock < 1 &&
                                            <div className='overlay-top'>
                                                <div className="square">
                                                    <p className='font-bold text-uppercase'>Agotado</p>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        {me.role ?
                                            <div className="overlay-layer">
                                                <span className="btn font-weight-bolder btn-sm btn-danger mr-2" onClick={() => deleteProduct(product)}>Eliminar</span>
                                                <span className={"btn font-weight-bolder btn-sm " + (product.isActive ? 'btn-light-danger': 'btn-light-info')} onClick={() => toggleProductActive(product)}>{product.isActive === true ? 'Desactivar' : 'Activar'}</span>
                                            </div>
                                            :
                                            <>
                                                {product.stock > 0 &&
                                                <div className="overlay-layer">
                                                    <span className={"btn font-weight-bolder btn-sm " + (product.isActive ? 'btn-green': 'btn-light-info')} onClick={() => onAddProductToCart(product)}>Agregar al carrito de compras</span>
                                                </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                                        <div className="product-detail-cont mb-2">
                                            <span className="font-size-h6 font-size-xxl-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1">{product.name}</span>
                                        </div>
                                        <div className="product-detail-cont">
                                            {product.details}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <span className="font-size-sm text-danger text-uppercase stock-text text-bold mr-2">Stock:</span>
                                            <span className="font-size-sm">{product.stock}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {me.role ?
                                            <>
                                                <span
                                                    className="btn font-weight-bolder btn-sm btn-success mr-2"
                                                    onClick={() => onProductEdit(product)}>
                                                    Editar
                                                </span>
                                                <span className="btn font-weight-bolder btn-sm btn-info"
                                                      onClick={() =>onShowPrices(product.productPrices)}>
                                                        Ver precios
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span
                                                    className={"btn font-weight-bolder market-button btn-sm btn-green " + (product.stock < 1 && 'hide')}
                                                    onClick={() => onAddProductToCart(product)}>Añadir al carrito<FontAwesomeIcon
                                                    icon={faShoppingCart} className='ml-4'/></span>
                                                <span
                                                    className={"btn font-weight-bolder market-button btn-sm btn-info ml-3 " + (product.stock < 1 && 'hide')}
                                                    onClick={() =>onShowPrices(product.productPrices)}>
																	Ver precios
																</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                }
            </>
        </div>
    );
};

export default ProductsMosaicView;
