import React, { useContext, useCallback, useState } from 'react';
import "./ForgotPassword.scss";
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {ReactComponent as Icon} from "../../../assets/img/logo.svg";
import {paths} from "../../../services/routes/mainRoutes";
import {Link} from "react-router-dom";

const ForgotPassword = () => {

    const api=useContext(ApiContext);

    const {form, handleInputChange} = useFormState({});
    const [showMessage, setShowMessage] = useState();

    const sendRequest=useCallback((e)=>{
        e.preventDefault();
        if(!form.email)
            return;
        api.users.passwordRecovery({params: {email: form.email}}).then(() => {
            setShowMessage('Te llegará un correo para recuperar tu contraseña al email que nos indicaste.');
        }).catch(() => {
            setShowMessage('El correo que ingresaste no es válido');
        });
    },[api, form]);

    return (
        <div className='ForgotPassword'>
            <div className='login-form text-center p-7 position-relative overflow-hidden'>
                <div className="mt-5 mb-15">
                    <Link to={paths.landing} className='links'>
                        <Icon className="max-h-75px icon"/>
                    </Link>
                </div>
                {showMessage && 
                    <div className="registerResponse mb-4 text-success">
                        <h4>{showMessage}</h4>
                    </div>
                }
                <div className="login-signin">
                    <div className="mb-20">
                        <h3>Genera una nueva contraseña</h3>
                        <div className="mentita-color font-weight-bold mb-5">Ingresa tu correo, te enviaremos un enlace para reestablecer tus contraseñas</div>
                    </div>
                    <form className="form" id="kt_login_signin_form" onSubmit={sendRequest}>
                        <div className="form-group mb-5">
                            <input id='login-user-input' onChange={handleInputChange('email')} value={form.email||''} className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="username" auto autoComplete="off" />
                        </div>
                        <button id="kt_login_signin_submit" disabled={!form.email} type='submit' className="submit-button btn font-weight-bold px-9 py-4 my-3 mx-4">Enviar</button>
                    </form>
                    <div className="mt-10">
                        <span className="opacity-70 mr-4">¿Aún no tienes cuenta?</span>
                        <a href="/register" id="kt_login_signup" className="mentita-color text-hover-primary font-weight-bold">¡Regístrate!</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ForgotPassword;