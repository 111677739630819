import React from 'react'
import PrivacyPolicy from "../../assets/img/aviso-MB.pdf";
import TermsAndConditions from "../../assets/pdf/terms-and-conditions.pdf"
import ReturnPolicy from "../../assets/img/devolucion.pdf";
import './Footer.scss';

const Footer = () => {
    return (
        <div className='Footer'>
            <a href={TermsAndConditions}  rel="noreferrer" target='_blank'>
                Términos y condiciones
            </a>
            <div className='separator'>|</div>
            <a href={ReturnPolicy}  rel="noreferrer" target='_blank'>
                Políticas de devolución
            </a>
            <div className='separator'>|</div>
            <a href={PrivacyPolicy}  rel="noreferrer" target='_blank'>
                Aviso de privacidad
            </a>
        </div>
    )
};

export default Footer;