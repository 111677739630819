import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useBoolean from '../../../../../hooks/useBoolean';
import useTideTable from '../../../../../hooks/useTideTable';
import IncomesForm from "./IncomesForm/IncomesForm";
import "./Incomes.scss";
import { incomesTableSGroups } from '../../../../../services/modelUtils/accountingUtils';
import {moneyFormatter} from "../../../../../services/currencyUtils";
import { paths } from '../../../../../services/routes/appRoutes';

const requestOptions={customProp: 'incomesTable'};
const Incomes = () => {
    const [newIncome, newIncomeOn, newIncomeOff] = useBoolean(false);
    const history = useHistory();

    const goToEdit = useCallback((id) => history.push(paths.incomesEdit.replace(':id', id)), [history]);

    const incomesColumns = useCallback(() => [
        {Header: "ID ODC", accessor: "originOrder.id"},
        {Header: "Monto", accessor: income => "$ "+moneyFormatter(income?.amount)},
        {Header: "Referencia", accessor: income => income?.reference||"Sin referencia"},
        {Header: "Método de pago", accessor: income => income?.paymentMethod?.name||'Sin información'},
        {
            Header: "Categorías",
            Cell: ({row: {original} = {}}) =>
            <div className='categoriesContainer'>
                <ul>
                {original?.paymentCategories?.map((cat) => (
                    <li key={cat.id}>{cat.name}</li>
                ))
                }
                </ul>
            </div>
        },
        {
            Header: "Editar el ingreso",
            Cell: ({row: {original} = {}}) =>
            <button className='btn btn-danger' onClick={() => goToEdit(original.id)}>
                Editar
            </button>
        },
        {Header: "Fecha de creación", accessor: income =>  moment(income.createdDate).format('DD/MM/YYYY')},
    ], [goToEdit]);

    const requestFilters = useMemo(()=>({
        type: 'income',
        "order[createdDate]": "DESC",
        sGroups: incomesTableSGroups,
    }),[]);

    const columns = useMemo(() => incomesColumns(), [incomesColumns]);
    const table = useTideTable({
        entity: 'payments',
        columns,
        requestFilters,
        requestOptions
    });

    return (
        <div className={"Incomes " + (newIncome && "scroll-down-block")}>
            {newIncome && <IncomesForm newIncomeOff={newIncomeOff}/>}
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-size-h3 font-weight-bolder text-dark center-text">Ingresos</span>
			    	</h3>
			    	<button className='btn btn-outline-danger' onClick={newIncomeOn}>
			    		Nuevo ingreso
                    </button>
			    </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
                            <TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Incomes;
