import React from 'react';
import semver from 'semver-lite';
import {version} from '../../pckg';
import qs from "qs";
import {getNotifier} from "../notifier";

function errorHandler(e, callParams) {

    //If the user's session ended, do not show the error notifier
    if(e?.status === 401 || e?.code === 401) {
        return;
    }
    if (!callParams?.config?.preventNotifier)
        getNotifier().error((e?.detail) || 'Parece que hubo un error', '¡Oh no!');
}

const querySerialize = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' });

const config ={
    host:'http://mentita-back.tide.company',
    commonPath:'api',
    credentials:'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse:(r)=>r.meta,
    onError: errorHandler,
    strictMode: false,
    handleUnknownMethods: true,
    appendHeaders: function (headers, callConfig) {//Create a requestLog so we can send it with the crash data
        if (!this.requestLog)
            this.requestLog = [];
        const callInfo = callConfig.path+' PARAMS '+JSON.stringify(callConfig.params);
        this.requestLog.unshift(callInfo);
        this.requestLog = this.requestLog.slice(0, 10);
    },
    endpoints:[
        'me',
        'paymentMethods',
        'paymentCategories',
        'providers',
        'neighbourhoods',
        'states',
        'loans',
        {
            name: 'payments',
            customMethods: {
                getTotal: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/payments/sum', customProp, params, config)
                },
            }
        },
        {
            name: 'profiles',
            customMethods: {
                addReferral: function ({ params, ...config}) {
                    return this.apiCall('/profiles/add_referral', '_', params, {...config, method:'POST'})
                },
                shareByEmail: function ({ params, ...config}) {
                    return this.apiCall('/profiles/share_by_email', '_', params, {...config, method:'POST'})
                },
            }
        },
        {
            name: 'orders',
            customMethods: {
                exportExcelUrl: function exportExcelUr(filters) {
                    const reportConfig = {
                        name: `Registro de órdenes Mentita.xlsx`,
                        title: 'Registro de órdenes Mentita',
                        headers: {
                            'id': 'ODC',
                            'monthFromCreatedDate' : 'Mes',
                            'formattedCreatedDate' : 'Fecha',
                            'user.email': 'Contacto',
                            'orderPaymentMethod': 'Método de Pago',
                            'user.name': 'Nombre de distribuidora',
                            'status': 'Status',
                            'trackingNumber': 'Número de rastreo',
                            'discount': 'Descuento',
                            'subtotal': 'Subtotal',
                            'shipping': 'Envío',
                            'balanceUsed' : 'Puntos mentita',
                            'total': 'Total'
                        },
                        transStrings: {
                            'pending': 'Pendiente',
                            'cancelled': 'Cancelada',
                            'paid': 'Pagada'
                        }
                    }
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/orders.xlsx?${query}`;
                },
                exportPdfUrl: function exportExcelUr( order ) {
                    return `${config.host}/api/orders/${order.id}/overview?token=${this.token}`;
                },
                executePayment: function executePayment({params, ...config}){
                    return this.apiCall('/order_payments/execute_payment', '_', params, {...config, method:'POST'})
                },
                executeKueskiPayment: function executeKueskiPayment({params, ...config}){
                    return this.apiCall('/order_payments/execute_kueski_payment', '_', params, {...config, method:'POST'})
                }
            }
        },
        {
            name: 'products',
            customMethods: {
                exportExcelUrl: function exportExcelUr(filters) {
                    const reportConfig = {
                        name: `Registro de Productos Mentita.xlsx`,
                        title: 'Registro de Productos Mentita',
                        headers: {
                            'id': 'Id',
                            'name': 'Nombre',
                            'details': 'Detalles',
                            'stock': 'Stock',
                            'weight': 'Peso',
                            'wholesaleGroup': 'Grupo de mayoreo',
                            'isActive': 'Activo',
                            'providerPrice': 'Precio de Compra',
                            'allProductPricesString': 'Precio de Venta'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/products.xlsx?${query}`;
                },
                exportExcelReport: function exportExcelUr(filters) {
                    const reportConfig = {
                        name: `Registro de Productos Mentita.xlsx`,
                        title: 'Registro de Productos Mentita',
                        headers: {
                            'id': 'Id',
                            'name': 'Nombre',
                            'details': 'Detalles',
                            'stock': 'Stock',
                            'weight': 'Peso',
                            'wholesaleGroup': 'Grupo de mayoreo',
                            'isActive': 'Activo',
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/products.xlsx?${query}`;
                }
            }
        },
        'productPrices',
        'cartProducts',
        'carts',
        {
            name:'appFiles',
            customMethods:{
                getUrl: function(id){ return `${this.host}/file/${id}`},
            }
        },
        {
            name: 'users',
            customMethods: {
                createPublic: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/register', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                passwordRecovery: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/recover_password_request', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                resetPassword: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/reset_password', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                validateAccount: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/validate_my_acount', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                sendInfo: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/contact_form', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                exportExcelUrl: function exportExcelUr(filters) {
                    const reportConfig = {
                        name: `Registro de Distribuidoras Mentita.xlsx`,
                        title: 'Registro de Distribuidoras Mentita',
                        headers: {
                            'id': 'Id',
                            'username': 'Nombre de usuario',
                            'email': 'Correo electrónico',
                            'name': 'Nombre',
                            'lastNames': 'Apellidos',
                            'profile.phone': 'Teléfono',
                            'profile.formattedAddress': 'Dirección',
                            'profile.birthday': 'Fecha de nacimiento',
                            'ordersCount': '# de Órdenes',
                            'profile.balance': 'Saldo a favor',
                            'profile.referredsCount': '# referidas',
                            'isActive': 'Activa',
                            'lastOrderDate': 'Última Orden'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/users.xlsx?${query}`;
                }
            }
        },
        {
            name: 'referrals',
            customMethods: {
                exportExcelUrl: function exportExcelUr(filters) {
                    const reportConfig = {
                        name: `Reporte de programa de aliadas.xlsx`,
                        title: 'Reporte de programa de aliadas',
                        headers: {
                            'name': 'Nombre',
                            'lastNames': 'Apellidos',
                            'profile.balance': 'Saldo actual',
                            'generatedBalance': 'Balance generado',
                            'usedBalance': 'Balance usado',
                            'incomeMovesBalance': 'Balance manual ingresado'
                        }
                    };
                    const query = querySerialize({
                        ...filters,
                        pagination: false,
                        token: this.token,
                        reportConfig:
                            JSON.stringify(reportConfig),
                    });
                    return `${config.host}/api/users.xlsx?${query}`;
                }
            }
        },
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function( error, stack, user, additionalInfo ){
                    let params = { stack,
                        error: error?.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo };
                    return this.apiCall( '/frontend_error', "error_sent", params, { method: "POST", queueable: true, useCommonPath: false }  )
                }
            }
        },
        {
            name: 'settings',
            customMethods: {
                postImage: function({customProp = "_", params, ...config}){
                    return this.apiCall( '/settings/1', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                getZones: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/settings/zones', customProp, params, config)
                },
            }
        },
        'log_balances'
    ],
    login:{
        createBody: ( username, password )=>{
            let credentials = new FormData();
            credentials.append("_username", username );
            credentials.append("_password", password);
            credentials.append("client_data", window?.navigator?.userAgent||'no-data');
            return credentials;
        },
    },
};


function handleResponse(response, headers){

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(version) && semver.validate(version) && semver.gt(server, version)) {
            if(!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    }
    catch(e){
        console.error('Error reading versions: '+e);
    }

    return response.data;
}

if(process.env.REACT_APP_BUILD === 'dev')
    config.host=localStorage.host||'https://mentita-back.tide.company';

if(process.env.REACT_APP_BUILD === 'prod')
    config.host='https://back.mentitababy.com';

export default config;

export const ApiContext = React.createContext(null);
