import React, {useCallback, useContext, useState} from 'react';
import {getNotifier} from "../../../../../services/notifier";
import {useSelector} from "react-redux";
import {ApiContext} from "../../../../../services/api/api-config";

const ReferralCodeForm = ({onCancel}) => {

    const api = useContext(ApiContext);
    const [code, setCode] = useState('');

    const loadingId = 'ShareModal.addingReferral';
    const addReferral = useCallback(()=>{
        if(!code)
            return getNotifier().error("Ingresa el código de una aliada");
        api.profiles.addReferral({params:{referralCode:code}, loadingId})
            .then(()=>api.me.get())
            .then(()=>{
                getNotifier().success("Código añadido correctamente");
                onCancel();
            })
    },[api, code, onCancel]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className={"ReferralCodeForm"}>
            <p>Ingresa el código de la aliada que te comentó sobre la plataforma</p>
            <input className='input form-control' placeholder="Ingresa el código..." value={code} onChange={e=>setCode(e.target.value)} />
            <div className='buttons-container'>
                <button className='btn btn-danger' onClick={onCancel} disabled={loading}>Cancelar</button>
                <button className='btn btn-success' onClick={addReferral} disabled={loading}>Guardar</button>
            </div>
        </div>
    );
};

export default ReferralCodeForm;
