import React from 'react';
import "./AsideBar.scss";
import "../../../../../assets/styles/theme.scss";
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AsideBar = ({setCatalogue, catalogues}) => {
    return (
        <div className={"AsideBarCat aside-secondary d-flex flex-row-fluid"}>
            <div className='tab-pane p-3 px-lg-7 py-lg-5 fade active show'>
                <h3 className="p-2 p-lg-3 my-1 my-lg-3r">Catálogos</h3>
			    <div className="list list-hover">
                    { catalogues.map( catalogue=>
                            <div className="list-item hoverable p-2 p-lg-3 mb-2" key={catalogue.name} onClick={()=>setCatalogue(catalogue)}>
			    		        <div className="d-flex align-items-center">
			    		        	{/*begin::Symbol*/}
			    		        	<div className="symbol symbol-40 symbol-light mr-4">
			    		        		<span className="symbol-label bg-hover-white">
			    		        			<FontAwesomeIcon icon={faBookmark} className="catalogue-icon h-50 align-self-center"/>
			    		        		</span>
			    		        	</div>
			    		        	{/*end::Symbol*/}
			    		        	{/*begin::Text*/}
			    		        	<div className="d-flex flex-column flex-grow-1 mr-2">
			    		        		<span className="text-dark-75 font-size-h6 mb-0">{catalogue.name}</span>
			    		        	</div>
			    		        	{/*begin::End*/}
			    		        </div>
			    	        </div>
                        )
                    }
			    </div>
            </div>
        </div>
    );
}

export default AsideBar;
