import React, { useContext, useEffect } from 'react';
import "./ValidateAccount.scss";
import {ReactComponent as Icon} from "../../../assets/img/logo.svg";
import {paths} from "../../../services/routes/mainRoutes";
import {Link, useLocation} from "react-router-dom";
import { ApiContext } from '../../../services/api/api-config';
import useBoolean from '../../../hooks/useBoolean';

const ValidateAccount = () => {
    const [isValid, validTrue, validFalse] = useBoolean();
    const api = useContext(ApiContext);
    const queryString = useLocation().search;

    useEffect(() => {
        const token = new URLSearchParams(queryString).get("t");
        api.users.validateAccount({params: {code: token}}).then(() => {
            validTrue();
        }).catch(() => {
            validFalse();
        })
    }, [api, queryString, validFalse, validTrue]);

    return (
        <div className="ValidateAccount">
            <div className='login-form text-center p-7 position-relative overflow-hidden'>
			    <div className="mt-5">
                    <Link to={paths.landing} className='links'>
                        <Icon className="max-h-75px icon"/>
                    </Link>
			    </div>
                {isValid ?
                    <div className="registerResponse mb-4 mentita-color">
                        <h4>¡Tu cuenta ha sido validada correctamente!<br/>
                        Ahora ingresa a tu portal dando click en el siguiente enlace:</h4>
                        <Link to={paths.login} className='links'>
                            <h5 className='mt-5 mb-5'>Inicia Sesión</h5>
                        </Link>
                    </div>
                    :
                    <div className="registerResponse mb-4 mentita-color">
                        <h4>No pudimos validar tu cuenta<br/>
                        Por favor verifica que tu usuario sea correcto</h4>
                    </div>
                }
            </div>
        </div>
    );
}
 
export default ValidateAccount;