import moment from 'moment';
import React, {useCallback, useMemo} from 'react';
import Modal from "../../../../components/Modal/Modal";
import { useSelector } from 'react-redux';
import {moneyFormatter} from "../../../../services/currencyUtils";
import TideReactTable from "../../../../components/TideReactTable/TideReactTable";
import useTideTable from '../../../../hooks/useTideTable';

const BalanceModal = ({ onClose }) => {
    const user=useSelector(({api})=>api.me);

    const referredColumns = useCallback(() => [
        {Header: "Descripción", accessor: 'description'},
        {Header: "Monto", accessor: log => "$ " + moneyFormatter(Number(log?.amount))},
        {Header: "Fecha", accessor: log =>  moment(log.createdDate).format('DD/MM/YYYY HH:mm')}
    ], []);

    const columns = useMemo(() => referredColumns(), [referredColumns]);
    
    const requestFilters = useMemo(()=>({
        profile: user.profile.id,
        sGroups: groups,
    }),[user]);

    const table = useTideTable({
        columns,
        entity: 'log_balances',
        requestFilters
    });

    return (
        <Modal
            title={"Mi balance de puntos"}
            className={"BalanceModal"}
            onClose={onClose}>
            <div className='share-content'>
                <div className='description'>
                    {/*begin::Table*/}
                    <TideReactTable {...table.tableProps} />
                    {/*end::Table*/}
                </div>
            </div>
        </Modal>
    );
};

const groups = ['read_created_date', 'log_balance_write'];

export default BalanceModal;
