import React, { useContext } from 'react'
import './Landing.scss'
import logoSrc from "../../../assets/img/logo.png";
import {paths} from "../../../services/routes/mainRoutes";
import {Link} from "react-router-dom";
import ProfileImage from "../../../assets/img/profileImgs/profileImg_1.png";
import wp from "../../../assets/img/wap.png";
import fb from "../../../assets/img/3fb.png";
import ig from "../../../assets/img/2insta.png";
import { ApiContext } from '../../../services/api/api-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExchangeAlt, faScroll} from '@fortawesome/free-solid-svg-icons';
import PrivacyPolicy from "../../../assets/img/aviso-MB.pdf";
import ReturnPolicy from "../../../assets/img/devolucion.pdf";
import Footer from '../../../components/Footer/Footer';

export default function Landing() {
    const api = useContext(ApiContext);

    return (
        <div>
            <div className="Landing" style={{backgroundImage: `url(${window.innerWidth > 600  && api.host + '/settings/banner_image'})`}}>
                <div className="portada">
                    <div className="nav">
                        <div className="nav-element logo-box">
                            <img src={logoSrc} alt={'Logo'} className='main-logo'/>
                        </div>
                        <div className="nav-elements-container">
                            <a href={paths.catalogue} target="_blank" rel='noreferrer' className='links'>
                                <div className="nav-element login-box">
                                    <p className='font-bold text-center mb-0'>Catálogo</p>
                                </div>
                            </a>
                            <Link to={paths.register} className='links'>
                                <div className="nav-element login-box">
                                    <p className='font-bold text-center mb-0'>¡Quiero ser aliada! </p>
                                </div>
                            </Link>
                            <a href="https://www.mentitababyshop.com" className='links'>
                                <div className="nav-element login-box">
                                    <p className='font-bold text-center mb-0'>Tienda en línea</p>
                                </div>
                            </a>
                            <Link to={paths.login} className='links login-link'>
                                <div className="nav-element login-box-img login-box">
                                    <img src={ProfileImage} alt="Profile"/>
                                    <p className='font-bold text-center mb-0'>Iniciar Sesión</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="body">

                    <div className="slide-link body-privacy" style={{display: 'none'}}>
                        <FontAwesomeIcon icon={faScroll} className='icon'/>
                        <a href={PrivacyPolicy}  rel="noreferrer" target='_blank'>
                        Aviso de Privacidad
                        </a>
                    </div>
                    <div className="slide-link body-return"  style={{display: 'none'}}>
                        <FontAwesomeIcon icon={faExchangeAlt} className='icon'/>
                        <a href={ReturnPolicy}  rel="noreferrer" target='_blank'>
                        Políticas de devolución
                        </a>
                    </div>
                    <div className="body-social-container">

                        <a href='https://www.facebook.com/MentitaBaby/'  rel="noreferrer" target='_blank'
                        className="footer-element text-underline">
                            <img src={fb} alt="Fb" className="wp"/>

                        </a>

                        <a href='https://www.instagram.com/mentitababy/'rel="noreferrer" target='_blank'
                        className="footer-element text-underline">
                            <img src={ig} alt="Ig" className="wp"/>

                        </a>

                        <a href='https://api.whatsapp.com/send?phone=525510510704&text=¡Hola mentita baby!'
                        rel="noreferrer" target='_blank' className="footer-element text-underline">
                        <img src={wp} alt="WhatsApp" className="wp"/>
                        </a>
                    </div>


                </div>
            </div>

            <Footer />
        </div>
    )
}
