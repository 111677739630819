import React, {useCallback, useContext, useState} from 'react';
import {ApiContext} from "../../../../../services/api/api-config";
import {getNotifier} from "../../../../../services/notifier";
import {useSelector} from "react-redux";

const ShareByEmail = ({onCancel}) => {

    const api = useContext(ApiContext);
    const [email, setEmail] = useState('');

    const loadingId = 'ShareByEmail.sharingEmail';
    const sendEmail = useCallback(()=>{
        if(!email)
            return getNotifier().error("Ingresa el email de la aliada");
        api.profiles.shareByEmail({params:{email}, loadingId})
            .then(()=>{
                getNotifier().success("Correo enviado correctamente");
                onCancel();
            })
    },[api, email, onCancel]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className={"ShareByEmail"}>
            <p>Ingresa el email de la nueva aliada para enviarle las instrucciones</p>
            <input className='input form-control' placeholder="Ingresa el email..." value={email} onChange={e=>setEmail(e.target.value)} />
            <div className='buttons-container'>
                <button className='btn btn-danger' onClick={onCancel} disabled={loading}>Cancelar</button>
                <button className='btn btn-success' onClick={sendEmail} disabled={loading}>Guardar</button>
            </div>
        </div>
    );
};

export default ShareByEmail;
