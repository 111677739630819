import { copyPropertyOrThrow } from "../formUtils";

export const prepareCategoryFormForServer = ( form )=>{
    const category = {};

    copyPropertyOrThrow(form, category, 'name', 'Por favor llena el campo de nombre', {trim: true});
    category.isActive = form.isActive===true;

    return category;
}

export const categoriesSGroups = [
    'category_read'
];
