import React from 'react';
import RecentOrders from './components/RecentOrders/RecentOrders';
import TotalSells from './components/TotalSells/TotalSells';
import "./SuperDashboard.scss";
import MonthlyGraphWidget from "./components/MonthlyGraphWidget/MonthlyGraphWidget";

const SuperDashboard = () => {
    return (
        <div className="SuperDashboard">

            <div className="row">
                <div className="col-12 col-md-8">
                    <RecentOrders/>
                </div>
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <MonthlyGraphWidget/>
                    </div>
                    <div className="col-12">
                        <TotalSells/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <RecentOrders isProduct={true} />
                </div>
            </div>
        </div>
    );
}

export default SuperDashboard;
