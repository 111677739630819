import React from 'react'
import Modal from '../../../../../components/Modal/Modal';

const ConfirmDeleteModal = ({onClose, mainButtonAction}) => {
    return (
        <Modal
            title={'Eliminar categoría'}
            onClose={onClose}
            className="ConfirmDeleteModal"
        >
            <div className="d-flex font-light justify-content-center align-items-center text-center">
                ¿Dese eliminar la categoría? <br/><br/>
                No se podrá recuperar
            </div>

            <div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <button
                        type="button"
                        className="btn cursor-pointer btn-secondary d-flex align-items-center mb-1 justify-content-around mr-3"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        className="btn cursor-pointer btn-danger d-flex align-items-center mb-1 justify-content-around ml-3"
                        onClick={mainButtonAction}
                    >
                        Eliminar categoría
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmDeleteModal;