import React, {useCallback, useMemo} from 'react';
import TideReactTable from "../../../../../components/TideReactTable/TideReactTable";
import useTideTable from '../../../../../hooks/useTideTable';
import { referredsTableSGroups } from '../../../../../services/modelUtils/userUtils';
import Modal from "../../../../../components/Modal/Modal";

const ReferredsModal = ({onClose, user}) => {
    const referredColumns = useCallback(() => [
        {Header: "Nombre", accessor: 'user.name'},
        {Header: "Apellidos", accessor: 'user.lastNames'},
        {Header: "Email", accessor: 'user.email'},
        {Header: "Teléfono", accessor: 'phone'}
    ], []);

    const columns = useMemo(() => referredColumns(), [referredColumns]);

    const requestFilters = useMemo(()=>({
        referredBy: user.profile.id,
        sGroups: referredsTableSGroups,
    }),[user]);

    const table = useTideTable({
        columns,
        entity: 'profiles',
        requestFilters
    });

    return (
        <Modal
            title={"Listado de referidas"}
            className={'ReferredsModal'}
            onClose={onClose}
        >

            {/*begin::Table*/}
            <TideReactTable {...table.tableProps} />
            {/*end::Table*/}

        </Modal>
    )
}

export default ReferredsModal;
