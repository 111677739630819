import React from 'react'
import { getNotifier } from '../../../../../../../services/notifier';

const ContinueAsGuest = ({form, handleInputChange, gotoDeliveryAddress}) => {

    const validateEmailAndContinue = () => {
        // validate email
        if( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) 
            gotoDeliveryAddress();
        else 
            return getNotifier().error('Debes escribir un correo válido para continuar');
    }

    return (
        <div className='ContinueAsGuest'>
            <div className='text-center'>
                <div className="mb-4 row text-center">
                    <h3>Continuar como invitado</h3>
                </div>
                <div className="mb-2 row ">
                    <div className="col-lg-6 mt-4 mx-auto">
                        <h6 className='font-weight-bolder mb-2 text-info'>Correo electrónico</h6>
                        
                        <input 
                            type="text" 
                            className="form-control" 
                            name="billing_city" 
                            onChange={handleInputChange('email')} 
                            value={form.email||''} 
                            placeholder="Correo electrónico"
                        />
                    </div>
                </div>
                <div className="mb-2 row">
                    <div className="col-lg-6 mt-4 mx-auto">
                        <button 
                            type="button" 
                            className="btn btn-info mr-2"
                            onClick={validateEmailAndContinue}
                        >
                            Continuar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContinueAsGuest;