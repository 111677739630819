import React, { useContext, useState } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from "../../../../../services/notifier";
import './Payment.scss';
import useFormState from '../../../../../hooks/useFormState';
import { getEmptyPayment, MERCADOPAGO_PUBLICTOKEN } from '../../../../../services/modelUtils/paymentUtils';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import {useSelector} from "react-redux";
import {gatherEnvInfo} from "../../../../../services/errorUtils";

const Payment = ({orderObject, onClose}) => {
    const api = useContext(ApiContext);
    const { form, handleInputChange } = useFormState( ()=>getEmptyPayment() );
    const [processingPayment, setProcessingPayment] = useState()
    const loadingId = "Payment.orders";
    const me = useSelector(s=>s.api.me);

    const createToken = () => {
        if(!form.cardNumber || !form.cardholderName || !form.cardExpirationMonth || !form.cardExpirationYear || !form.securityCode){
            getNotifier().error("Debes llenar todos los campos para continuar");
        } else {
            try {
                const mp = new window.MercadoPago(MERCADOPAGO_PUBLICTOKEN);

                setProcessingPayment(true);

                const parameters = {
                    cardNumber: form.cardNumber,
                    cardholderName: form.cardholderName,
                    cardExpirationMonth: form.cardExpirationMonth,
                    cardExpirationYear: form.cardExpirationYear,
                    securityCode: form.securityCode,
                };

                mp.createCardToken(parameters).then(executePayment).catch(errorCreatingToken);
            }
            catch (error){
                const info = gatherEnvInfo();
                info.requestLog = api.requestLog;
                api.error.send(error, "Error en Payment.jsx\n"+(error?.stack||''), me, info);
                getNotifier().error('No fue posible procesar el pago. Inténtalo de nuevo.')
                setTimeout(  ()=>window.location.reload(), 5000);
            }
        }
    }

    const executePayment = (e) => {
        const cardToken = e.id;
        const paymentData = {cardToken: cardToken, orderId: orderObject.id};
        const guest = orderObject.guest;

        if(guest) {
            paymentData.guest = guest.id;
        }

        const request = api.orders.executePayment({ id:orderObject.id, params:paymentData, loadingId, useCommonPath: !paymentData.guest });

        request.then(() => {
            getNotifier().success('Tu pago ha sido procesado correctamente');
            setProcessingPayment(false);
            onClose && onClose();
        }).catch((e) => setProcessingPayment(false));
    }

    const errorCreatingToken = (error) => {
        let message = '';

        switch (error[0]?.code) {
            case 'E301':
                message = 'Tarjeta inválida.';
                break;
            case '316':
                message = 'El nombre del titular no es válido.';
                break;
            case '325':
                message = 'El mes de expiración es incorrecto.';
                break;
            case '326':
                message = 'El año de expiración es incorrecto.';
                break;
            default:
                break;
        }

        if(!message){
            if( error?.message &&
                (error.message.toLowerCase().indexOf('invalid expiration_year') !== -1 ||
                error.message.toLowerCase().indexOf('invalid expiration date') !== -1))
                message = 'Fecha de expiración inválida.';
            else if( error?.message ){
                message = 'Hubo un error validando tu tarjeta. '+error.message;
            }
            else
                message = 'Hubo un error validando tu tarjeta';
        }

        setProcessingPayment(false);
        getNotifier().error(message);
    }

    return (
        <div className="Payment">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">
                                Se te cobrarán <strong>${ moneyFormatter( orderObject.total )}</strong> por la orden <strong>#{orderObject.id}</strong>.
                            </div>

                            <div className="card-body">
                                <form id="mentita-payment-form">
                                    <div className="form-group form-row">
                                        <div className="col-12">
                                            <label htmlFor="form-checkout__cardNumber">
                                                Número de tarjeta
                                            </label>
                                            <input type="tel"
                                                id="form-checkout__cardNumber"
                                                placeholder="Número de tarjeta"
                                                className="form-control Payment__card"
                                                onChange={handleInputChange('cardNumber')}
                                                maxLength="16"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group form-row">
                                        <div className="col-4">
                                            <label htmlFor="form-checkout__securityCode">Código de seguridad</label>
                                            <input type="password"
                                                id="form-checkout__securityCode"
                                                placeholder="CVV"
                                                className="form-control"
                                                onChange={handleInputChange('securityCode')}
                                                maxLength="4"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="form-cardExpirationMonth">Mes de expiración</label>
                                            <input type="text"
                                                id="form-checkout__cardExpirationMonth"
                                                placeholder="MM"
                                                className="form-control"
                                                onChange={handleInputChange('cardExpirationMonth')}
                                                maxLength="2"
                                            />
                                        </div>
                                        <div className="col-4">
                                            <label htmlFor="form-checkout__cardExpirationYear">Año de expiración</label>
                                            <input type="text"
                                                id="form-checkout__cardExpirationYear"
                                                placeholder="YYYY"
                                                className="form-control"
                                                onChange={handleInputChange('cardExpirationYear')}
                                                maxLength="4"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group form-row">
                                        <label htmlFor="form-checkout__cardholderName">Nombre del titular</label>
                                        <div className="col-12">
                                            <input type="text"
                                                name="cardholderName"
                                                id="form-checkout__cardholderName"
                                                placeholder=""
                                                className="form-control"
                                                onChange={handleInputChange('cardholderName')}
                                                maxLength="50"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group form-row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <button type="button"
                                                id="form-checkout__submit"
                                                className="btn btn-lg btn-primary"
                                                onClick={ createToken }
                                                disabled={processingPayment}
                                            >
                                                {processingPayment && <span className="loader-div mr-5" />} Pagar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Payment.displayName = 'Payment';

export default Payment;
