import React from 'react';
import './ShareModal.scss';
import gatitos from './assets/gatitos.png';
import Modal from '../../../../../components/Modal/Modal';

const ShareModal = ({ onClose }) => {
    return (
        <Modal
            title={"Compartir"}
            className={"ShareModal"}
            onClose={onClose}>
            <div className='share-content'>
                <div className='description'>
                    <p>Comparte la siguiente URL</p>
                    <p>
                        <b>{window.location.href}</b>
                    </p>
                </div>
                <div className='image'>
                    <img src={gatitos} alt='Aliadas' className='aliadas-img'/>
                </div>
            </div>
        </Modal>
    );
};

export default ShareModal;
