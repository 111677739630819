import React, {useCallback, useContext, useEffect, useState} from 'react';
import "./UsersAdminEdit.scss";
import {ApiContext} from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import useDatePickerState from "../../../../../hooks/useDatePickerState";
import {SingleDatePicker} from "react-dates";
import {
    getUserFullName,
    renderMonthElement,
    userListSGroups,
    userProfileIdFilters
} from '../../../../../services/modelUtils/userUtils';
import _ from 'lodash';
import moment from 'moment';
import {getNotifier} from '../../../../../services/notifier';
import {neighbourhoodSelectorSGroups} from '../../../../../services/modelUtils/neighbourhoodUtils';
import Modal from "../../../../../components/Modal/Modal";
import TideEntitySelect from "../../../../../components/TideEntitySelect/TideEntitySelect";
import {useSelector} from "react-redux";

const UsersAdminEdit = ({isEdit, user, onClose}) => {
    const api = useContext(ApiContext);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const {form, handleInputChange, setForm} = useFormState({});
    const {
        props,
        date
    } = useDatePickerState(user?.profile?.birthday ? moment(user.profile?.birthday) : moment().subtract(18, 'years'));

    //Referral edition
    const [referredByUser, setReferredByUser] = useState();
    useEffect(()=>{
        if(!user?.profile?.referredByCode)
            return;
        api.users.get({ params:{ "profile.referralCode":user.profile.referredByCode }, customProp:'_' })
            .then( users=>users?.length&&setReferredByUser(users[0]) );
    },[api, user]);

    const roles = [{
        id: 2,
        name: 'ROLE_GENERAL_ADMIN',
        title: "Administrador normal"
        },
        {
            id: 4,
            name: 'ROLE_GENERAL_DISTRIBUITOR',
            title: "Distribuidora"
        },
        {
            id: 3,
            name: 'ROLE_GENERAL_SELL',
            title: "Ventas"
        }
        ]

    const handleSave = useCallback(() => {

        if (!form.neighbourhoodId)
            return getNotifier().error('Por favor completa tu información');

        const profile = {
            id: user?.profile?.id,
            street: form.street,
            phone: form.phone,
            suburb: form.suburb,
            addressReference: form.ref,
            zipCode: form.zipCode,
            neighbourhood: form.neighbourhoodId + '',
            city: form.city,
            state: form.state,
            birthday: date?.format("YYYY-MM-DD") || null,
            balance: form.balance,
            referredBy: referredByUser?.profile?.id
        }
        const params = {
            email: form.email,
            username: form.email,
            name: form.name,
            password: form.password,
            lastNames: form.lastNames,
            role: (form.role === '2' || form.role === '3') ? form.role : null,
            profile,
            sGroups: userListSGroups,
        }

        api.users[ isEdit ? 'update' : 'create' ]({ id: user?.id, params, loadingId:'UsersAdminEdit.users' }).then(onClose);

    }, [api, date, form, onClose, user, isEdit, referredByUser]);

    const loading = useSelector(s=>!!s.loadingIds["UsersAdminEdit.users"]);

    const setNewZipCode = useCallback((user) => {
        if (user.profile.zipCode) {
            api.neighbourhoods.get({
                params: {
                    postalCode: user.profile.zipCode,
                    sGroups: neighbourhoodSelectorSGroups
                }
            }).then((neighbourhoods) => {
                if (_.isEmpty(neighbourhoods)) {
                    getNotifier().error('Por favor actualice el código postal')
                }

                const myNeighbourhood = _.find(neighbourhoods, (neighbourhood) => neighbourhood.id === user.profile.neighbourhood?.id);

                setNeighbourhoods(neighbourhoods);
                setForm({
                    city: myNeighbourhood?.municipality.name,
                    state: myNeighbourhood?.municipality.state.name,
                    neighbourhoodId: myNeighbourhood?.id,
                    street: user.profile.street,
                    suburb: user.profile.suburb,
                    zipCode: user.profile.zipCode,
                    ref: user.profile.addressReference,
                    lastNames: user.lastNames,
                    phone: user.profile.phone,
                    birthday: user.profile.birthday,
                    name: user.name,
                    balance: user.profile.balance
                })
            });
        } else {
            setForm({
                street: user.profile.street,
                suburb: user.profile.suburb,
                zipCode: user.profile.zipCode,
                ref: user.profile.addressReference,
                lastNames: user.lastNames,
                phone: user.profile.phone,
                birthday: user.profile.birthday,
                name: user.name
            })
        }
    }, [api, setForm]);

    useEffect(() => {
        if (isEdit) {
            setNewZipCode(user);
        }
    }, [isEdit, user, setNewZipCode]);

    const handleZipCodeChange = useCallback((input) => {
        const postalCode = input.target.value;

        if (postalCode.length === 5) {
            api.neighbourhoods.get({
                params: {
                    postalCode: postalCode,
                    sGroups: neighbourhoodSelectorSGroups
                }
            }).then((neighbourhoods) => {
                if (_.isEmpty(neighbourhoods)) {
                    getNotifier().error('No encontramos tu código postal')
                }

                const randomNeighbourhood = _.sample(neighbourhoods);

                setNeighbourhoods(neighbourhoods);

                setForm({
                    ...form,
                    zipCode: postalCode,
                    state: randomNeighbourhood?.municipality.state.name,
                    city: randomNeighbourhood?.municipality.name
                })
            });
        } else {
            setForm({...form, zipCode: postalCode});
        }
    }, [api, form, setForm]);

    return (
        <Modal
            title={(isEdit ? 'Editar' : 'Añadir')+' usuario'}
            onClose={onClose}
            className={"UsersAdminEdit"}
        >
            {/*begin::Form*/}
            <form className="form">
                <div className="card-body">

                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Email</span>
                            </div>
                            <input type="text" value={form.email || ''} onChange={handleInputChange('email')}
                                   className="form-control" placeholder={'Ingresa el correo electrónico'}/>
                        </div>
                    </div>
                    {!isEdit &&
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text">Contraseña</span>
                            </div>
                            <input type="password" value={form.password || ''}
                                   onChange={handleInputChange('password')} className="form-control"
                                   placeholder="Ingresa una contraseña"/>
                        </div>
                    </div>
                    }
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <SingleDatePicker
                                {...props}
                                placeholder='Fecha de Nacimiento'
                                id='birthdate_date'
                                isOutsideRange={() => false}
                                renderMonthElement={renderMonthElement}
                                numberOfMonths={1}
                            />
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group mb-2 col-12 col-lg-6">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text">Nombre</span>
                            </div>
                            <input type="text" value={form.name || ''} onChange={handleInputChange('name')}
                                   className="form-control" placeholder={'Ingresa tu nombre completo'}/>
                        </div>
                        <div className="input-group mb-2 col-12 col-lg-6">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text">Apellidos</span>
                            </div>
                            <input type="text" value={form.lastNames || ''}
                                   onChange={handleInputChange('lastNames')} className="form-control"
                                   placeholder={'Ingresa tus apellidos'}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-6 d-flex">
                            <div className="input-group-prepend input-label mb-2">
                                <span className="input-group-text input-label">Rol</span>
                            </div>
                            <div>
                                <select className="form-control selectpicker"
                                        value={form.role || 'Selecciona una opción'} placeholder='Selecciona'
                                        onChange={handleInputChange('role')}>
                                    <option disabled={true}>Selecciona una opción</option>
                                    {roles.map((rol) => (
                                        <option value={rol.id} key={rol.id} id={rol.name}>{rol.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">C.P.</span>
                            </div>
                            <input type="text" value={form.zipCode || ''} onChange={handleZipCodeChange}
                                   className="form-control" placeholder={'Ingresa tu código postal'}/>
                        </div>
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Colonia</span>
                            </div>
                            <select className="form-control" value={form.neighbourhoodId || 'null'}
                                    onChange={handleInputChange('neighbourhoodId')}>
                                <option value='null' disabled={true}>Selecciona</option>
                                {_.map(neighbourhoods, (neighbourhood) =>
                                    <option value={neighbourhood.id} key={neighbourhood.id}>
                                        {neighbourhood.name}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Ciudad</span>
                            </div>
                            <input type="text" readOnly={true} className="form-control bg-secondary"
                                   value={form.city || ''}/>
                        </div>
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Estado</span>
                            </div>
                            <input type="text" readOnly={true} className="form-control bg-secondary"
                                   value={form.state || ''}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Calle y número</span>
                            </div>
                            <input type="text" value={form.street || ''} onChange={handleInputChange('street')}
                                   className="form-control" placeholder={'Ingresa tu calle'}/>
                        </div>
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Teléfono</span>
                            </div>
                            <input type="text" value={form.phone || ''} onChange={handleInputChange('phone')}
                                   className="form-control" placeholder={'Ingresa tu número'}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Ref.</span>
                            </div>
                            <input type="text" value={form.ref || ''} onChange={handleInputChange('ref')}
                                   className="form-control" placeholder={'Referencia de tu domicilio'}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-12 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Saldo actual.</span>
                            </div>
                            <input type="text" value={form.balance || '0'} onChange={handleInputChange('balance')}
                                   className="form-control" placeholder={'Saldo actual'}/>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Código de ref.</span>
                            </div>
                            <input readOnly={true} className="form-control bg-secondary" value={user?.profile?.referralCode}/>
                        </div>
                        <div className="input-group col-lg-6 mb-2">
                            <div className="input-group-prepend input-label">
                                <span className="input-group-text input-label">Referida por</span>
                            </div>
                            <TideEntitySelect
                                className='flex-grow-1'
                                entity={"users"}
                                labelCreator={getUserFullName}
                                value={referredByUser}
                                onChange={setReferredByUser}
                                additionalFilters={userProfileIdFilters}
                            />
                        </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                </div>
                <div className="card-footer">
                    <button type="reset" className="btn btn-primary mr-2" disabled={loading}
                            onClick={handleSave}>Aceptar
                    </button>
                    <button type="reset" className="btn btn-secondary" onClick={onClose}>Cancelar</button>
                </div>
            </form>
                {/*end::Form*/}
        </Modal>
    );
}

export default UsersAdminEdit;
