import React, {useState} from 'react';
import MyReferralCode from "./MyReferralCode";
import ShareByEmail from "./ShareByEmail";
import ReferralCodeForm from "./ReferralCodeForm";

const ReferralActions = () => {

    const [showing, setShowing] = useState();

    return (
        <div className={"ReferralActions"}>
            {!showing &&
                <MyReferralCode
                    startSharingEmail={()=>setShowing("sharingEmail")}
                    startAddingCode={()=>setShowing('addingCode')} />}
            {showing==='sharingEmail' &&
                <ShareByEmail
                    onCancel={()=>setShowing(null)}/>}
            {showing==='addingCode' &&
                <ReferralCodeForm
                    onCancel={()=>setShowing(null)}/>}

        </div>
    );
};

export default ReferralActions;
