import React, { useContext } from 'react'
import { ApiContext } from '../../../../../../../services/api/api-config';
import { prepareGuestFormForServer } from '../../../../../../../services/modelUtils/guestUtils';
import { getNotifier } from '../../../../../../../services/notifier';
import { getOrderTotalForGuest, orderShippingCalculatorForGuest, prepareOrderGuestFormForServer } from '../../../../../../../services/modelUtils/orderUtils';
import useShippingZones from '../../../../../../../hooks/useShippingZones';
import { previewOrderTable } from '../../../../../../../services/modelUtils/orderUtils';


const DeliveryAddress = ({form, cart, handleInputChange, setForm, gotoNextStep, neighbourhoods, register, handlePhoneChange, handleNeighbourhoodSelect, neighbourhood}) => {
    const api = useContext(ApiContext);

    const shippingZones = useShippingZones();

    //Derived data
    const tempOrder = {orderProducts: cart.cartProducts.map(cp => {
        const price = Math.max(...cp.product.productPrices.map(o => o.price));

        return {
            product: cp.product,
            quantity: cp.quantity,
            price: price*cp.quantity,
            isReward: false
        }
    })};

    const automaticShipping = orderShippingCalculatorForGuest( tempOrder, neighbourhood, shippingZones );
    const shipping = automaticShipping;
    const total = getOrderTotalForGuest(tempOrder, shipping);

    const registerGuestOrder = async() => {
        let _guest = null;
        let _order = null;
        let guest = null;
        let order = null;

        try {
            form.neighbourhood = neighbourhood;
            _guest = prepareGuestFormForServer(form)
            _order = prepareOrderGuestFormForServer(form, cart, shipping, total);
        } catch (e) {
            return getNotifier().error(e.message);
        }

        try {
            // create guest user
            guest = await api.guests.create({params: _guest, useCommonPath: false});
        } catch( e ) {
            return getNotifier().error(e.message);
        }
        
        // create order
        _order.guest = guest.id;
        _order.sGroups = previewOrderTable;
        order = await api.orders.create({params: _order, useCommonPath: false});        

        // set order and guest in state
        setForm({...form, order, guest});

        // go to next step (select payment type)
        gotoNextStep();       
    }

    return (
        <div className='DeliveryAddress'>
            <div className='text-center'>
                <div className="mb-4 row text-center">
                    <h3>Dirección de entrega</h3>
                </div>
                <div className="mb-2 row ">
                    <div className="col-lg-6 mt-4 mx-auto">
                        
                    <form className="form " id="kt_login_signin_form" onSubmit={register}>
                        <div className='form-container'>
                            <div className='form-column'>
                                <div className="form-group mb-3">
                                    <input 
                                        onChange={handleInputChange('name')} 
                                        value={form.name||''} 
                                        className="form-control h-auto form-control-solid py-4 px-8" 
                                        placeholder="Nombre(s)" 
                                        name="name" 
                                        autoComplete="off" 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <input 
                                        onChange={handleInputChange('lastname')} 
                                        value={form.lastname||''} 
                                        className="form-control h-auto form-control-solid py-4 px-8" 
                                        placeholder="Apellidos" 
                                        name="lastName" 
                                        autoComplete="off" 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <input 
                                        onChange={handlePhoneChange} 
                                        value={form.phone||''} 
                                        className="form-control h-auto form-control-solid py-4 px-8" 
                                        placeholder="Teléfono" 
                                        name="phone" autoComplete="off" 
                                    />
                                </div>
                            </div>

                            <div className='form-column'>
                                <div className="form-group mb-3">
                                    <input onChange={handleInputChange('street')} value={form.street||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Calle y número" name="street"/>
                                </div>
                                <div className="form-group mb-3">
                                    <input onChange={handleInputChange('zipCode')} value={form.zipCode||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Código postal" />
                                </div>
                                <div className="form-group mb-3">
                                    <select 
                                        onChange={handleNeighbourhoodSelect} 
                                        value={neighbourhood?.id||''} 
                                        className="form-control h-auto form-control-solid py-4 px-8" 
                                        placeholder="Colonia"
                                    >
                                        {!neighbourhoods?.length && <option value={""} disabled>Colonia</option>}
                                        {neighbourhoods.map( nh=><option key={nh.id} value={nh.id}>{nh.name}</option> )}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <input readOnly value={neighbourhood? `${neighbourhood.municipality?.name}, ${neighbourhood.municipality?.state?.name}`:''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Municipio, Estado"/>
                                </div>
                                <div className="form-group mb-3">
                                    <input onChange={handleInputChange('addressReference')} value={form.addressReference||''} className="form-control h-auto form-control-solid py-4 px-8" placeholder="Referencia" autoComplete="off" />
                                </div>
                            </div>
                        </div>
                    </form>

                    </div>
                </div>
                <div className="mb-2 row">
                    <div className="col-lg-6 mt-4 mx-auto">
                        <button 
                            type="button" 
                            className="btn btn-info mr-2"
                            onClick={registerGuestOrder}
                        >
                            Continuar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeliveryAddress;