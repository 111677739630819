import {useSelector} from "react-redux";
import {useContext, useEffect, useMemo} from "react";
import {ApiContext} from "../services/api/api-config";

const emptyZonesObject = {};

const useShippingZones = ()=>{

    const api = useContext(ApiContext);
    const zonesSetting = useSelector(s=>s.api.shippingZones );
    const zonesString = zonesSetting?.value;

    useEffect(()=>{
        api.settings.get({id: "shipping_zones", customProp:'shippingZones', useCommonPath: false});
    },[api]);

    return useMemo(()=>{
        try{
            if(!zonesString){
                return emptyZonesObject;
            }
            const zones = JSON.parse(zonesString);
            if( zones ){
                return zones;
            }
            return emptyZonesObject;
        }
        catch (e){
            console.error("Error parsing zones.", zonesString, e);
            return emptyZonesObject;
        }
    },[zonesString]);
}

export default useShippingZones;
