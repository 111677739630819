import React, { useContext } from 'react';
import "./SideBar.scss";
import {ReactComponent as Icon} from "../../../../assets/img/logo.svg";
import {ReactComponent as DisplaySideBarIcon} from "../../../../assets/icons/dashboard/aside-bar/display-sidebar.svg";
import getAppRoutes from "../../../../services/routes/appRoutes";
import {ApiContext} from "../../../../services/api/api-config";
import {faShare, faSignOutAlt, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {SecurityContext} from "../../../../services/SecurityManager";
import ShoppingDetails from '../ShoppingDetails/ShoppingDetails';
import { useSelector } from 'react-redux';
import {version} from '../../../../pckg.json';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import useBoolean from "../../../../hooks/useBoolean";
import ShareModal from "../ShareModal/ShareModal";
import BalanceModal from "../BalanceModal/BalanceModal";
import { moneyFormatter } from '../../../../services/currencyUtils';

const SideBar = ({hidden, toggleHidden, toggleProfile, hideAside, toggleHideAside}) => {
    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const routes = getAppRoutes(security);
    const location = useLocation();
    const me=useSelector(({api})=>api.me);
    const [showingShare, showShare, hideShare] = useBoolean();
    const [showingBalance, showBalance, hideBalance] = useBoolean();

    const isActiveRoute = (path) => {
        if (!path) return '';
        const pathToRegex = path.replace(/\/:[^/]*/g, "[^/]*") + "($|/)";
        const regex = new RegExp(pathToRegex);
        return location.pathname.match(regex) ? 'active' : '';
    };

    return (
        <div className={"MenuSidebar caracol " + (hideAside && 'hiddenAside')}>
            <div className={'menu-container ' + (hidden === true ? 'hide' : 'discover')}>
                <div className={'logo d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                    <h4 className={'magenta-color font-bold ' + (hidden && 'd-none')}>Menú</h4>
                    <DisplaySideBarIcon className={'display-sidebar-icon'} onClick={toggleHidden}/>
                </div>
                <div className="lists-container d-flex flex-column justify-content-between">
                    <ul className='menu-list'>
                        <div className="logo-container">
                            <Icon className={'logo-icon mb-4 '}/>
                        </div>
                        {routes.map((route, i) => {
                                if (route.hidden) return null;
                                return (
                                    <li className={classNames('menu-item', isActiveRoute(route.path))} key={i}>
                                        <Link to={route.path} onClick={ window.innerWidth < 700? toggleHideAside : ()=>{}}
                                              className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                            <div className={hidden ? '' : "pr-1"}>
                                                {route.activeIcon}
                                                {route.icon}
                                            </div>
                                            <p className={"text-box p-0 m-0 ml-2 " + (hidden && 'font-mini')}>{route.name}</p>
                                        </Link>
                                    </li>
                                );
                            }
                        )}
                        {!me.role && <li className='menu-item'>
                            <ShoppingDetails smallButton={hidden}/>
                        </li>
                        }
                    </ul>
                    <ul className="menu-list">
                    { !me.role && <li className='menu-item' onClick={showBalance}>
                            <span className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                
                            <div className="text-left">
                                <div className="p-2">Mi saldo</div>
                                <div className="p-2">$ { me.profile?.balance ? moneyFormatter(Number(me.profile?.balance)) : moneyFormatter(0) }</div>
                                <div className="btn btn-secondary">Ver Balance</div>
                            </div>
                                
                            </span>
                        </li>}
                        <li className='menu-item' onClick={showShare}>
                            <span className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faShare} className='dashboard-logo icon-dash-logo'/>
                                <FontAwesomeIcon icon={faShare} className='active-icon icon-dash-logo text-info'/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Compartir
                                </div>
                            </span>
                        </li>
                        <li className='menu-item' onClick={toggleProfile}>
                            <span className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faUserCircle} className='dashboard-logo icon-dash-logo'/>
                                <FontAwesomeIcon icon={faUserCircle} className='active-icon icon-dash-logo text-info'/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Mi Perfil
                                </div>
                            </span>
                        </li>
                        <li className='menu-item'>
                            <span onClick={api.logout}
                                  className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="dashboard-logo icon-dash-logo text-danger"/>
                                <FontAwesomeIcon icon={faSignOutAlt} className="active-icon icon-dash-logo text-info"/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Cerrar sesión
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>

                <span className="app-version">{version}{ !!api.newerVersion && <FontAwesomeIcon icon={faArrowUp}/> }</span>
            </div>

            {showingShare && <ShareModal onClose={hideShare} />}

            {showingBalance && <BalanceModal onClose={hideBalance} />}
        </div>
    );
}

SideBar.displayName="SideBar";

export default SideBar;
