import React, { useContext, useEffect, useState } from 'react';
import "./ExpensesEdit.scss";
import ExpensesForm from "../ExpensesForm/ExpensesForm";
import { useParams } from 'react-router';
import { ApiContext } from '../../../../../../services/api/api-config';
import { incomesTableSGroups } from '../../../../../../services/modelUtils/accountingUtils';


const ExpensesEdit = () => {
    const id = useParams().id;
    const api = useContext(ApiContext);
    const [expenseObject, setExpenseObject] = useState();

    useEffect(()=>{
        api.payments.get({id: id, params:{sGroups: incomesTableSGroups}}).then((response) => {
            setExpenseObject(response);
        });
    },[id, setExpenseObject, api]);

    return (
        <div className="ExpensesEdit">
            <ExpensesForm expenseObject={expenseObject}/>
        </div>
    );
}
 
export default ExpensesEdit;