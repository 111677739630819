import React, { useCallback } from 'react';
import "./AsideBar.scss";
import "../../../../../assets/styles/theme.scss";
import {faAngleDown, faChartBar, faChartLine, faFileWord, faMoneyBill, faMoneyCheckAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const accounts = ['Ingresos', 'Egresos', 'Préstamos', 'Estado de Cuenta', 'Acumulables'];
const AsideBar = ({setCurrentEntity}) => {

	const logoName = useCallback((item) => {
		switch(item) {
			case 'Ingresos':
				return faChartLine;
			case 'Egresos':
				return faAngleDown;
			case 'Préstamos':
				return faMoneyCheckAlt;
			case 'Estado de Cuenta':
				return faFileWord;
			case 'Acumulables':
				return faChartBar;
			default:
				return faMoneyBill;
		}
	}, []);

    return (
        <div className={"AsideBar aside-secondary d-flex"}>
            <div className='tab-pane p-3 px-lg-7 py-lg-5 fade active show'>
                <h3 className="p-2 p-lg-3 my-1 my-lg-3">Finanzas</h3>
			    <div className="list list-hover">
                    {accounts.map((item, i) => 
                            <div className="list-item hoverable p-2 p-lg-3 mb-2" key={i} id={i} onClick={() => setCurrentEntity(item)}>
			    		        <div className="d-flex align-items-center">
			    		        	{/*begin::Symbol*/}
			    		        	<div className="symbol symbol-40 symbol-light mr-4">
			    		        		<span className="symbol-label bg-hover-white">
			    		        			<FontAwesomeIcon icon={logoName(item)} className="catalogue-icon h-50 align-self-center"/>
			    		        		</span>
			    		        	</div>
			    		        	{/*end::Symbol*/}
			    		        	{/*begin::Text*/}
			    		        	<div className="d-flex flex-column flex-grow-1 mr-2">
			    		        		<span className="text-dark-75 font-size-h6 mb-0">{item}</span>
			    		        	</div>
			    		        	{/*begin::End*/}
			    		        </div>
			    	        </div>
                        )
                    }
			    </div>
            </div>
        </div>
    );
}
 
export default AsideBar;