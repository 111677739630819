import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import loadingAnim from "../../../../../assets/animations/890-loading-animation.json";
import {
    getGuestPrice,
    getProductPhotoUrl,
    ProductSGroups
} from "../../../../../services/modelUtils/productUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import {ApiContext} from "../../../../../services/api/api-config";
import _ from "lodash";
import { STATE_ACTIONS } from 'tide-api';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import CategoryLabel from "../../../../../components/CategoryLabel/CategoryLabel";

const { STATE_ACTION_CONCAT, STATE_ACTION_SET } = STATE_ACTIONS;

const ProductsMosaicView = ({ cart, setCart, filters, onShowPrices, deleteProduct, toggleProductActive, customProp }) => {
    const api = useContext(ApiContext);
    const productMeta = useSelector(s=>s.api[customProp+'Meta']);
    const productsList = useSelector(s=>s.api[customProp]);
    const itemsPage = productMeta?.currentPage||1;
    
    const loadData = useCallback(( filters, page )=>{
        return api.products.get({
            stateAction: page === 1? STATE_ACTION_SET : STATE_ACTION_CONCAT,
            customProp,
            params:{
                ...filters,
                "publicPrice[gte]" : 1,
                itemsPerPage: 8,
                page,
                sGroups: ProductSGroups,
                isActive: true
            },
            useCommonPath: false
        })
    },[api, customProp]);

    const fetchData = useCallback(() => {
        loadData( filters, itemsPage+1 );
    }, [itemsPage, loadData, filters]);

    const throttledLoadData = useMemo( ()=>_.debounce(loadData, 650), [loadData] );

    useEffect(()=>{
        throttledLoadData( filters, 1 );
    },[throttledLoadData, filters]);


    const handleAddProductToCart = useCallback((product) => {
        //search product in the cart
        const cartProduct = cart.cartProducts.find(cp => cp?.product.id === product?.id)||null;

        if(cartProduct) {
            //if product is in the cart, increase quantity
            const cartProducts = cart.cartProducts.map(cp => {
                if (cp?.product.id === product?.id) {
                    return {
                        ...cp,
                        quantity: cp.quantity + 1
                    }
                }
                return cp;
            });

            setCart({...cart, cartProducts});
        } else { 
            //if product is not in the cart, add it
            setCart({ ...cart, cartProducts: [...cart.cartProducts, {
                product,
                quantity: 1,
            }] });
        }
    }, [cart, setCart]);


    return (
        <div className={"ProductsMosaicView"}>
            <>
                {productsList?.length === 0 && 
                    <p className='no-products'>
                        No hay productos que coincidan con los filtros seleccionados.
                    </p>}

                {productsList && productMeta &&
                <InfiniteScroll
                    dataLength={productsList?.length}
                    next={fetchData}
                    key={filters}
                    hasMore={productMeta.totalItems>(productsList.length)}
                    loader={<Lottie animationData={loadingAnim}  loop={true} />}
                    className='row'
                >
                    {productsList.map((product) => (
                        <div className="col-md-6 col-xxl-3 col-lg-3 mt-3" key={product.id}>

                            <CategoryLabel className="mr-5">{ product.category?.name }</CategoryLabel>

                            {/*begin::Card*/}
                            <div className={"card card-custom card-shadowless product-container" + ( !product.isActive && " opacity-60")}>
                                <div className="card-body p-5 mt-5">
                                    {/*begin::Image*/}
                                    <div className="overlay">
                                        <div className="overlay-wrapper rounded text-center image-container">
                                            <div className="mw-100 w-500px image" style={{backgroundImage: `url(${getProductPhotoUrl(product, api)})` }}>
                                            </div>
                                            {product.stock < 1 &&
                                            <div className='overlay-top'>
                                                <div className="square">
                                                    <p className='font-bold text-uppercase'>Agotado</p>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                                        <div className="product-detail-cont mb-2">
                                            <span className="font-size-h6 font-size-xxl-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1">{product.name}</span>
                                        </div>
                                        <div className="product-detail-cont">
                                            {product.details}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <span className="font-size-sm text-danger text-uppercase stock-text text-bold mr-2">Stock:</span>
                                            <span className="font-size-sm">{product.stock}</span>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <span className="font-size-sm text-danger text-uppercase stock-text text-bold mr-2">Price:</span>
                                            <span className="font-size-sm">$ { moneyFormatter(getGuestPrice(product)) }</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <span
                                                className={"btn font-weight-bolder market-button btn-sm btn-green " + (product.stock < 1 && 'hide')}
                                                onClick={() => handleAddProductToCart(product)}>Añadir al carrito<FontAwesomeIcon
                                                icon={faShoppingCart} className='ml-4'/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
                }
            </>
        </div>
    );
};

export default ProductsMosaicView;
