import React from 'react';
import {useSelector} from "react-redux";

const MyReferralCode = ({startSharingEmail, startAddingCode}) => {

    const me = useSelector(s=>s.api.me);

    return (
        <div className={"MyReferralCode"}>
            <div>
                <p className='code-title'>Tu código es</p>
                <p className='code'>{me?.profile?.referralCode}</p>
                <button className='btn btn-danger share-btn' onClick={startSharingEmail}>Compartir por email</button>
                <br/><br/>
                {!me?.profile?.referredByCode ?
                    <button className='btn btn-danger share-btn' onClick={startAddingCode}>Ingresar código de aliada</button> :
                    <p className='small'>Código <b>{me?.profile?.referredByCode}</b> registrado</p>}
            </div>
        </div>
    );
};

export default MyReferralCode;
