import React, { useContext } from 'react';
import "./SideBar.scss";
import {ReactComponent as Icon} from '../../../../../assets/img/logo.svg';
import {ReactComponent as DisplaySideBarIcon} from "../../../../../assets/icons/dashboard/aside-bar/display-sidebar.svg";
import {ApiContext} from "../../../../../services/api/api-config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useHistory} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';
import ShoppingDetails from '../ShoppingDetails/ShoppingDetails';
import {version} from '../../../../../../package.json';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import useBoolean from '../../../../../hooks/useBoolean';
import ShareModal from '../ShareModal/ShareModal';
import { getGuestRoutes } from '../../../../../services/routes/mainRoutes';
import {faShare, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

const SideBar = ({cart, setCart, hidden, toggleHidden, hideAside, toggleHideAside, handleShowRegister}) => {
    const api = useContext(ApiContext);
    const routes = getGuestRoutes();
    const location = useLocation();
    const history = useHistory();
    const [showingShare, showShare, hideShare] = useBoolean();

    const isActiveRoute = (path) => {
        if (!path) return '';
        const pathToRegex = path.replace(/\/:[^/]*/g, "[^/]*") + "($|/)";
        const regex = new RegExp(pathToRegex);
        return location.pathname.match(regex) ? 'active' : '';
    };

    const gotoHome = () => {
        history.push('/');
    };

    return (
        <div className={"MenuSidebar caracol " + (hideAside && 'hiddenAside')}>
            <div className={'menu-container ' + (hidden === true ? 'hide' : 'discover')}>
                <div className={'logo d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                    <h4 className={'magenta-color font-bold ' + (hidden && 'd-none')}>Menú</h4>
                    <DisplaySideBarIcon className={'display-sidebar-icon'} onClick={toggleHidden}/>
                </div>
                <div className="lists-container d-flex flex-column justify-content-between">
                    <ul className='menu-list'>
                        <div className="logo-container">
                            <Link to="/" className='d-flex justify-content-cente align-items-center'>
                                <Icon className={'logo-icon mb-4 '}/>
                            </Link>
                        </div>
                        {routes.map((route, i) => {
                                if (route.hidden) return null;
                                return (
                                    <li className={classNames('menu-item', isActiveRoute(route.path))} key={i}>
                                        <Link to={route.path} onClick={ window.innerWidth < 700? toggleHideAside : ()=>{}}
                                              className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                            <div className={hidden ? '' : "pr-1"}>
                                                {route.activeIcon}
                                                {route.icon}
                                            </div>
                                            <p className={"text-box p-0 m-0 ml-2 " + (hidden && 'font-mini')}>{route.name}</p>
                                        </Link>
                                    </li>
                                );
                            }
                        )}
                        
                        <li className='menu-item'>
                            <ShoppingDetails 
                                smallButton={hidden} 
                                cart={cart} 
                                setCart={setCart}
                                handleShowRegister={handleShowRegister}
                            />
                        </li>
                    </ul>
                    <ul className="menu-list">                    
                        <li className='menu-item' onClick={showShare}>
                            <span className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faShare} className='dashboard-logo icon-dash-logo'/>
                                <FontAwesomeIcon icon={faShare} className='active-icon icon-dash-logo text-info'/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Compartir
                                </div>
                            </span>
                        </li>
                        <li className='menu-item'>
                            <span onClick={gotoHome}
                                  className={'menu-link d-flex align-items-center ' + (hidden && 'justify-content-center')}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="dashboard-logo icon-dash-logo text-danger"/>
                                <FontAwesomeIcon icon={faSignOutAlt} className="active-icon icon-dash-logo text-info"/>
                                <div className={'text-box m-0 ml-2 ' + (hidden && 'font-mini')}>
                                Salir
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
                
                {showingShare && <ShareModal onClose={hideShare} />}

                <span className="app-version">{version}{ !!api.newerVersion && <FontAwesomeIcon icon={faArrowUp}/> }</span>
            </div>
        </div>
    );
}

SideBar.displayName="SideBar";

export default SideBar;
