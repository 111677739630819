import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useBoolean from '../../../../../hooks/useBoolean';
import useTideTable from '../../../../../hooks/useTideTable';
import { expensesTableSGroups } from '../../../../../services/modelUtils/accountingUtils';
import "./Expenses.scss";
import ExpensesForm from "./ExpensesForm/ExpensesForm";
import { moneyFormatter } from "../../../../../services/currencyUtils";
import { paths } from '../../../../../services/routes/appRoutes';

const requestOptions={customProp: 'expensesTable'};
const Expenses = () => {
    const [newExpense, newExpenseOn, newExpenseOff] = useBoolean(false);
    const history = useHistory();

    const goToEdit = useCallback((id) => history.push(paths.expensesEdit.replace(':id', id)), [history]);

    const expensesColumns = useCallback(() => [
        {Header: "ID", accessor: expense => expense?.id},
        {Header: "Monto", accessor: expense => "$ " + moneyFormatter(expense?.amount, 'MXN')},
        {Header: "Referencia", accessor: expense => expense?.reference},
        {Header: "Método de pago", accessor: expense => expense?.paymentMethod?.name||'Sin información'},
        {Header: "Proveedor", accessor: expense => expense?.provider?.name||'Sin información'},
        {
            Header: "Categorías",
            Cell: ({row: {original} = {}}) =>
            <div className='categoriesContainer'>
                <ul>
                {original?.paymentCategories?.map((cat) => (
                    <li key={cat.id}>{cat.name}</li>
                ))}
                </ul>
            </div>
        },
        {
            Header: "Editar el egreso",
            Cell: ({row: {original} = {}}) =>
            <button className='btn btn-danger' onClick={() => goToEdit(original.id)}>
                Editar
            </button>
        },
        {Header: "Fecha de creación", accessor: expense =>  moment(expense.createdDate).format('DD/MM/YYYY')},
    ], [goToEdit]);

    const requestFilters = useMemo(()=>({
        type: 'expense',
        "order[createdDate]": "DESC",
        sGroups: expensesTableSGroups,
    }),[]);

    const columns = useMemo(() => expensesColumns(), [expensesColumns]);
    const table = useTideTable({
        entity: 'payments',
        columns,
        requestFilters,
        requestOptions
    });

    return (
        <div className={"Expenses " + (newExpense && "scroll-down-block")}>
            {newExpense && <ExpensesForm newExpenseOff={newExpenseOff}/>}
            <div className='card card-custom gutter-b'>
                <div className="card-header border-0 pt-5">
			    	<h3 className="card-title align-items-start flex-column">
			    		<span className="card-label font-size-h3 font-weight-bolder text-dark center-text">Egresos</span>
			    	</h3>
			    	<button className='btn btn-outline-danger' onClick={newExpenseOn}>
			    		Nuevo egreso
                    </button>
			    </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    <div className="tab-content mt-5" id="myTabTables6">
			            {/*begin::Tap pane*/}
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
			            	{/*begin::Table*/}
                            <TideReactTable {...table.tableProps}/>
			            	{/*end::Table*/}
			            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Expenses;