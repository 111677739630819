import React, { useCallback, useContext, useEffect, useState } from 'react';
import "./CatalogueEdit.scss";
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { ApiContext } from '../../../../../services/api/api-config';
import useFormState from '../../../../../hooks/useFormState';
import useBoolean from '../../../../../hooks/useBoolean';

const CatalogueEdit = ({closeModal, entity, isEdit, currentCatalogue}) => {
    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});
    const [error, setError] = useState();
	const [typeOfMethod, setTypeOfMethod] = useState(currentCatalogue?.payment_type);
	const [isLoading, loadingOn, loadingOff] = useBoolean();

    useEffect(() => {
        if (isEdit) {
            setForm({name: currentCatalogue.name, desc: currentCatalogue.description || currentCatalogue.type});
        }
    }, [isEdit, currentCatalogue, setForm])

    const createCatalogue = useCallback(() => {
		loadingOn();
        const apiMethod = isEdit ? 'update' : 'create';
        const params = entity === 'providers' ? {name: form.name, type: form.desc} : {name: form.name, description: form.desc, paymentType: typeOfMethod};
        const requestBody = isEdit ? {id: currentCatalogue.id, params, customProp: entity === 'providers' ? entity : entity+typeOfMethod} : {params, customProp: entity === 'providers' ? entity : entity+typeOfMethod};
        api[entity][apiMethod](requestBody).then(() => {
			loadingOff();
            closeModal();
        }).catch(() => {
			loadingOff()
            if (entity === 'paymentMethods')
                setError('No se pudo crear el nuevo método de pago');
            else if (entity === 'providers')
                setError('No se pudo crear el nuevo proveedor');
            else
                setError('No se pudo crear la nueva categoría de pago');
        });
    }, [api, form, entity, currentCatalogue, closeModal, isEdit, loadingOn, loadingOff, typeOfMethod]);

    return (
        <div className="CatalogueEdit">
            <div className="card inner-modal card-custom gutter-b example example-compact">
				<div className="card-header">
					<h3 className="card-title">
                        {isEdit ? 'Editar ' : 'Añadir '}{entity === 'providers' ? "proveedor" : entity === 'paymentMethods' ? "método de pago" : "categoría de pago"}
                    </h3>
					<div className="card-toolbar">
						<div className="example-tools justify-content-center">
                            <FontAwesomeIcon icon={faTimes} className="example-copy text-danger" onClick={() => closeModal()}/>
						</div>
					</div>
				</div>
				{/*begin::Form*/}
				<form className="form">
					<div className="card-body">
						<div className="form-group">
                            {error &&
							    <div className="alert alert-custom alert-default" role="alert">
							    	<div className="alert-icon">
							    		<span className="svg-icon svg-icon-primary svg-icon-xl">
							    			{/*begin::Svg Icon | path:assets/media/svg/icons/Tools/Compass.svg*/}
							    			{/*end::Svg Icon*/}
							    		</span>
							    	</div>
							    	<div className="alert-text text-danger">
                                        {error}
                                    </div>
							    </div>
                            }
						</div>
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">Nombre</span>
								</div>
								<input type="text" value={form.name||''} onChange={handleInputChange('name')} className="form-control" placeholder="Escribe un nombre" />
							</div>
						</div>
						<div className="form-group">
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text">{entity === 'providers' ? 'Tipo' : 'Descripción'}</span>
								</div>
								<input type="text" value={form.desc||''} onChange={handleInputChange('desc')} className="form-control" placeholder={entity === 'providers' ? 'Escribe el tipo de proveedor' : 'Escribe una descripción'} />
							</div>
						</div>
						<div className="separator separator-dashed my-2"></div>
                    </div>
                    {entity !== 'providers' &&
                        <ul className="nav nav-pills nav-dark-75 justify-content-center my-5">
					    	<li className="nav-item cursor-pointer rounded border border-info">
					    		<span className={"nav-link py-2 px-4 cursor-pointer " + (typeOfMethod === 'income' && 'active')} onClick={() => setTypeOfMethod('income')} data-toggle="tab">Ingreso</span>
					    	</li>
					    	<li className="nav-item cursor-pointer rounded border border-info">
					    		<span className={"nav-link py-2 px-4 cursor-pointer " + (typeOfMethod === 'expense' && 'active')} onClick={() => setTypeOfMethod('expense')} data-toggle="tab">Egreso</span>
					    	</li>
					    </ul>
                    }
					<div className="card-footer">
						<button type="reset" className="btn btn-primary mr-2" disabled={isLoading || (entity !== 'providers'&& !typeOfMethod)} onClick={createCatalogue}>Aceptar</button>
						<button type="reset" className="btn btn-secondary" onClick={() => closeModal()}>Cancelar</button>
					</div>
				</form>
				{/*end::Form*/}
			</div>
        </div>
    );
}
 
export default CatalogueEdit;