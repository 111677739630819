import React from 'react';
import './Pagination.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";

const defaultPageSizeOptions = [10,20,50,100];

const Pagination = ({reactTable, totalItems, pageSizeOptions=defaultPageSizeOptions}) => {

    const pages = reactTable.pageCount;
    const pageIndex = reactTable.state.pageIndex;
    const pageSize = reactTable.state.pageSize;

    const existsPrev = pageIndex > 0;
    const existsNext = pageIndex < (pages-1);

    const handlePageSizeChange = (e)=>{
        reactTable.setPageSize(Number(e.target.value));
    };

    let highestRowShown = pageIndex*pageSize+pageSize;
    if(highestRowShown > totalItems)
        highestRowShown = totalItems;


    return (
        <div className={'TideReactTable-Pagination'}>
                <div className='page-size-block'>
                    <span>Filas por página</span>
                    <select value={pageSize} onChange={handlePageSizeChange} className='page-select'>
                        {pageSizeOptions.map(opt=><option value={opt} key={opt}>{opt}</option>)}
                    </select>
                </div>

                <p className='actual-rows'>
                    {pageIndex*pageSize+1}-{highestRowShown} de {totalItems}
                </p>

            
            <button className='pagination-btn pag-ctrl' disabled={!existsPrev}
                    onClick={()=>reactTable.gotoPage(0)}>
                <FontAwesomeIcon icon={faAngleDoubleLeft}/>
            </button>
            <button className='pagination-btn pag-ctrl' disabled={!existsPrev}
                    onClick={()=>reactTable.gotoPage(pageIndex-1)}>
                <FontAwesomeIcon icon={faAngleLeft}/>
            </button>

            {/*existsPrev &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pageIndex-1)}>
                {pageIndex}</button>}

            <button className='pagination-btn active'>
                {pageIndex+1}</button>
            {existsNext &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pageIndex+1)}>
                {pageIndex+2}</button>}

            {pages > (pageIndex+2) &&
            <button className='pagination-btn' onClick={()=>reactTable.gotoPage(pages-1)}>
                {reactTable.pageCount}</button>*/}

            <button className='pagination-btn pag-ctrl'  disabled={!existsNext}
                    onClick={()=>reactTable.gotoPage(pageIndex+1)}>
                <FontAwesomeIcon icon={faAngleRight}/>
            </button>
            <button className='pagination-btn pag-ctrl'  disabled={!existsNext}
                    onClick={()=>reactTable.gotoPage(pages-1)}>
                <FontAwesomeIcon icon={faAngleDoubleRight}/>
            </button>
        </div>
    );
};

export default Pagination;
