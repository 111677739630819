import React from 'react'
import { KueskiPayment } from '../../../../../../Logged/Orders/components/KueskiPayment/KueskiPayment';

const KueskiPaymentModal = ({ form, gotoThanks }) => {
    return (
        <KueskiPayment
            orderObject={form.order}
            usePublic={true}
            guest={form.guest}
            onClose={gotoThanks}
        />
    );
}

export default KueskiPaymentModal;