import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useHistory } from 'react-router';
import TideEntitySelect from '../../../../../../components/TideEntitySelect/TideEntitySelect';
import useBoolean from '../../../../../../hooks/useBoolean';
import useDatePickerState from '../../../../../../hooks/useDatePickerState';
import useFormState from '../../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../../services/api/api-config';
import { incomesTableSGroups } from '../../../../../../services/modelUtils/accountingUtils';
import { getNotifier } from '../../../../../../services/notifier';
import "./IncomesForm.scss";


const IncomesForms = ({newIncomeOff, incomeObject}) => {
    const api = useContext(ApiContext);
    const {form, handleInputChange, setForm} = useFormState({});
    const [loading, isLoading, notLoading] = useBoolean();
    const [paymentMethod, setPaymentMethod] = useState();
    const [paymentCategories, setPaymentCategories] = useState([]);
    const {props, date} = useDatePickerState(moment());
    const history = useHistory();

    const goBack = useCallback((id) => history.push('/contabilidad?entity=Ingresos'), [history]);

    useEffect(() => {
        if (incomeObject) {
            setForm({
                amount: incomeObject.amount,
                reference: incomeObject.reference
            });
            setPaymentMethod(incomeObject.paymentMethod);
            setPaymentCategories(incomeObject.paymentCategories);
        }
    }, [incomeObject, setForm]);

    const handleSaveLoan = useCallback(() => {
        isLoading();
        const request = api.payments[incomeObject?"update":"create"]({
            id: incomeObject?.id,
            params:{
                ...form,
                amount: Number(form.amount),
                paymentCategories: _.map(paymentCategories, (payment) => payment.id),
                type: 'income',
                paymentMethod:paymentMethod?.id,
                paymentDate: date,
                sGroups: incomesTableSGroups,
            },
            customProp: 'incomesTable',
        });
        request.then(() => {
            incomeObject?getNotifier().success('El ingreso se se ha editado correctamente'):getNotifier().success('El ingreso se se ha creado correctamente');
            incomeObject?goBack():newIncomeOff();
            notLoading();
        }).catch(() => {
            incomeObject?getNotifier().error('No se pudo editar el ingreso'):getNotifier().error('No se pudo crear un nuevo ingreso');
            notLoading();
        });
    }, [newIncomeOff, date, isLoading, notLoading, incomeObject, goBack, api, form, paymentMethod, paymentCategories]);

    return (
        <div className="IncomesForm">
            <div className="card card-custom gutter-b">
			    <div className="card-body pt-4">
			    	<form>
			    		{/*end::Product images*/}
			    		{/*begin::Product info*/}
			    		<div className="mt-6">
			    			<div className="font-size-h3 font-weight-bolder text-dark">Información del nuevo ingreso </div>
                            <br></br>
			    			{/*begin::Input*/}
			    			<div className="form-group mb-8 row">
                                <div className="col-12 d-flex flex-column">
                                    <label className="font-weight-bolder">Referencia</label>
                                    <input type="text" className="form-control" min={0} value={form.reference} onChange={handleInputChange('reference')}></input>
                                </div>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 mt-8 mt-md-0 d-flex flex-column">
                                    <label className={'font-weight-bolder'}>Fecha en que se realizó el pago</label>
                                    <SingleDatePicker
                                        {...props}
                                        placeholder='Fecha de pago'
                                        id='expense_date'
                                        isOutsideRange={() => false}
                                    />
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-column">
                                    <label className="font-weight-bolder">Categoría de pago</label>
                                    <div className="form-group mb-0">
                                        <TideEntitySelect
                                            entity='paymentCategories'
                                            value={paymentCategories}
                                            onChange={setPaymentCategories}
                                            additionalFilters={{paymentType: 'income'}}
                                            filterLocal
                                            multi
                                        />
                                    </div>
                                </div>
			    			</div>
			    			<div className="form-group mb-8 row">
                                <div className="col-12 col-md-6 mb-8 d-flex flex-column">
                                    <label className="font-weight-bolder">Método de pago</label>
                                    <TideEntitySelect
                                        entity='paymentMethods'
                                        value={paymentMethod}
                                        onChange={setPaymentMethod}
                                        additionalFilters={{paymentType: 'income'}}
                                        filterLocal
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-2 mt-md-0 d-flex flex-column">
                                    <label className="font-weight-bolder">Monto</label>
                                    <input min={0} type="number" className="form-control" value={form.amount} onChange={handleInputChange('amount')}></input>
                                </div>
			    			</div>
                            <div className="separator my-1"></div>
			    		</div>
			    		{/*end::Product info*/}
						{/*end::Color*/}
						<button type="submit" className="btn btn-primary font-weight-bolder mr-2 px-8" disabled={loading} onClick={() => handleSaveLoan()}>{incomeObject?'Editar':'Crear'} ingreso</button>
			    		<button type="reset" className="btn btn-clear font-weight-bolder text-muted px-8" onClick={incomeObject?goBack:newIncomeOff}>Cancelar</button>
			    		{/*end::Input*/}
			    	</form>
			    	{/*end::Form*/}
			    </div>
			    {/*end::Body*/}
			</div>
        </div>
    );
}

export default IncomesForms;
