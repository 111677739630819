import _ from 'lodash';
import placeholder from "../../assets/img/product-placeholder.png";

export const getAplicablePriceBlock = ( product, quantity )=>{
    if(!product?.productPrices?.length)
        return null;
    const blocks =  _.sortBy( product?.productPrices, ['minQuantity'] );
    return  _.find(blocks, price => quantity <= price.maxQuantity && quantity >= price.minQuantity)
        || blocks[ blocks.length-1 ];
};

export const getUnitPriceFromQuantity = (product, quantity=0) => {
    if (!product?.productPrices?.length || quantity <= 0)
    return 0;
    const priceBlock = getAplicablePriceBlock(product, quantity);
    return priceBlock ? priceBlock.price : 0;
};

export const getTotalUtilityFromOrderProduct = ( orderProduct ) => {
    return  ( orderProduct.price - (orderProduct.product.providerPrice * orderProduct.quantity ) ) || 0;
};

export const getTotalUtilityFromOrder = ( order ) => {
    return order.orderProducts.reduce(( total,  orderProduct) => {
        return total + getTotalUtilityFromOrderProduct( orderProduct );
    }, 0);
};

export const getProductPhotoUrl = ( product, api )=>{
    return product?.productImage?
        api.appFiles.getUrl(product.productImage.id):
        placeholder;
};

/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: BEGIN */
export const ProductSGroups = [
    'product_read',
    'product_read_provider_price',
    'product_read_product_image',
    'app_file_read',
    'product_read_product_prices',
    'product_price_read',
    'created_date',
    'product_read_all_product_prices',
    'category_read'
];
/* sGroups used in Products CRUD from ProductsIndexComponent to ProductsForm :: END */

export const productSelectorSGroups = [
    'product_read',
    'product_read_provider_price',
    'product_read_product_image',
        'app_file_read',
    'product_read_product_prices',
        'product_price_read',
];
export const productSelectorFilters = {sGroups: productSelectorSGroups, isActive: true };

export const productStockStatus = (productStock) => {
    return (productStock < 1 ? { status: "SIN STOCK", color: "text-danger" } : { status: "EN STOCK", color: "" });
}

export const displayProductsOptions = [
    { value: 'mosaic', label: 'Mosaico' },
    { value: 'table', label: 'Tabla' },
]

export const getGuestPrice = (product) => {
    return product.publicPrice || 0;   
}