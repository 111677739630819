import moment from 'moment';
import React, { useCallback, useContext, useMemo } from 'react';
import TideReactTable from '../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../hooks/useTideTable';
import { moneyFormatter } from '../../../../../services/currencyUtils';
import {
    orderStatusTrans,
    orderStatusTransBackgroundColor,
    orderStatusTransColor
} from '../../../../../services/modelUtils/orderUtils';
import Lottie from "lottie-react";
import "./RecentOrders.scss";
import userAnim from '../../../../../assets/animations/53184-user.json';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../../../services/routes/appRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import arrayProfileImages from '../../../../../assets/img/profileImgs/arrayProfileImages';
import _ from 'lodash';
import { ApiContext } from '../../../../../services/api/api-config';
import { getProductPhotoUrl } from '../../../../../services/modelUtils/productUtils';


const requestOptions={customProp: 'recentOrdersTable'};
const RecentOrders = ({isProduct=false}) => {
    const api = useContext(ApiContext);
    const history = useHistory();

    const goToDetail = useCallback((id) => history.push(paths.orderDetail.replace(':id', id)), [history]);

    const getImagePath = useCallback((user) => {
        const theProfileImage = _.find(arrayProfileImages, (img) => img.name === user.avatar);
        return theProfileImage.path;
    }, []);

    const productsStockColumns = useMemo(() => [
        {Header: "Logo", accessor: product => <div style={{backgroundImage: `url(${getProductPhotoUrl(product, api)})`,
        width: '100px',
        height: '100px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        }}>

        </div>},
        {Header: "Aliada", accessor: product =>
            <div>
                <p className='mb-0 font-weight-bolder'>{product.name||'Sin información'}</p>
                <p className='mb-0 text-info'>ID: {product.id}</p>
            </div>
            },
        {Header: "Detalles", accessor: product =>
            <div>
                <p className='mb-0 text-uppercase text-medium font-weight-bolder text-justify'>{product.details||'Sin información'}</p>
            </div>
            },
        {Header: "Stock", accessor: product =>
            <div>
                <p className='mb-0 text-info text-small'>STOCK</p>
                <p className='mb-0 font-weight-bolder'>{product.stock}</p>
            </div>
            },
        {Header: "Fecha de creación", accessor: product =>  <p className='font-weight-bolder'>{moment(product.createdDate).format('DD/MM/YYYY')}</p>},
    ], [api]);

    const recentOrdersColumns = useMemo(() => [
        {Header: "Logo", accessor: order => <div> {order.user?.avatar ? <img src={getImagePath(order.user)}
        alt={'profile'}
        name={'profile'}
        className='logo-order'/>
        :<Lottie className='logo-order' animationData={userAnim} loop={false}/> }</div>},
        {Header: "Aliada", accessor: order => <div>
                                                <p className='mb-0 font-weight-bolder'>
                                                    { order.guest ? `INVITADO: ${order.guest?.name}` : ( order.user?.name||'Sin información' )}
                                                </p>
                                                <p className='mb-0 text-info'>ID: {order.id}</p>
                                                </div>
                                            },
        {Header: "Total", accessor:  order=> <div>
        <p className='mb-0 font-weight-bolder'>$&nbsp;{moneyFormatter(order.total)}</p>
        </div>},
        {Header: "Status", accessor:  order=>
            <div className='status-container' style={{backgroundColor: orderStatusTransBackgroundColor[order.status]}}>
                <p className='mb-0 text-small text-uppercase' style={{color:orderStatusTransColor[order.status] }} >{orderStatusTrans[order.status]||'Sin información'}</p>
            </div>
        },
        {Header: "Detalles", accessor: order =>
                <button className='btn btn-info mx-2' onClick={() => goToDetail(order.id)}>
                    <FontAwesomeIcon icon={faEye}/>
                </button>
        },
        {Header: "Fecha de creación", accessor: order => <p className='font-weight-bolder'>{moment(order.createdDate).format('DD/MM/YYYY')}</p>},
    ], [goToDetail, getImagePath]);

    const recentOrdersFilters = useMemo(()=>({
        sGroups: ['user_read', 'order_read_user', 'order_read', 'created_date', 'user_read_profile', 'profile_read', 'order_read_guest', 'guest_read'],
        "order[createdDate]": "DESC",
        pagination: true,
        itemsPerPage: 10
    }),[]);

    const productsStockFilters = useMemo(()=>({
        sGroups: ['product_read', 'product_read_product_image', 'app_file_read'],
        "stock[lt]": "10",
        "order[stock]": "ASC",
        pagination: false,
        isActive: true
    }),[]);

    const table = useTideTable({
        entity: 'orders',
        columns: recentOrdersColumns,
        requestFilters: recentOrdersFilters,
        requestOptions
    });

    const productsTable = useTideTable({
        entity: 'products',
        columns: productsStockColumns,
        requestFilters: productsStockFilters,
    });

    return (
        <div className={"RecentOrders mx-auto col-lg-12"}>
            <div className="card card-custom gutter-b">
                <div className="card-header border-0 pt-5">
                    <h3 className="title-text align-items-start flex-column">
                        <span className="font-bold magenta-color">{isProduct ?'Productos con stock menor a 10':'Últimas 10 ODC realizadas'}</span>
                    </h3>
                </div>
                <div className="card-body pt-2 pb-0 mt-n3">
                    {isProduct ?
                    <TideReactTable {...productsTable.tableProps} displayPagination={false}/>
                    :
                    <TideReactTable {...table.tableProps} displayPagination={false}/>}
                </div>
            </div>
        </div>
    );
}

export default RecentOrders;
