import React from 'react'
import { moneyFormatter } from '../../../../../../../services/currencyUtils';
import { sumTotalWeight } from '../../../../../../../services/modelUtils/orderUtils';
import _ from 'lodash';

const SelectPaymentType = ({ cart, form, gotoCardPayment, gotoKueskiPayment }) => {
    const orderObject = form.order;

    return (
        <div className='SelectPaymentType'>
            <div className='row'>
                <div className='col-12'>
                    <h3>Selecciona el método de pago</h3>
                </div>
            </div>
            
            <div className="mt-5 row justify-content-between">
                <div className="OrderInfo-box col-12 col-lg-4 rounded pt-4">                    
                    <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                        <p className='font-bold mt-2'>Total de productos</p>
                        <p>{ _.reduce((orderObject?.orderProducts), (acc, current) => { return acc + current.quantity }, 0) }</p>
                    </div>
                    <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                        <p className='font-bold mt-2'>Costo de envío</p>
                        <p>$&nbsp;{orderObject?.shipping && moneyFormatter(orderObject?.shipping)}</p>
                    </div>
                    <div className="OrderInfo-items d-flex align-items-center justify-content-between">
                        <p className='font-bold mt-2'>Peso acumulado</p>
                        <p>{sumTotalWeight(orderObject?.orderProducts)+'gr.'}</p>
                    </div>
                    <div className="OrderInfo-items mt-4 mb-2 d-flex align-items-center justify-content-between">
                        <p className='font-bold'>Subtotal</p>
                        <p>$&nbsp;{moneyFormatter(orderObject?.subtotal)}
                        </p>
                    </div>
                    <div className="mt-4 mb-2 d-flex align-items-center justify-content-between">
                        <p className='font-bold'>Total de la orden</p>
                        <p>$&nbsp;{orderObject?.total && moneyFormatter(orderObject?.total)}</p>
                    </div>
                </div>
            </div>

            <div className="mb-2 row mt-4">
                <div className="col-12 col-md-6">
                    <p className='text-info font-weight-bolder mb-1'>Notas de orden de compra</p>
                    <p className='text-medium font-bold'>{cart?.notes||'No existen notas para esta orden de compra.'}</p>
                </div>
            </div>
            
            <div className='row'>
                <div className='col-12 mt-4 d-flex justify-content-center align-items-center gap-4'>
                    <div className="OrderPDF-box mt-5 w-100 d-flex justify-content-end">
                        {orderObject?.total>500 &&
                            <button type="button"
                                onClick={gotoKueskiPayment}
                                className="btn btn-primary mr-3 btn-kueski"
                            >
                                Pagar con kueskiPay
                            </button>}

                        <button type="button"
                            onClick={gotoCardPayment}
                            className="btn btn-primary mr-3">
                            Pagar con tarjeta
                        </button>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default SelectPaymentType;