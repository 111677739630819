import React, { useState } from 'react';
import "./Accounting.scss";
import AsideBar from "./components/AsideBar/AsideBar";
import Loans from "./components/Loans/Loans";
import Incomes from "./components/Incomes/Incomes";
import Expenses from "./components/Expenses/Expenses";
import Status from "./components/Status/Status";
import Accumulative from "./components/Accumulative/Accumulative";

const Accounting = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const entity = searchParams.get('entity');
    const [currentEntity, setCurrentEntity] = useState(entity && entity);

    return (
        <div className="Accounting d-flex flex-column flex-md-row">
            <AsideBar setCurrentEntity={setCurrentEntity}/>
            <div className="pay-container">
            {currentEntity === 'Préstamos' && <Loans setCurrentEntity={setCurrentEntity}/>}
            {currentEntity === 'Ingresos' && <Incomes/>}
            {currentEntity === 'Egresos' && <Expenses/>}
            {currentEntity === 'Estado de Cuenta' && <Status/>}
            {currentEntity === 'Acumulables' && <Accumulative/>}
            </div>
        </div>
    );
}

Accounting.displayName="Accounting";

export default Accounting;
