import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import TideEntitySelect from '../../../../../../components/TideEntitySelect/TideEntitySelect';
import useDateRangePickerState from '../../../../../../hooks/useDateRangePickerState';
import { ApiContext } from '../../../../../../services/api/api-config';
import { moneyFormatter } from '../../../../../../services/currencyUtils';
import { getNotifier } from '../../../../../../services/notifier';
import "./SumWidget.scss";

const SumWidget = ({type, setTableQuery}) => {
    const api = useContext(ApiContext);
    const [paymentCategory, setPaymentCategory] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [provider, setProvider] = useState();
    const [total, setTotal] = useState();

    const {props, dateRange} = useDateRangePickerState({
        startDate: moment().subtract(1, 'month'),
        endDate: moment()
        });

    const handleGetTotal = useCallback(() => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return getNotifier().error('Ingresa un rango de fechas válido');
        }
        const params = {type,
            paymentCategory: paymentCategory?.id,
            provider: provider?.id,
            paymentMethod: paymentMethod?.id,
            from: dateRange.startDate.format("YYYY-MM-DD HH:mm"),
            to: dateRange.endDate.format("YYYY-MM-DD HH:mm")
        }
        api.payments.getTotal({params})
            .then((response) => {
                setTotal(response);
                setTableQuery(params);
            });
    }, [api, type, paymentCategory, dateRange, setTableQuery, provider, paymentMethod]);

    return (
        <div className="ExpenseReport col-12 col-md-6">
            <div className="card">
                <div className="card-header font-size-h3 font-weight-bolder text-dark">
                    {type === 'expense' ? 'Egresos' : 'Ingresos'}
                </div>
                <div className="card-body">
                    Calcula el monto de {type === 'expense' ? 'egresos' : 'ingresos'} en un rango de fechas
                    <div className='mt-4 ml-0 pl-0 col-12'>
                        <p className='filter-label font-weight-bolder'>Rango de fechas:</p>
                        <DateRangePicker {...props} />
                    </div>
                    <br></br>
                    
                    <div className="row">
                    <div className='mt-4 ml-0 pl-0 col-6'>
                        <p className='filter-label font-weight-bolder'>Categoría de pago</p>
                        <TideEntitySelect
                            entity='paymentCategories'
                            value={paymentCategory}
                            onChange={setPaymentCategory}
                            additionalFilters={{paymentType: type}}
                            filterLocal
                            isClearable
                        />
                    </div>
                    <div className='mt-4 ml-0 pl-0 col-6'>
                        <p className='filter-label font-weight-bolder'>Método de pago</p>
                        <TideEntitySelect
                            entity='paymentMethods'
                            value={paymentMethod}
                            onChange={setPaymentMethod}
                            additionalFilters={{paymentType: type}}
                            filterLocal
                            isClearable
                        />
                    </div>
                    {type === 'expense' &&
                        <div className='mt-4 ml-0 pl-0 col-6'>
                            <p className='filter-label font-weight-bolder'>Proveedor</p>
                            <TideEntitySelect
                                entity='providers'
                                value={provider}
                                onChange={setProvider}
                                filterLocal
                                isClearable
                            />
                        </div>
                        
                    }
                    <div className='mt-4 ml-0 pl-0 col-12'>
                        <button className='btn btn-danger' onClick={handleGetTotal}>Obtener total</button>
                    </div>
                    </div>
                    <div className='mt-4 ml-0 pl-0 col-12 d-flex justify-content-end'>
                        <h3>Total de {type === 'expense' ? 'egresos' : 'ingresos'}: {total?`$ ${moneyFormatter(Number(total.sum), 'MXN')}`:'$0.00MXN'}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default SumWidget;