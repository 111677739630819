import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import TideReactTable from '../../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../../hooks/useTideTable';
import { expensesTableSGroups } from '../../../../../../services/modelUtils/accountingUtils';
import "./LoanPayments.scss";

const requestOptions={customProp: 'loanPaymentsTable'};
const LoanPayments = ({requestLoan, setLoanPaymentToShow}) => {

    const loanPaymentsColumns = useCallback(() => [
        {Header: "ID", accessor: payment => payment?.id},
        {Header: "Monto", accessor: payment => payment?.amount},
        {Header: "Referencia", accessor: payment => payment?.reference},
        {Header: "Método de pago", accessor: payment => payment?.paymentMethod?.name},
        {
            Header: "Categorías",
            Cell: ({row: {original} = {}}) =>
            <div className='categoriesContainer'>
                <ul>
                {original?.paymentCategories?.map((cat) => (
                    <li key={cat.id}>{cat.name}</li>
                ))}
                </ul>
            </div>
        },
        {Header: "Fecha de creación", accessor: payment =>  moment(payment.createdDate).format('DD/MM/YYYY')},
    ], []);

    const requestFilters = useMemo(()=>({
        loan: requestLoan.id,
        type: 'expense',
        "order[createdDate]": "DESC",
        sGroups: expensesTableSGroups,
    }),[requestLoan]);

    const columns = useMemo(() => loanPaymentsColumns(), [loanPaymentsColumns]);
    const table = useTideTable({
        entity: 'payments',
        columns,
        requestFilters,
        requestOptions
    });

    return (
        <div className="LoanPayments">
            <div className="card card-custom gutter-b">
                <div className="close-container d-flex justify-content-between p-5 cursor-pointer" onClick={() => setLoanPaymentToShow(null)}>
                    <div className="text-muted mb-4 font-weight-bolder font-size-lg">Pagos realizados</div>
                    <FontAwesomeIcon icon={faTimes} className='close-icon pink-color'/>
                </div>
			    <div className="card-body pt-4">
                    <div className="tab-content mt-5" id="myTabTables6">
			            <div className="tab-pane fade active show" id="kt_tab_pane_6_1" role="tabpanel" aria-labelledby="kt_tab_pane_6_1">
                            <TideReactTable {...table.tableProps}/>
			            </div>
                    </div>
			    </div>
			</div>
        </div>
    );
}
 
export default LoanPayments;