import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import TideReactTable from '../../../../../../components/TideReactTable/TideReactTable';
import useTideTable from '../../../../../../hooks/useTideTable';
import { moneyFormatter } from '../../../../../../services/currencyUtils';
import { paymentsSGroups } from '../../../../../../services/modelUtils/accountingUtils';
import "./TableWidget.scss";

const TableWidget = ({tableQuery}) => {

    const paymentsColumns = useCallback(() => [
        {Header: "ID", accessor: payment => payment.id||'Sin información'},
        {Header: "Monto", accessor: payment => moneyFormatter(payment.amount)},
        {Header: "Tipo", accessor: payment => payment?.type === 'expense' ? 'Egreso' : 'Ingreso'},
        {Header: "Referencia", accessor: payment => payment?.reference},
        {Header: "Método de pago", accessor: payment => payment?.paymentMethod?.name||'Sin información'},
        {Header: "Proveedor", accessor: payment => payment?.provider?.name||'Sin información'},
        {
            Header: "Categorías",
            Cell: ({row: {original} = {}}) =>
            <div className='categoriesContainer'>
                <ul>
                {original?.paymentCategories?.map((cat) => (
                    <li key={cat.id}>{cat.name}</li>
                ))}
                </ul>
            </div>
        },
        {Header: "Fecha de creación", accessor: payment =>  moment(payment.createdDate).format('DD/MM/YYYY')},
    ], []);

    const requestFilters = useMemo(()=>({
        "order[createdDate]": "DESC",
        sGroups: paymentsSGroups,
        customProp: 'AccumulativeTable',
        type: tableQuery.type,
        provider: tableQuery.provider,
        paymentCategories: tableQuery.paymentCategory,
        paymentMethod: tableQuery.paymentMethod,
        loan: tableQuery.loan,
        originOrder: tableQuery.originOrder,
        "createdDate[before]": tableQuery.to,
        "createdDate[after]": tableQuery.from,
    }),[tableQuery]);

    const columns = useMemo(() => paymentsColumns(), [paymentsColumns]);
    const table = useTideTable({
        entity: 'payments',
        columns,
        requestFilters,
    });

    return (
        <div className="TableWidget col-12 mt-3">
            <div className="card">
                <div className="card-header">
                    <h3>Tabla de {tableQuery.type === 'expense'?'egresos':'ingresos'}</h3>
                </div>
                <div className="card-body">
                    <TideReactTable {...table.tableProps}/>
                </div>
            </div>

        </div>
    );
}
 
export default TableWidget;